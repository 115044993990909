<template>
  <div class="bike-overview">
    <h1>Übersicht</h1>

    <div class="filter-wrap mb-5">
      <category-choice v-model="chosenCategory" class="mr-2 mb-2 d-inline-block" />

      <filter-summary v-model="activeFilters" class="mr-2 mb-2 d-inline-block">
        <v-chip label small color="primary" @click="showFilters = !showFilters">
          Filter {{ showFilters ? 'verstecken' : 'anzeigen' }}
        </v-chip>
      </filter-summary>

      <bike-filters
        :is-visible="showFilters"
        :active-filters="activeFilters"
        @changed:filters="filters => activeFilters = filters"
        @changed:visibility="isVisible => showFilters = isVisible"
      />
    </div>

    <v-row v-if="isLoading">
      <v-col v-for="x in 12" :key="`loader_${x}`" :sm="6" :lg="4">
        <v-skeleton-loader :height="312" type="card" />
      </v-col>
    </v-row>

    <div v-else class="grid-wrap">
      <transition-group name="cell" tag="div" class="row">
        <v-col
          v-for="model in filteredModels"
          :key="model.modelSeriesId"
          :sm="6"
          :lg="4"
          class="cell"
        >
          <div class="preview-wrap" role="link" @click="chosenModel = model">
            <v-card outlined class="preview">
              <component-img
                :id="model.modelSeriesId"
                :has-image="model.hasImage"
                :alt="model.label"
                :height="245"
                is-model
                contain
              />
              <v-divider />
              <v-card-title class="bike-title justify-center">
                {{ model.label }}
              </v-card-title>
            </v-card>
          </div>
        </v-col>
      </transition-group>
    </div>

    <frame-modal
      :is-visible="framesToShow !== null"
      :frames="framesToShow"
      @close="chosenModel = null"
    >
      <filter-summary
        v-if="frameFilters.length"
        v-model="activeFilters"
        class="mb-6"
      />
    </frame-modal>
  </div>
</template>

<script>
import ModelApi from '@/api/Model'
import BikeFilters from './BikeFilters'
import CategoryChoice from './CategoryChoice'
import ComponentImg from '@/components/ComponentImg'
import FrameModal from './FrameModal'
import FilterSummary from './FilterSummary'

export default {
  name: 'bike-overview',

  components: {
    BikeFilters,
    CategoryChoice,
    ComponentImg,
    FilterSummary,
    FrameModal,
  },

  data () {
    return {
      activeFilters: [],
      chosenCategory: null,
      chosenModel: null,
      isLoading: false,
      models: [],
      showFilters: false,
    }
  },

  computed: {
    /**
     * Name of the slot which is related to frames.
     *
     * @returns {string}
     */
    frameCategory () {
      return this.$store.state.frameCategory
    },

    /**
     * Models matching the chosen category, filters.
     *
     * @returns {array}
     */
    filteredModels () {
      return this.models
        .filter(model => !this.chosenCategory || this.chosenCategory === model.category)
        .filter(model => {
          if (!this.activeFilters.length) return true

          return this.activeFilters.reduce((allFiltersMatch, filter) =>
            allFiltersMatch && this.modelHasSlotForFilter(model, filter)
          , true)
        })
    },

    /**
     * Active filters which are related to a frame.
     *
     * @returns {array}
     */
    frameFilters () {
      return this.activeFilters.filter(({ componentType }) => componentType.componentType === this.frameCategory)
    },

    /**
     * If a model gets chosen, we want to display related frames in a dialog.
     * But only those matching the current filter-configuration.
     *
     * @returns {array|null}
     */
    framesToShow () {
      return this.chosenModel === null ? null : this.getFramesOfChosenModel()
    },
  },

  mounted () {
    this.getModels()
  },

  methods: {
    /**
     * Loads available models, sorts those.
     *
     * @returns {void}
     */
    async getModels () {
      this.isLoading = true
      const res = await ModelApi.getAll()
      this.isLoading = false

      if (res.ok) {
        this.models = (await res.json()).sort((a, b) => a.label.localeCompare(b.label))
      }
    },

    /**
     * Checks if the given model has a slot with a components which matches the
     * given filter.
     *
     * @param {object} model
     * @param {object} filter
     * @returns {void}
     */
    modelHasSlotForFilter (model, filter) {
      // a filter is always related to a specific component-type. We just want to check that slot
      const slotToCheck = model.slots.find(slot =>
        slot.componentType.componentType === filter.componentType.componentType
      )

      // the slot has atleast one component with a matching option
      return slotToCheck && slotToCheck.slotComponents.find(({ component }) =>
        component.options.find(option => option.optionId === filter.optionId) !== undefined
      ) !== undefined
    },

    /**
     * Returns the frames of the currently chosen model which match the current
     * filters.
     *
     * @returns {array}
     */
    getFramesOfChosenModel () {
      const frameSlot = this.chosenModel.slots.find(({ componentType }) => componentType.componentType === this.frameCategory)
      const frames = frameSlot
        ? frameSlot.slotComponents.map(({ component }) => ({ ...component, model: this.chosenModel }))
        : []

      return frames
        .filter(({ options }) =>
          this.frameFilters.reduce((allFiltersMatch, filter) =>
            allFiltersMatch && options.find(({ optionId }) => optionId === filter.optionId)
          , true)
        )
        .sort((a, b) => a.label.localeCompare(b.label))
    },
  },
}
</script>

<style lang="scss">
  .bike-title {
    font-size: 1.15rem;
    word-break: break-word;
    text-align: center;
  }

  .bike-overview {
    .row {
      position: relative;
    }

    .preview-wrap {
      .preview {
        cursor: pointer;
      }
    }

    .cell {
      will-change: transform, opacity;
      transform: scale(1);
    }

    .cell-move {
      transition: transform 120ms*2 cubic-bezier(0.4, 0, 0.2, 1);
    }

    .cell-enter-active,
    .cell-leave-active {
      transition:
        opacity 120ms*2 cubic-bezier(0.4, 0, 0.2, 1),
        transform 120ms*2 cubic-bezier(0.4, 0, 0.2, 1);
    }

    .cell-leave-active {
      position: absolute;
      z-index: 0;
    }

    .cell-enter,
    .cell-leave-to {
      transform: scale(0);
      opacity: 0;
    }

    .v-skeleton-loader {
      border: 1px solid rgba(0, 0, 0, 0.12);

      .v-skeleton-loader__card {
        height: 100%;
        display: flex;
        flex-direction: column;

        .v-skeleton-loader__image {
          flex: 1 0 auto;
        }
      }
    }
  }
</style>
