<template>
  <div class="mudguard-wrap">
    <h2>Schutzblechlinien</h2>

    <v-radio-group :value="mudguardConfig.customizeMudguard" class="mt-0 mb-3" @change="onChangeCustomization">
      <v-radio :value="false" class="mb-4">
        <template v-slot:label>
          ohne
          <v-chip small :color="mudguardConfig.customizeMudguard ? 'grey lighten-2' : 'primary'" class="ml-auto">
            0€
          </v-chip>
        </template>
      </v-radio>

      <v-radio :value="true">
        <template v-slot:label>
          <span>
            Unter Lack
          </span>
          <v-chip small :color="mudguardConfig.customizeMudguard ? 'primary' : 'grey lighten-2'" class="ml-auto">
            {{ dekorPrice }}€
          </v-chip>
        </template>
      </v-radio>
    </v-radio-group>

    <v-text-field
      v-if="mudguardConfig.customizeMudguard"
      :value="mudguardConfig.chosenColor ? mudguardConfig.chosenColor.label : ''"
      prepend-inner-icon="color_lens"
      readonly
      hide-details
      outlined
      label="Farbauswahl Schutzblechlinien"
      class="mb-6"
      @click="showColorPicker = true"
    />

    <color-picker
      v-if="showColorPicker"
      :value="mudguardConfig.chosenColor"
      :with-ral-colors="false"
      @input="onColorPick"
      @close="showColorPicker = false"
    />
  </div>
</template>

<script>
import ColorPicker from '@/components/ColorPicker'

export default {
  name: 'bike-mudguard',

  components: {
    ColorPicker,
  },

  data () {
    return {
      showColorPicker: false,
    }
  },

  computed: {
    // formatted dekor-price
    dekorPrice () {
      return (this.$store.state.prices.dekorPrice / 100).toFixed(2)
    },

    // current mudguard-configuration
    mudguardConfig () {
      return this.$store.state.bike.mudguardConfig
    },
  },

  methods: {
    onColorPick (color) {
      this.showColorPicker = false
      this.$store.commit('updateMudguardConfig', { chosenColor: color })
    },

    onChangeCustomization (customize) {
      this.$store.commit('updateMudguardConfig', { customizeMudguard: customize })
    },
  },
}
</script>
