var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-models-overview-wrap"},[_c('div',{staticClass:"action-header"},[_c('h1',[_vm._v("Modellreihen")]),_c('v-btn',{attrs:{"to":{ name: 'ModelDetails' },"depressed":""}},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v(" create ")]),_vm._v(" Neue Modellreihe ")],1)],1),_c('div',{staticClass:"model-table-wrap"},[_c('data-table',{attrs:{"is-loading":_vm.isLoading,"items":_vm.models,"columns":_vm.columnOptions,"search-placeholder":"Bezeichnung/Kategorie","state-key":"models"},on:{"dblclick:row":function (e, ref) {
	var item = ref.item;

	return _vm.$router.push({ name: 'ModelDetails', params: { id: item.modelSeriesId } });
}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('component-img',{staticClass:"my-1",attrs:{"id":item.modelSeriesId,"has-image":item.hasImage,"alt":item.label,"height":60,"width":100,"is-model":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){_vm.modelToRemove = item.modelSeriesId}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1),_c('v-btn',{attrs:{"icon":"","to":{ name: 'ModelDetails', params: { id: item.modelSeriesId } }}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" create ")])],1)]}}],null,true)})],1),_c('base-dialog',{attrs:{"headline":"Modellreihe wirklich löschen?","is-visible":_vm.modelToRemove !== null},on:{"cancel":function($event){_vm.modelToRemove = null},"ok":_vm.deleteSelectedModel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }