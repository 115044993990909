<template>
  <div v-if="bike.slots.length" class="configurator-wrap">
    <h1 v-if="frameComponent" class="mb-0">
      {{ frameComponent.label }}
    </h1>
    <h2 v-if="model" class="text--secondary">
      {{ model.label }}
    </h2>

    <v-row>
      <v-col order-lg="1" :cols="12" :lg="8">
        <v-skeleton-loader
          v-if="isLoading"
          type="table-heading, image"
          height="500"
        />

        <div class="sticky-content" :class="{ 'd-none': isLoading }">
          <v-tabs v-model="activeTab" class="mt-2 mb-6">
            <v-tab>
              <v-icon left v-text="'photo_camera'" /> Foto
            </v-tab>
            <v-tab>
              <v-icon left v-text="'color_lens'" /> Farben
            </v-tab>
            <v-tab>
              <v-icon left v-text="'auto_awesome'" /> Dekor
            </v-tab>
          </v-tabs>

          <v-card>
            <tab-view
              :active-tab="activeTab"
              :errors="configErrors"
              :frame-component="frameComponent"
              :to-colorize="toColorize"
              @choose:color="onColorChoice"
              @loaded:preview="onPreviewLoaded"
              @start:color-choice="startColorChoice"
              @stop:color-choice="toColorize = null"
            />

            <v-divider />

            <v-card-actions v-if="configErrors.length">
              <v-alert class="ml-auto my-0" color="error" icon="priority_high" dense dark>
                Bitte korrigieren Sie zunächst die Konfiguration.
              </v-alert>
            </v-card-actions>

            <v-card-actions v-else class="d-flex align-center flex-wrap">
              <summary-button class="mr-1" />
              <wishlist-button class="mr-1" />
              <reset-button class="mr-1" @request:reset="init(true)" />

              <v-spacer />
              <buy-box @buy="buyDialogVisible = true" />
            </v-card-actions>
          </v-card>

          <small class="d-flex justify-end align-center mt-4">
            <sup>*</sup>inkl. MwSt.
          </small>
        </div>
      </v-col>

      <v-col order-lg="0" :cols="12" :lg="4">
        <v-skeleton-loader
          v-if="isLoading"
          type="table-heading, list-item-avatar@10"
        />
        <bike-slots
          v-else
          :errors="configErrors"
          :frame-component="frameComponent"
          @picked:component="selectSlotComponent"
          @picked:option="option => $store.commit('pickFrameOption', option)"
          @request:color-change="onRequestColorChange"
          @unselect:slot-component="unselectSlotComponent"
        />
      </v-col>
    </v-row>

    <missing-component-dialog :missing-components="missingComponents" />
  </div>
</template>

<script>
import BookmarkApi from '@/api/Bookmark'
import ModelApi from '@/api/Model'
import OrderApi from '@/api/Order'

import BikeSlots from './BikeSlots'
import BuyBox from './BuyBox'
import MissingComponentDialog from './MissingComponentDialog'
import ResetButton from './ResetButton'
import SummaryButton from './Summary/SummaryButton'
import TabView from './TabView'
import WishlistButton from './WishlistButton'

export default {
  name: 'configurator',

  components: {
    BikeSlots,
    BuyBox,
    MissingComponentDialog,
    ResetButton,
    SummaryButton,
    TabView,
    WishlistButton,
  },

  data () {
    return {
      activeTab: null,
      buyDialogVisible: false,
      summaryVisible: false,
      missingComponents: [],
      model: null,
      toColorize: null,
      isLoading: false,
    }
  },

  computed: {
    // the complete configuration of the current bike
    bike () {
      return this.$store.state.bike
    },

    // errors caused by the current configuration
    configErrors () {
      return this.checkConfigErrors()
    },

    // default color to use for paintable components
    defaultColor () {
      return this.$store.getters.defaultColor
    },

    // default color to use for paintable components
    defaultDekorColor () {
      return this.$store.getters.defaultColor
    },

    // a fixed key to identify a frame-component
    frameCategory () {
      return this.$store.state.frameCategory
    },

    // slot which contains the component representing the frame of the bike
    frameSlot () {
      return this.bike.slots.find(slot => slot.componentType.componentType === this.frameCategory)
    },

    // component representing the frame of the bike
    frameComponent () {
      return this.frameSlot && this.bike.frame
        ? this.frameSlot.slotComponents.find(({ component }) => component.componentId === this.bike.frame).component
        : undefined
    },
  },

  watch: {
    activeTab (to, from) {
      // colorchoice-tab was switched
      from === 1 && (this.toColorize = null)
    },

    bike: {
      handler () {
        this.bike.slots.length && this.$store.dispatch('getBikePrice')
      },
      deep: true,
    },
  },

  mounted () {
    this.init()
  },

  methods: {
    /**
     * Initializes the configurator by loading required data based on the
     * desired model, frame.
     *
     * @param {boolean} fullReset removes the set bookmark/order before loading
     * @returns {void}
     */
    async init (fullReset) {
      const { bookmark, frame, model, order } = this.$route.query
      this.isLoading = true

      if (fullReset && (bookmark || order)) {
        this.$router.replace({ path: this.$route.path, query: { model, frame } })
      }

      if (model && frame) {
        this.$store.commit('resetConfiguration')
        this.$store.commit('setFrame', +frame)
        this.$store.commit('setModel', +model)

        await this.$store.dispatch('getPrices')
        await this.$store.dispatch('loadColors')
        await this.$store.dispatch('loadDekorTypes')
        await this.loadModel(model)
      }
    },

    /**
     * Loads the model with the given id, only uses its active slots sorted by their name.
     *
     * @param {number} modelId
     * @returns {void}
     */
    async loadModel (modelId) {
      const res = await ModelApi.get(modelId)

      if (res.ok) {
        this.model = await res.json()

        const slots = this.model.slots
          .filter(({ active }) => active)
          .sort((a, b) => a.componentType.position - b.componentType.position)
          .map(slot => {
            // pick components based on the slot-defaults (or the frame, which has no components to choose from)
            const slotComponent = slot.componentType.componentType === this.frameCategory
              ? slot.slotComponents.find(({ component }) => component.componentId === this.bike.frame)
              : slot.slotComponents.find(({ component }) => component.componentId === slot.defaultComponentId)

            slot.pickedComponent = slotComponent ? slotComponent.component : null

            // set a default-color for paintable components
            slot.slotComponents.forEach(({ component }) =>
              component.paintable && (component.pickedColor = { ...this.defaultColor })
            )
            return slot
          })

        this.$store.commit('setSlots', slots)

        if (this.frameSlot && this.frameComponent) {
          // pick first frame-option per option-category initially (since a choice is required)
          this.frameComponent.options.forEach(option => {
            if (!this.bike.frameConfig.find(o => o.optionCategoryId === option.optionCategoryId)) {
              this.$store.commit('pickFrameOption', option)
            }
          })
        }
      }
    },

    /**
     * When our canvas was initialized, we want to load the configuration of an
     * order or a bookmark if the url indicates that.
     *
     * @returns {void}
     */
    async onPreviewLoaded () {
      const { order, bookmark } = this.$route.query

      if (bookmark) {
        await this.loadBookmark(+bookmark)
        this.isLoading = false
        return
      }

      order && (await this.loadOrder(+order))
      this.isLoading = false
    },

    /**
     * Loads the bookmark with the given ID, uses its configuration instead of
     * the current one.
     *
     * @param {number} bookmarkId
     * @returns {void}
     */
    async loadBookmark (bookmarkId) {
      const res = await BookmarkApi.get(bookmarkId)

      if (res.ok) {
        const { configuration } = await res.json()
        this.checkForMissingComponents(configuration)
        this.applyConfiguration(configuration)
      }
    },

    /**
     * Loads the order with the given ID, uses its configuration instead of the
     * current one.
     *
     * @param {number} orderId
     * @returns {void}
     */
    async loadOrder (orderId) {
      const res = await OrderApi.get(orderId)

      if (res.ok) {
        const { orderConfiguration } = await res.json()
        this.checkForMissingComponents(orderConfiguration)
        this.applyConfiguration(orderConfiguration)
      }
    },

    /**
     * Updates the slots of the currently loaded model so they match the
     * given configuration.
     *
     * @param {object} configuration
     * @returns {void}
     */
    applyConfiguration (configuration) {
      const bikeSlots = this.bike.slots.map(slot => {
        const configSlot = configuration.slots.find(({ slotId }) => slotId === slot.slotId)
        if (!configSlot || !configSlot.pickedComponent) return slot

        // if the slot of the configuration has a picked component, we want to
        // use that instead of the model-default
        const slotComponent = slot.slotComponents.find(({ component }) =>
          component.componentId === configSlot.pickedComponent.componentId
        )
        if (!slotComponent) return slot

        // if the component within the configuration has a color, we want to use that
        if (slotComponent.component.paintable && configSlot.pickedComponent.pickedColor) {
          slotComponent.component.pickedColor = configSlot.pickedComponent.pickedColor
        }

        slot.pickedComponent = slotComponent.component

        return slot
      })

      configuration.dekorConfig.elements = this.bike.dekorConfig.elements.map(element => {
        const toApply = configuration.dekorConfig.elements.find(dekor => dekor.label === element.label)
        return toApply ? { ...element, ...toApply } : element
      })

      this.$store.commit('setConfiguration', { ...configuration, slots: bikeSlots })
    },

    /**
     * Checks there are any components in the given configuration which can't
     * be found in the current model. Based on the result an information-dialog
     * gets shown.
     * If a configuration-slot doesn't have a picked component (= null) the
     * option "without" (e.g. "Ohne Gepäckträger vorne") is set and we don't
     * have to check that.
     *
     * @param {object} configuration
     * @returns {void}
     */
    checkForMissingComponents (configuration) {
      const componentExists = toCheck => this.bike.slots.find(({ slotComponents }) =>
        slotComponents.find(({ component: { componentId } }) => componentId === toCheck.componentId) !== undefined
      ) !== undefined

      this.missingComponents = configuration.slots.reduce((missing, { pickedComponent }) =>
        pickedComponent === null || componentExists(pickedComponent) ? missing : [...missing, pickedComponent]
      , [])
    },

    /**
     * updateSlot
     *
     * @param {object} slotUpdate
     * @returns {void}
     */
    updateSlot (slotUpdate) {
      this.$store.commit('setSlots',
        this.bike.slots.map(slot => slot.slotId === slotUpdate.slotId ? slotUpdate : slot)
      )
    },

    /**
     * startColorChoice
     *
     * @param {number} toColorize ID of the component whose color should get chosen
     * @returns {void}
     */
    startColorChoice (componentId) {
      this.toColorize = this.bike.slots
        .map(({ slotComponents }) => slotComponents.map(({ component }) => component))
        .flat()
        .find(component => component.componentId === componentId)
    },

    /**
     * onColorChoice
     *
     * @param {object} color
     * @returns {void}
     */
    onColorChoice (color) {
      if (!this.toColorize) {
        return
      }

      this.toColorize = { ...this.toColorize, pickedColor: color }
      const component = Object.assign({}, this.toColorize)

      const slot = this.bike.slots.find(slot =>
        slot.componentType.componentType === component.componentType.componentType
      )

      if (slot.pickedComponent && slot.pickedComponent.componentId === component.componentId) {
        slot.pickedComponent = component
      }

      slot.slotComponents = slot.slotComponents.map(slotComponent => ({
        ...slotComponent,
        component: slotComponent.component.componentId === component.componentId ? component : slotComponent.component,
      }))

      this.updateSlot(slot)
    },

    /**
     * Removes the selection of the given slot. If the selected component is
     * the one actually getting colorized, we stop the color-choice.
     *
     * @param {object} slot
     * @returns {void}
     */
    unselectSlotComponent (slot) {
      if (this.toColorize && slot.pickedComponent && slot.pickedComponent.componentId === this.toColorize.componentId) {
        this.toColorize = null
      }

      this.updateSlot({ ...slot, pickedComponent: null })
    },

    /**
     * Picks the given component for the given slot.
     *
     * If the currently picked component (before the change) is the one which
     * gets actually getting colorized, we switch the color-choice to the new
     * one.
     */
    selectSlotComponent ({ slot, component }) {
      if (this.toColorize && slot.pickedComponent && slot.pickedComponent.componentId === this.toColorize.componentId) {
        this.toColorize = component.isPaintable ? component : null
      }

      this.updateSlot({ ...slot, pickedComponent: component })
    },

    componentIsForbiddenBy (component) {
      return this.bike.slots.reduce((conflicts, slot) => {
        return slot.pickedComponent && slot.pickedComponent.forbiddenComponentIds.includes(component.componentId)
          ? [...conflicts, { ...slot.pickedComponent }]
          : conflicts
      }, [])
    },

    /**
     * Starts the color-choice for the given component.
     *
     * @param {object} component
     * @returns {void}
     */
    onRequestColorChange (component) {
      this.activeTab = 1

      // trick which prevents the collision of the two sidebars if one gets
      // hidden, the other one gets shown in the same tick (dekor -> color-choice).
      this.$nextTick(() => (this.toColorize = component))
    },

    /**
     * Tries to find the component with the given id in the currently loaded
     * model.
     *
     * @param {number} componentId
     * @returns {object|null}
     */
    getModelComponent (componentId) {
      if (!this.model || !this.model.slots) {
        return null
      }

      return this.model.slots.reduce((result, { slotComponents }) => {
        if (result) return result

        const slotComponent = slotComponents.find(({ component }) => component.componentId === componentId)
        return slotComponent ? slotComponent.component : null
      }, null)
    },

    /**
     * Checks if the current configuration contains errors:
     * A picked component...
     * - ...is unavailable
     * - ...is forbidden by another picked component
     * - ...exists, but another picked component requires another choice
     *
     * Those errors get collected with messages, references to the view is able
     * to show indicators at the right spots (affected slots, components).
     */
    checkConfigErrors () {
      if (this.isLoading || !this.model || !this.model.slots) {
        return []
      }

      const componentLabel = components => components
        .map(component => `"${component.label} (${component.componentType.label})"`)
        .join(' oder ')

      return this.bike.slots.reduce((errors, { slotId, pickedComponent }) => {
        if (!pickedComponent) return errors
        if (!pickedComponent.available) {
          errors.push({
            slotId,
            componentId: pickedComponent.componentId,
            message: 'Auswahl nicht verfügbar',
            type: 'unavailable',
          })
        }

        this.getRequiredConflicts(pickedComponent).forEach(conflict => {
          errors.push({
            slotId,
            componentId: pickedComponent.componentId,
            message: `Erfordert Auswahl von ${componentLabel(conflict.components)}`,
            conflict,
            type: 'requires',
          })

          errors.push({
            slotId: conflict.slotId,
            componentId: conflict.components.map(({ componentId }) => componentId),
            message: `Auswahl wird durch ${componentLabel([pickedComponent])} vorgegeben`,
            conflict: conflict,
            type: 'required',
          })
        })

        this.componentIsForbiddenBy(pickedComponent).forEach(conflict => {
          errors.push({
            slotId,
            componentId: pickedComponent.componentId,
            message: `Auswahl darf nicht mit ${componentLabel([conflict])} kombiniert werden`,
            conflict,
            type: 'forbidden',
          })
        })

        return errors
      }, [])
    },

    /**
     * Checks if every required component of the given one has been picked. If
     * that's not the case, a list of conflicting components with their slots
     * will get returned.
     * If multiple components of the same slot are required, only one of those
     * must be picked.
     *
     * @param {object} component
     * @returns {array}
     */
    getRequiredConflicts (component) {
      if (!component) {
        return []
      }

      const slotsWithCorrectChoices = []

      return component.requiredComponentIds.reduce((conflicts, id) => {
        const requiredComponent = this.getModelComponent(id)
        const requiredSlot = this.bike.slots.find(({ componentType }) =>
          componentType.componentType === requiredComponent.componentType.componentType
        )

        if (!requiredSlot || slotsWithCorrectChoices.includes(requiredSlot.slotId)) {
          return conflicts
        }

        const isPicked = this.bike.slots.reduce((isPicked, slot) =>
          isPicked || (slot.pickedComponent && slot.pickedComponent.componentId === id)
        , false)

        if (isPicked) {
          slotsWithCorrectChoices.push(requiredSlot.slotId)
          conflicts = conflicts.filter(({ slotId }) => slotId !== requiredSlot.slotId)
          return conflicts
        }

        const sameSlotError = conflicts.find(conflict => conflict.slotId === requiredSlot.slotId)

        if (sameSlotError) {
          sameSlotError.components.push(requiredComponent)
          return conflicts
        }

        return [
          ...conflicts,
          { components: [requiredComponent], slotId: requiredSlot.slotId }
        ]
      }, [])
    },
  },
}
</script>

<style lang="scss">
  .configurator-wrap {
    .v-tabs {
      .v-tab {
        &.v-tab--active:not(:hover) {
          &::before {
            opacity: 0.05;
          }
        }
      }
    }

    .v-skeleton-loader {
      display: flex;
      flex-direction: column;

      .v-skeleton-loader__image {
        flex: 1 0 auto;
      }
    }
  }

  @media (min-width: 960px) {
    .configurator-wrap {
      .sticky-content {
        position: sticky;
        top: 130px;
      }
    }
  }
</style>
