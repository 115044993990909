<template>
  <v-sheet v-if="user" color="primary" dark>
    <v-container class="d-flex align-center py-0">
      <div class="mr-2">
        <v-icon>admin_panel_settings</v-icon>
      </div>
      <v-tabs optional class="admin-tabs" background-color="transparent">
        <!--
          The keys are a little trick to avoid problems with a wrong "active"-visualization.
          If set on the "v-tabs", the "active"-indicator wouldn't get animated anylonger.
        -->
        <v-tab
          v-if="userCanEditComponents"
          :key="`${$route.name}_ComponentOverview`"
          :to="{ name: 'ComponentOverview' }"
        >
          Komponenten
        </v-tab>
        <v-tab
          v-if="userCanEditComponents"
          :key="`${$route.name}_ModelOverview`"
          :to="{ name: 'ModelOverview' }"
        >
          Modellreihen
        </v-tab>
        <v-tab
          v-if="userCanEditComponents"
          :key="`${$route.name}_OptionCategoryOverview`"
          :to="{ name: 'OptionCategoryOverview' }"
        >
          Optionskategorien
        </v-tab>
        <v-tab
          v-if="userCanEditCompanies"
          :key="`${$route.name}_CompanyOverview`"
          :to="{ name: 'CompanyOverview' }"
        >
          Firmen
        </v-tab>
        <v-tab
          v-if="userCanManageSeries"
          :key="`${$route.name}_AdminSeriesBikesOverview`"
          :to="{ name: 'AdminSeriesBikesOverview' }"
        >
          Serienräder
        </v-tab>
      </v-tabs>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: 'admin-nav',

  data () {
    return {
      adminTabs: null,
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },

    userCanEditCompanies () {
      return this.$store.getters.userCanEditCompanies
    },

    userCanEditComponents () {
      return this.$store.getters.userCanEditComponents
    },

    userCanManageSeries () {
      return this.$store.getters.userCanManageSeries
    },
  },
}
</script>
