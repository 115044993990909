var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"option-category-overview-wrap"},[_c('div',{staticClass:"action-header"},[_c('h1',[_vm._v("Optionskategorien")]),_c('v-btn',{attrs:{"to":{ name: 'OptionCategory' },"depressed":""}},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v(" create ")]),_vm._v(" Neue Optionskategorie ")],1)],1),_c('div',{staticClass:"option-category-table-wrap"},[_c('data-table',{attrs:{"is-loading":_vm.isLoading,"items":_vm.optionCategories,"columns":_vm.columnOptions,"search-placeholder":"Bezeichnung/Kategorie","state-key":"optionCategories"},on:{"dblclick:row":function (e, ref) {
      var item = ref.item;

      return _vm.$router.push({
        name: 'OptionCategory',
        params: { id: item.optionCategoryId }
      });
}},scopedSlots:_vm._u([{key:"item.showInSearch",fn:function(ref){
      var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.showInSearch,"inset":"","disabled":""}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){_vm.optionCategoryToRemove = item.optionCategoryId}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1),_c('v-btn',{attrs:{"icon":"","to":{ name: 'OptionCategory', params: { id: item.optionCategoryId } }}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" create ")])],1)]}}],null,true)})],1),_c('base-dialog',{attrs:{"headline":"Optionskategorie wirklich löschen?","is-visible":_vm.optionCategoryToRemove !== null},on:{"cancel":function($event){_vm.optionCategoryToRemove = null},"ok":_vm.deleteSelectedOptionCategory}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }