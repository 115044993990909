<template>
  <transition name="scale">
    <div v-if="!isAvailable" class="not-available-hint--wrap">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="error"
            v-bind="attrs"
            v-on="on"
          >
            report
          </v-icon>
        </template>
        <span>Nicht verfügbar</span>
      </v-tooltip>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'not-available-hint',

  props: {
    isAvailable: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
