import Vue from 'vue'
import VueRouter from 'vue-router'

import AdminRoutes from './admin'
import PrivateRoutes from './private'
import PublicRoutes from './public'

Vue.use(VueRouter)

const routes = [
  ...AdminRoutes,
  ...PrivateRoutes,
  ...PublicRoutes,
]

const scrollBehavior = (to, from, savedPosition) => {
  if (to.hash) {
    return {
      selector: to.hash,
    }
  }

  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  routes,
  scrollBehavior,
})

export default router
