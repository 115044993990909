import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl: `${baseUrl}/modelSeries`,
  baseHeader,

  get (modelSeriesId) {
    return fetch(`${this.baseUrl}/${modelSeriesId}`, {
      headers: this.baseHeader,
    })
  },

  /**
   * getAll
   *
   * @returns {Promise[]}
   */
  getAll () {
    return fetch(this.baseUrl, { headers: this.baseHeader })
  },

  /**
   * create
   *
   * @returns {Promise[]}
   */
  create (model) {
    return fetch(this.baseUrl, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify(model),
    })
  },

  /**
   * delete
   *
   * @param {number} modelSeriesId ID of the model to remove
   * @returns {Promise[]}
   */
  delete (modelSeriesId) {
    return fetch(`${this.baseUrl}/${modelSeriesId}`, {
      method: 'DELETE',
      headers: this.baseHeader,
    })
  },

  /**
   * update
   *
   * @param {object} model model to update
   * @returns {Promise[]}
   */
  update (model) {
    if (!model || !Object.keys(model).length) {
      return false
    }

    return fetch(`${this.baseUrl}/${model.modelSeriesId}`, {
      method: 'PUT',
      headers: this.baseHeader,
      body: JSON.stringify(model),
    })
  },
}
