import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl: `${baseUrl}/company`,
  baseHeader,

  get (companyId) {
    return fetch(`${this.baseUrl}/${companyId}`, {
      headers: this.baseHeader,
    })
  },

  /**
   * getAll
   *
   * @returns {Promise[]}
   */
  getAll () {
    return fetch(this.baseUrl, { headers: this.baseHeader })
  },

  /**
   * create
   *
   * @returns {Promise[]}
   */
  create (company) {
    return fetch(this.baseUrl, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify(company),
    })
  },

  /**
   * delete
   *
   * @param {number} companyId ID of the company to remove
   * @returns {Promise[]}
   */
  delete (companyId) {
    return fetch(`${this.baseUrl}/${companyId}`, {
      method: 'DELETE',
      headers: this.baseHeader,
    })
  },

  getUsers (companyId) {
    return fetch(`${this.baseUrl}/${companyId}/user`, {
      headers: this.baseHeader,
    })
  },

  /**
   * update
   *
   * @param {object} company company to update
   * @returns {Promise[]}
   */
  update (company) {
    if (!company || !Object.keys(company).length) {
      return false
    }

    return fetch(`${this.baseUrl}/${company.companyId}`, {
      method: 'PUT',
      headers: this.baseHeader,
      body: JSON.stringify(company),
    })
  },
}
