<template>
  <div class="text-editor--wrap">
    <div
      v-if="editor"
      class="text-editor--actions d-flex flex-wrap pa-2"
    >
      <v-btn
        v-for="(action, i) in actions"
        :key="i"
        icon
        outlined
        tile
        class="mr-1"
        :color="action.key && editor.isActive(action.key) ? 'primary' : null"
        :disabled="action.disabled"
        @click="action.action()"
      >
        <v-icon>{{ action.icon }}</v-icon>
      </v-btn>
    </div>

    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      editor: null,
    }
  },

  computed: {
    actions () {
      return [
        {
          icon: 'format_bold',
          key: 'bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          disabled: !this.editor.can().chain().focus().toggleBold().run(),
        },
        {
          icon: 'format_italic',
          key: 'italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          disabled: !this.editor.can().chain().focus().toggleItalic().run(),
        },
        {
          icon: 'format_strikethrough',
          key: 'strike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          disabled: !this.editor.can().chain().focus().toggleStrike().run(),
        },
        {
          icon: 'format_underlined',
          key: 'underline',
          action: () => this.editor.chain().focus().toggleUnderline().run(),
          disabled: !this.editor.can().chain().focus().toggleUnderline().run(),
        },
        {
          icon: 'format_list_bulleted',
          key: 'bulletList',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          disabled: false,
        },
        {
          icon: 'undo',
          action: () => this.editor.chain().focus().undo().run(),
          disabled: !this.editor.can().chain().focus().undo().run(),
        },
        {
          icon: 'redo',
          action: () => this.editor.chain().focus().redo().run(),
          disabled: !this.editor.can().chain().focus().redo().run(),
        },
      ]
    },
  },

  watch: {
    value (value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      },
    })
  },

  beforeDestroy () {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
  .text-editor--wrap {
    border: 1px solid #a8a8a8;
    border-radius: 6px;

    .text-editor--actions {
      border-radius: 6px 6px 0 0;
      background-color: #fafafa;
      border-bottom: 1px solid #a8a8a8;
    }

    .tiptap {
      min-height: 269px;
      padding: 8px;
    }
  }
</style>
