<template>
  <div class="component-options--wrap">
    <div class="d-flex align-center mb-5">
      <h3>Optionen</h3>

      <v-btn depressed small type="button" class="ml-auto" @click="optionModalVisible = true">
        <v-icon left>
          add
        </v-icon>
        Optionen ergänzen
      </v-btn>

      <option-modal
        :is-visible="optionModalVisible"
        :chosen-options="chosenOptions"
        :option-categories="optionCategoriesByComponentType"
        @change:options="onChangeOptions"
        @change:visibility="visibility => optionModalVisible = visibility"
      />
    </div>

    <v-list v-if="chosenOptions && chosenOptions.length" dense>
      <template v-for="(option, i) in chosenOptions">
        <v-list-item :key="`option_${i}`">
          <v-list-item-content>
            <v-list-item-title>
              {{ option.label }}
              <v-chip x-small label class="ml-2">
                {{ getCategoryOfOption(option) }}
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn small icon @click="removeOption(option)">
              <v-icon small color="red accent-3">
                delete
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="i < chosenOptions.length - 1" :key="i" />
      </template>
    </v-list>

    <v-alert v-else text border="left" icon="info" color="primary" class="d-inline-block">
      Es wurden noch keine Optionen zugeordnet
    </v-alert>
  </div>
</template>

<script>
import OptionCategoryApi from '@/api/OptionCategory'
import OptionModal from '@/components/OptionModal'

export default {
  name: 'component-options',

  components: {
    OptionModal,
  },

  props: {
    chosenOptions: {
      type: Array,
      default: () => ([])
    },

    componentType: {
      type: String,
      default: null,
    },
  },

  data () {
    return {
      optionCategories: [],
      optionModalVisible: false,
    }
  },

  computed: {
    optionCategoriesByComponentType () {
      return this.componentType
        ? this.optionCategories.filter(category => category.componentType.componentType === this.componentType)
        : this.optionCategories
    },
  },

  mounted () {
    this.loadOptions()
  },

  methods: {
    /**
     * Loads available option-categories.
     *
     * @returns {void}
     */
    async loadOptions () {
      const res = await OptionCategoryApi.getAll()
      res.ok && (this.optionCategories = await res.json())
    },

    /**
     * onChangeOptions
     *
     * @param {array} options
     * @returns {void}
     */
    onChangeOptions (options) {
      this.$emit('change:options', options)
    },

    /**
     * Removes the given option from the current choice.
     *
     * @param {object} option
     * @returns {void}
     */
    removeOption (option) {
      this.onChangeOptions(
        this.chosenOptions.filter(o => o.optionId !== option.optionId)
      )
    },

    /**
     * Returns the label of the related category of the given option.
     *
     * @param {object} option
     * @returns {string}
     */
    getCategoryOfOption (option) {
      const category = this.optionCategories.find(category => category.optionCategoryId === option.optionCategoryId)
      return category ? category.label : ''
    }
  },
}
</script>
