<template>
  <v-snackbar
    :color="snackbar.color"
    :value="snackbar.isVisible"
    @input="visible => $store.commit('setSnackbarVisibility', visible)"
  >
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'snackbar',

  computed: {
    snackbar () {
      return this.$store.state.snackbar
    },
  },
}
</script>
