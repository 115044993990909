<template>
  <div v-if="user" class="cart-button--wrap">
    <v-badge
      bordered
      :offset-x="20"
      :offset-y="20"
      :class="{ 'animate-badge': animateBadge }"
      :style="{ 'animation-duration': `${animationDuration}ms` }"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            :loading="isLoading"
            :to="{ name: 'Cart' }"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>shopping_cart</v-icon>
          </v-btn>
        </template>
        <span>Warenkorb</span>
      </v-tooltip>

      <template #badge>
        {{ itemAmount }}
      </template>
    </v-badge>
  </div>
</template>

<script>
export default {
  name: 'cart-button',

  data () {
    return {
      animateBadge: false,
      animationDuration: 1200,
    }
  },

  computed: {
    isLoading () {
      return this.$store.state.cart.loading
    },

    itemAmount () {
      return this.$store.getters.cartItems.reduce((amount, item) => amount + item.amount, 0)
    },

    user () {
      return this.$store.state.user.user
    },
  },

  watch: {
    itemAmount () {
      this.animateBadge = true
      setTimeout(() => (this.animateBadge = false), this.animationDuration)
    },
  },

  mounted () {
    this.$store.dispatch('getCart')
  },
}
</script>

<style lang="scss">
  .v-badge {
    &.animate-badge {
      .v-badge__wrapper {
        animation-duration: inherit;

        .v-badge__badge {
          animation-name: wobble;
          animation-iteration-count: 1;
          animation-duration: inherit;
        }
      }
    }
  }
</style>
