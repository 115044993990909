<template>
  <div class="imprint-wrap">
    <h1>Impressum</h1>

    <p>
      Verantwortlich für den Inhalt:<br>
      Fritz Wittich GmbH
    </p>

    <p>
      33609 Bielefeld<br>
      Hallenstraße 10 -14
    </p>

    <p>
      Tel.: (0521) 93204-40<br>
      Fax: (0521) 93204-49
    </p>

    <p>
      <a href="mailto:info@wittich-gmbh.de">info@wittich-gmbh.de</a>
    </p>

    <p>
      Geschäftsführer:<br>
      Matthias Wittich
    </p>

    <p>
      Handelsregister:<br>
      Amtsgericht Bielefeld – Nr. 20 HRB 7143
    </p>

    <p>
      Umsatzsteuer-Identifikationsnummer<br>
      DE124004827
    </p>

    <p>
      Redaktion und Produktion:<br>
      Inhaltlich verantwortliche Person: Matthias Wittich (Anschrift wie oben)
    </p>
  </div>
</template>

<script>
export default {
  name: 'imprint',
}
</script>
