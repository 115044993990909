<template>
  <v-app>
    <app-header />

    <v-main>
      <v-container>
        <div class="main-content">
          <snackbar />
          <v-breadcrumbs v-if="breadcrumb.length > 1" :items="breadcrumb" />
          <router-view />
        </div>
      </v-container>
    </v-main>

    <app-footer />
  </v-app>
</template>

<script>
import AppFooter from '@/components/AppFooter'
import AppHeader from '@/components/AppHeader'
import Snackbar from '@/components/Snackbar'

export default {
  name: 'app',

  components: {
    AppHeader,
    AppFooter,
    Snackbar,
  },

  computed: {
    breadcrumb () {
      return this.$store.getters.breadcrumb
    },
  },

  watch: {
    '$store.state.breadcrumb.breadcrumbItems': {
      deep: true,
      handler () {
        const breadcrumb = this.$store.state.breadcrumb.breadcrumbItems || []
        const joinedLabels = breadcrumb.map(({ text }) => text).join(' - ')
        const titleSuffix = breadcrumb.length > 0 ? ` - ${joinedLabels}` : ''

        document.title = `Wittich${titleSuffix}`
      }
    }
  },

  async mounted () {
    this.$store.dispatch('getUser')
  },
}
</script>

<style lang="scss">
  $material-icons-font-path: '~material-icons/iconfont/';
  @import '~material-icons/iconfont/material-icons.scss';

  @import '@/styles/_animations';
  @import '@/styles/_dekor-fonts';
  @import '@/styles/_fonts';
  @import '@/styles/_styles';
  @import '@/styles/_typography';

  body {
    .v-application--wrap {
      .v-main__wrap {
        > .container {
          padding-top: 0;
          padding-bottom: 0;

          .main-content {
            padding: 1rem 0;

            .v-breadcrumbs {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1904px) {
    .container {
      max-width: 1520px;
    }
  }
</style>
