<template>
  <div class="gallery-wrap">
    <h1>Galerie</h1>

    <v-carousel cycle show-arrows-on-hover hide-delimiter-background>
      <v-carousel-item class="slide">
        <img class="img-fluid" :src="require('@/assets/images/slides/galerie_01.jpeg')">
        <div class="caption">
          Sondermodell City Styler
        </div>
      </v-carousel-item>

      <v-carousel-item class="slide">
        <img class="img-fluid" :src="require('@/assets/images/slides/galerie_02.jpeg')">
        <div class="caption">
          Verfahrene Situation
        </div>
      </v-carousel-item>

      <v-carousel-item class="slide">
        <img class="img-fluid" :src="require('@/assets/images/slides/galerie_03.jpeg')">
        <div class="caption">
          Sondermodell Tulip
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: 'gallery',
}
</script>

<style lang="scss">
  .slide {
    position: relative;

    img {
      pointer-events: none;
    }

    .caption {
      z-index: 1;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #fff;
      padding: 0.5rem 1rem;
    }
  }
</style>
