<template>
  <div class="login-wrap">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'login',
}
</script>
