<template>
  <div class="downloads-wrap">
    <h1>Downloads</h1>

    <v-text-field
      v-model="search"
      prepend-inner-icon="search"
      class="mb-5"
      hide-details
      outlined
      dense
      label="Downloads durchsuchen"
    />

    <v-card outlined>
      <v-list dense>
        <template v-for="(download, i) in filteredDownloads">
          <v-list-item :key="`item_${i}`" :href="download.link" target="_blank" rel="noopener">
            <v-list-item-content>
              <v-list-item-title>
                {{ download.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="i < filteredDownloads.length - 1" :key="i" />
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'downloads',

  data () {
    return {
      search: '',
      downloads: [
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/5-WMF-Angebot-Alu-7-Gang-2-2018.pdf', label: '5 WMF Angebot Alu 7-Gang 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/6-WMF-Angebot-Alu-8-Gang-2-2018.pdf', label: '6 WMF Angebot Alu 8-Gang 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/7-WMF-Angebot-CroMo-7-Gang-2-2018.pdf', label: '7 WMF Angebot CroMo 7-Gang 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/8-WMF-Angebot-CroMo-8-Gang-2-2018.pdf', label: '8 WMF Angebot CroMo 8-Gang 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/9-WMF-Angebot-Rohloff-2-2018.pdf', label: '9 WMF Angebot Rohloff 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/10-WMF-Angebot-Alfine-2-2018.pdf', label: '10 WMF Angebot Alfine 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/11-WMF-Sondermodell-Limited-Cruiser-2-2018.pdf', label: '11 WMF Sondermodell Limited Cruiser 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/12-WMF-Sondermodell-FunCruiser-2-2018.pdf', label: '12 WMF Sondermodell FunCruiser 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/13-WMF-Sondermodell-RM5.0-Black-2-2018.pdf', label: '13 WMF Sondermodell RM5.0 Black 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/14-WMF-Beach-Cruiser-2-2018.pdf', label: '14 WMF Beach Cruiser 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/15-WMF-Nostalgie-2-2018.ods', label: '15 WMF Nostalgie 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/16-WMF-Tourensport-2-2018.ods', label: '16 WMF Tourensport 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/17-WMF-Cromo-2-2018.ods', label: '17 WMF Cromo 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/18-WMF-Alu-2-2018.ods', label: '18 WMF Alu 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/19-WMF-Alu-U6-2-2018.ods', label: '19 WMF Alu U6 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/20-WMF-Alu-SLX-Alu-2-2018.pdf', label: '20 WMF Alu SLX Alu 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/21-WMF-XXL-2-2018.ods', label: '21 WMF XXL 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/22-WMF-Gates-2-2018.pdf', label: '22 WMF Gates 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/23-WMF-Tiefeinsteiger-2-2018.pdf', label: '23 WMF Tiefeinsteiger 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/24-WMF-City-Stahl-Basic-2-2018.pdf', label: '24 WMF City Stahl Basic 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/25-WMF-Ansmann-FM-4.1-2-2018.ods', label: '25 WMF Ansmann FM 4.1 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/26-WMF-Ansmann-FM-4.1-Gates-2-2018.ods', label: '26 WMF Ansmann FM 4.1 Gates 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/27-WMF-Ansmann-RM-5.1-2-2018.ods', label: '27 WMF Ansmann RM 5.1 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/28-WMF-Ansmann-RM-7.0-2-2018.ods', label: '28 WMF Ansmann RM 7.0 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/29-WMF-Shimano-Steps-2-2018.ods', label: '29 WMF Shimano Steps 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/30-WMF-NOS-The-Black-Retro-2-2018.pdf', label: '30 WMF NOS The Black Retro 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/31-WMF-NOS-The-Lightgreen-Retro-2-2018.pdf', label: '31 WMF NOS The Lightgreen Retro 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/32-WMF-NOS-The-Red-Retro-2-2018.pdf', label: '32 WMF NOS The Red Retro 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/33-WMF-NOS-The-Blue-Retro-2-2018.pdf', label: '33 WMF NOS The Blue Retro 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/34-WMF-NOS-The-Bronx-Retro-2-2018.pdf', label: '34 WMF NOS The Bronx Retro 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/35-WMF-NOS-Trek-Tour-2-2018.pdf', label: '35 WMF NOS Trek-Tour 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/36-WMF-NOS-Ansmann-Front-Basic-Konkave-2-2018.pdf', label: '36 WMF NOS Ansmann Front Basic Konkave 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/37-WMF-NOS-Ansmann-Front-Basic-Tiefeinsteiger-2-2018.pdf', label: '37 WMF NOS Ansmann Front Basic Tiefeinsteiger 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/38-WMF-Unterlackdekorauftrag-2-2018.pdf', label: '38 WMF Unterlackdekorauftrag 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/39-WMF-Unterlackdekorauftrag-Werbeschilder-2-2018.pdf', label: '39 WMF Unterlackdekorauftrag Werbeschilder 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/40-41-Wittich-Nostalgie-Fertigräder-2-2018.pdf', label: '40-41 Wittich Nostalgie &amp; Fertigräder 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/42-Wittich-Girly-2-2018.pdf', label: '42 Wittich Girly 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/43-Wittich-Retro-Nostalgie-Sondermodell-2-2018.pdf', label: '43 Wittich Retro Nostalgie Sondermodell 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/44-Wittich-College-2-2018.pdf', label: '44 Wittich College 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/46-Ersatz-und-Zubehörteile-2-2018.pdf', label: '46 Ersatz- und Zubehörteile 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/47-KMC-Ketten-2-2018.pdf', label: '47 KMC Ketten 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/48-Luftpumpe-LYNX-März-2-2018.pdf', label: '48 Luftpumpe LYNX März 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/49-Rexway-Reifen-2-2018.pdf', label: '49 Rexway Reifen 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/50-Haberland-Taschen-2-2018.pdf', label: '50 Haberland Taschen 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/51-Fahrradträger-Uebler-2-2018.pdf', label: '51 Fahrradträger Uebler 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/52-Fahrradträger-Bosal-2-2018.pdf', label: '52 Fahrradträger Bosal 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/53-Wittich-GW-Antrag-2-2018.pdf', label: '53 Wittich GW-Antrag 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/54Ansmann-Servicekarte-E-Bike-2-2018.pdf', label: '54Ansmann Servicekarte E-Bike 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/55-Ansmann-Leitfaden-2-2018.pdf', label: '55 Ansmann Leitfaden 2-2018' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/56-Ansmann-Servicekarte-Akku-2-2018.pdf', label: '56 Ansmann Servicekarte Akku 2-2018' },
      ],
    }
  },

  computed: {
    /**
     * Items matching the current search, filter.
     *
     * @returns {array}
     */
    filteredDownloads () {
      return this.search && this.search.length ? this.downloads.filter(download =>
        download.label.toLowerCase().includes(this.search.toLowerCase())
      ) : this.downloads
    },
  },
}
</script>
