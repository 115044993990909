<template>
  <div class="privacy-wrap">
    <h1>Datenschutzerklärung</h1>
    <p>
      Wir informieren Sie mit dieser Datenschutzerklärung als Verantwortlicher über Art, Umfang und Zweck der Verarbeitung Ihrer personenbezogenen Daten innerhalb unseres Onlineangebotes und der damit verbundenen Leistungen.
    </p>

    <section>
      <h2>I. Begriffsbestimmungen</h2>
      <p>
        Diese Datenschutzerklärung beruht auf Begrifflichkeiten, die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DSGVO) verwendet wurden. Um zu gewährleisten, dass diese Erklärung verständlich ist, erläutern wir vorab die verwendeten Begrifflichkeiten. Soweit Sie nachstehend Verweise auf gesetzliche Regelungen ohne genaue Angabe des jeweiligen Gesetzesnamens finden, ist die DSGVO gemeint.
      </p>

      <h3>1. Personenbezogene Daten</h3>
      <p>„Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden “betroffene Person”) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.</p>

      <h3>2. Verantwortlicher</h3>
      <p>„Verantwortlicher“ ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet; sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.</p>

      <h3>3. Verarbeitung</h3>
      <p>„Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>

      <h3>4. Dritter</h3>
      <p>„Dritter“ ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.</p>

      <h3>5. Einwilligung</h3>
      <p>„Einwilligung“ ist jede von der betroffenen Person freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der Sie betreffenden personenbezogenen Daten einverstanden ist.</p>

      <h3>6. Profiling</h3>
      <p>„Profiling“ ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.</p>
    </section>

    <section>
      <h2>II. Kontaktdaten des für die Verarbeitung Verantwortlichen und des Datenschutzbeauftragten</h2>
      <h3>Verantwortlicher für die Verarbeitung:</h3>
      <p>
        Fritz Wittich GmbH<br>
        Eckendorfer Straße 31-33, 33609 Bielefeld<br>
        Tel.: +49 521 93204-30<br>
        Mail: <a href="mailto:info@wittich-gmbh.de">info@wittich-gmbh.de</a>
      </p>

      <h3>Kontaktdaten des Datenschutzbeauftragten:</h3>
      <p>
        CRO Consulting GmbH<br>
        Datenschutzbeauftragter<br>
        Adenauerplatz 4, 33602 Bielefeld<br>
        Tel.: +49 521 91414-69<br>
        Mail: <a href="mailto:datenschutz@cro-consulting.de">datenschutz@cro-consulting.de</a>
      </p>
    </section>

    <section>
      <h2>III. Art und Umfang der Verarbeitung personenbezogener Daten</h2>
      <p>Wir verarbeiten Ihre personenbezogenen Daten grundsätzlich nur, soweit dies zur Bereitstellung dieser Website und unserer Leistungen erforderlich ist. Die Verarbeitung der Daten erfolgt nur, wenn sie durch gesetzliche Vorschriften gestattet ist. Sofern Sie eine Einwilligung erteilen, ist auch eine darüber hinausgehende Verarbeitung möglich.</p>

      <h3>1. Besuch unserer Website</h3>
      <p>Beim Besuch unserer Website werden durch den von Ihnen verwendeten Browser automatisch Informationen an den Server unserer Website gesendet. Folgende Informationen werden dabei vorübergehend in Protokolldateien (Logfiles) gespeichert:</p>

      <ul>
        <li>Datum und Uhrzeit des Zugriffs auf die Website,</li>
        <li>Ihre Internet-Protokoll-Adresse (IP-Adresse),</li>
        <li>Internet-Service-Provider des zugreifenden Systems,</li>
        <li>Verwendeter Browsertyp und das Betriebssystem,</li>
        <li>Websites, von der aus Sie auf unsere Website gelangt sind,</li>
        <li>Websites, die von Ihrem System über unsere Website aufgerufen werden.</li>
      </ul>

      <p>Die Rechtsgrundlage für die Speicherung der Daten und Logfiles ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>
      <p>Die Verarbeitung der Daten erfolgt, um die Funktionsfähigkeit unserer Website sicherzustellen. Zudem dienen die Daten der technischen Optimierung der Website sowie der Sicherheit und Stabilität unserer informationstechnischen Systeme.</p>
      <p>Wir verwenden diese Daten weder für Marketingzwecke noch um Rückschlüsse auf Ihre Person zu ziehen.</p>
      <p>Die personenbezogenen Daten werden gelöscht, sobald sie für die Erreichung des Zwecks ihrer Erhebung nicht mehr erforderlich sind.</p>
      <p>Bei der Speicherung der Daten in Logfiles werden die Daten spätestens nach sieben Tagen gelöscht.</p>
      <p>Falls eine darüberhinausgehende Speicherung erfolgt, wird Ihre IP-Adresse gelöscht oder verfremdet.</p>
      <p>Die Verarbeitung dieser Daten in Logfiles ist zur Bereitstellung der Website unerlässlich. Damit steht Ihnen hier keine Widerspruchsmöglichkeit zu.</p>
      <p>Wenn Sie unsere Website besuchen, verarbeiten wir weitere personenbezogene Daten, indem wir bestimmte Analysedienste einsetzen. Nähere Erläuterungen hierzu erhalten Sie unter Ziff. IV. dieser Erklärung.</p>

      <h3>2. Kontaktaufnahme</h3>
      <p>Wenn Sie mit uns Kontakt aufnehmen (z. B. per E-Mail, Kontaktformular, Telefon oder soziale Medien), werden Ihre personenbezogenen Daten zur Bearbeitung der Kontaktanfrage und deren Abwicklung verarbeitet. Rechtsgrundlage für diese Verarbeitung der übermittelten Daten ist Art. 6 Abs. 1 S. 1 lit. b DSGVO. Wir löschen diese Daten, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind; also regelmäßig dann, wenn die jeweilige Konversation mit Ihnen beendet ist. Die Konversation ist beendet, wenn der betroffene Sachverhalt endgültig geklärt ist. Ansonsten gelten die gesetzlichen Aufbewahrungsfristen. Es erfolgt keine Weitergabe dieser Daten an Dritte.</p>
    </section>

    <section>
      <h2>IV. Analysedienste: Cookies und Tracking</h2>
      <h3>1. Einsatz von Cookies</h3>
      <p>Auf unserer Website setzen wir sogenannte Cookies ein. Dabei handelt es sich um kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf ihrem Endgerät (z. B. Computer, Tablet, Smartphone, o. ä.) automatisch gespeichert werden, wenn Sie unsere Website besuchen. Diese Cookies enthalten charakteristische Zeichenfolgen, die eine eindeutige Identifizierung des Internetbrowsers beim erneuten Aufrufen der Website ermöglichen.</p>
      <p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren, z.B. als eingeloggter Benutzer.Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.Die hierbei verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.</p>
      <p>Sie können die Setzung von Cookies durch unsere Website jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Ferner können bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern möglich. Wenn Sie die Setzung von Cookies in dem genutzten Internetbrowser deaktivieren, sind unter Umständen nicht alle Funktionen unserer Website vollumfänglich nutzbar.</p>

      <h3>2. Social Plugins</h3>
      <p>Wir setzen innerhalb unserer Website Social Plugins (im Folgenden „Plugins“) der sozialen Netzwerke (wie z.B. Facebook, Instagram oder Twitter) ein. Plugins sind Software-Module, die als Schaltflächen (im Folgenden: „Buttons“) unverändert in unsre Website eingebaut werden können, um einen automatisierten Datenaustausch zwischen unserer Website und den sozialen Netzwerken – nach deren Richtlinien – zu realisieren. Auf die Art und den Umfang der dabei übertragenen Daten sowie über deren weitere Verarbeitung haben wir keinen Einfluss.</p>
      <p>Wir verwenden Plugins, um unseren Bekanntheitsgrad zu steigern, um die Attraktivität unserer Website sowie unserer sonstigen Onlinedienste zu erhöhen und um dort mit Kunden, Interessenten und Nutzern zu kommunizieren. Die Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten ist Art. 6 Abs. 1 lit. f DSGVO. Der dahinterstehende Zweck ist gleichzeitig als berechtigtes Interesse im Sinne der DSGVO anzusehen.</p>
      <p>Die Verantwortung für den datenschutzkonformen Umgang mit den Daten ist durch deren jeweiligen Anbieter der Plugins zu gewährleisten.</p>

      <h4>Facebook</h4>
      <p>Wir verwenden Plugins des Anbieters Facebook (Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland; im Folgenden „Facebook“). Eine Übersicht über die Facebook Plugins finden Sie unter: <a href="https://developers.facebook.com/docs/plugins/" target="_blank" rel="noopener noreferrer">https://developers.facebook.com/docs/plugins/</a>. Dort kann auch das Aussehen der Facebook Plugins eingesehen werden. Sie enthalten zumeist das gängige Facebook Logo (weißes „f“ auf blauer Kachel), sind an den Begriffen „Like“ bzw. „Gefällt mir“ zu erkennen oder bestehen aus einem stilisierten „Daumen-hoch-Zeichen“.</p>
      <p>Wenn Sie eine Seite unseres Onlineauftritts besuchen, die ein solches Plugin enthält, baut Ihr Browser eine direkte Verbindung mit den Servern von Facebook in den USA auf. Facebook erhält die Information, dass Ihr Browser die entsprechende Seite unserer Website aufgerufen hat, auch wenn Sie kein Facebook-Konto besitzen oder zum Zeitpunkt des Besuchs unserer Website nicht bei Facebook eingeloggt sind. Diese Information, die auch Ihre IP-Adresse umfasst, wird von Ihrem Browser direkt an einen Server von Facebook übermittelt und dort verarbeitet. Falls Sie bei Facebook eingeloggt sind, kann Facebook den Besuch unserer Website Ihrem Facebook-Konto direkt zuordnen.</p>
      <p>Wenn Sie den Facebook-Button anklicken, wird diese Information ebenfalls direkt an den Server von Facebook übermittelt und dort verarbeitet. Die Informationen werden zudem auf der Website von Facebook veröffentlicht.</p>
      <p>Wenn Sie nicht möchten, dass Facebook Ihre Daten über unsere Website sammelt und anschließend verarbeitet, müssen Sie sich vor der Nutzung unserer Website bei Facebook ausloggen und die entsprechenden Cookies über Ihren Browser vorher löschen (vgl. auch oben Ziff. IV. 1).</p>
      <p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Facebook: (<a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">https://www.facebook.com/about/privacy/</a>)</p>
      <p>Weitere Einstellungen sowie Widersprüche bzgl. der Nutzung von Daten für Werbezwecke, können Sie innerhalb der Facebook-Profileinstellungen vornehmen: <a href="https://facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">https://facebook.com/settings?tab=ads</a></p>

      <h3>3. Unsere Angebote auf sozialen Medien und Einbindung sonstiger Dienste Dritter</h3>
      <p>Wir sind mit Inhalten auch auf sozialen Netzwerken und anderen virtuellen Plattformen vertreten, um dort mit Kunden, Interessenten und Nutzern kommunizieren und ihnen unsere Leistungen präsentieren zu können. Bei Aufruf dieser Netzwerke und virtuellen Plattformen gelten deren Geschäftsbedingungen und vor allem deren Datenschutzgrundsätze. Dies ist insbesondere zu beachten, wenn Sie durch Betätigung entsprechender Links auf unserer Website unsere Auftritte auf sozialen Netzwerken und anderen Plattformen aufrufen.</p>
      <p>Soweit in unserer Datenschutzerklärung nicht abweichend geregelt, verarbeiten wir Ihre personenbezogenen Daten, die Sie uns über die sozialen Netzwerke und die virtuellen Plattformen im Rahmen unserer Kommunikation zur Verfügung stellen, z.B. durch Versand von Nachrichten an uns, zur Abwicklung Ihrer Anfragen. Rechtsgrundlage für diese Verarbeitung der übermittelten Daten ist Art. 6 Abs. 1 S. 1 lit. b DSGVO.</p>
      <p>Außerdem setzen wir innerhalb unserer Website zur Optimierung unseres Internetauftritts Angebote von Drittanbietern ein, um deren Inhalte und Services, nämlich z.B. Videos oder Schriftarten in unsere Website einzubinden (nachfolgend einheitlich bezeichnet als „Services“). Diese Einbindung setzt voraus, dass die Anbieter dieser Services Ihre IP-Adresse verarbeiten. Weil sich dies technisch nicht verhindern lässt, ist die Verarbeitung der IP-Adresse damit für die Benutzung und Realisierung der Services erforderlich. Wir sind stets darum bemüht, nur solche Services zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur unmittelbaren Erbringung der Services verarbeiten. Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als „Web Beacons“ bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die „Web-Beacons“ können verschiedene Informationen, z.B. der Besuch auf unserer Website untersucht und ausgewertet werden. In pseudonymisierter Form können Informationen außerdem in Cookies auf Ihrem Endgerät gespeichert werden, wodurch technische Informationen zu Ihrem Browser und eingesetztem Betriebssystem, verlinkten Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unserer Website verarbeitet werden können. Rechtsgrundlage für diese Verarbeitung der übermittelten Daten ist Art. 6 Abs. 1 lit. f DSGVO. Bei den etwaig eingesetzten Services handelt es sich um:</p>
    </section>

    <section>
      <h2>V. Ihre Rechte als betroffene Person</h2>
      <p>Wenn ihre personenbezogenen Daten verarbeitet werden, sind Sie Betroffener im Sinne der DSGVO. Damit stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:</p>

      <h3>1. Recht auf Auskunft</h3>
      <p>Als betroffene Person haben Sie das Recht, von dem Verantwortlichen eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden; ist dies der Fall, so haben sie ein Recht auf Auskunft über diese personenbezogenen Daten (Art. 15 DSGVO). Über folgende Informationen können Sie Auskunft verlangen:</p>
      <p>
        die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;<br>
        die Kategorien von personenbezogenen Daten, welche verarbeitet werden;<br>
        die Empfänger oder die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;<br>
        falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;<br>
        das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;<br>
        das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;<br>
        wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;<br>
        das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.<br>
        Ferner haben Sie das Recht, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
      </p>

      <h3>2. Recht auf Berichtigung</h3>
      <p>Als betroffene Person haben Sie das Recht, von dem Verantwortlichen unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen (Art. 16 DSGVO).</p>

      <h3>3. Recht auf Löschung</h3>
      <p>Als betroffene Person haben Sie grundsätzlich das Recht, von dem Verantwortlichen zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft:</p>
      <p>
        Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.<br>
        Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.<br>
        Die betroffene Person legt gemäß Art. 21 Abs. 1 Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt gemäß Art. 21 Abs. 2 Widerspruch gegen die Verarbeitung ein.
      </p>

      <p>
        Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.<br>
        Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.<br>
        Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 erhoben.<br>
        Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er wegen der vorstehenden Gründen zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass eine betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat.
      </p>

      <p>
        Dieses Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
      </p>
      <p>
        zur Ausübung des Rechts auf freie Meinungsäußerung und Information;<br>
        zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;<br>
        aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Artikel 9 Absatz 2 lit. h und i sowie Artikel 9 Absatz 3;<br>
        für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gemäß Artikel 89<br>
        Absatz 1, soweit das in Absatz 1 genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder<br>
        zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      </p>

      <h3>4. Recht auf Einschränkung der Verarbeitung</h3>
      <p>Sie haben das Recht das Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:</p>
      <p>
        die Richtigkeit der personenbezogenen Daten von der betroffenen Person bestritten wird, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,<br>
        die Verarbeitung unrechtmäßig ist und die betroffene Person die Löschung der personenbezogenen Daten ablehnt und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt;<br>
        der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, die betroffene Person sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigt, oder<br>
        die betroffene Person Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1 eingelegt hat, solange noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen.<br>
        Wurde die Verarbeitung eingeschränkt, so dürfen diese personenbezogenen Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
      </p>
      <p>Wenn Sie die Einschränkung der Verarbeitung erwirkt haben, werden Sie von dem Verantwortlichen unterrichtet, bevor die Einschränkung aufgehoben wird.</p>

      <h3>5. Widerspruchsrecht</h3>
      <p>Als betroffene Person haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</p>
      <p>Der Verantwortliche verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechten und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
      <p>Verarbeitet der Verantwortliche personenbezogene Daten, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen Sie gegenüber dem Verantwortlichen der Verarbeitung für Zwecke der Direktwerbung, so wird er die personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.</p>
      <p>Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine Nachricht an unsere(n) Datenschutzbeauftragte(n) (Kontaktdaten siehe Ziff. II).</p>
      <p>Ferner haben Sie die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.</p>

      <h3>6. Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</h3>
      <p>Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit mit Wirkung für die Zukunft zu widerrufen. Durch den Widerruf wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>

      <h3>7. Recht auf Unterrichtung</h3>
      <p>Sofern Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht haben, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.</p>
      <p>Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.</p>

      <h3>8. Recht auf Datenübertragbarkeit</h3>
      <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Zudem haben Sie das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern</p>
      <p>
        die Verarbeitung auf einer Einwilligung gemäß Artikel 6 Absatz 1 lit. a oder Artikel 9 Absatz 2 lit. a oder auf einem Vertrag gemäß Artikel 6 Absatz 1 lit. b beruht und<br>
        die Verarbeitung mithilfe automatisierter Verfahren erfolgt.<br>
        Bei der Ausübung dieses Rechts haben Sie weiter das Recht zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.
      </p>
      <p>Das Recht auf Datenübertragbarkeit steht Ihnen nicht zu, wenn die Verarbeitung der Daten für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.</p>

      <h3>9. Automatische Entscheidung im Einzelfall einschließlich Profiling</h3>
      <p>Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.</p>
      <p>Dies gilt nicht, wenn die Entscheidung</p>
      <p>
        für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich ist,<br>
        aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person enthalten oder<br>
        mit Ihrer ausdrücklichen Einwilligung erfolgt.<br>
        In den vorstehend unter (a.) und (b.) genannten Fällen trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie die berechtigten Interessen der betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
      </p>

      <h3>10. Beschwerderecht</h3>
      <p>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs haben Sie das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn die betroffene Person der Ansicht ist, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen diese Verordnung verstößt.</p>
      <p>Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet Sie als Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.</p>
    </section>

    <section>
      <h2>VI. Datensicherheit</h2>
      <p>Wir setzen technische und organisatorische Maßnahmen ein, um einen möglichst lückenlosen Schutz Ihrer personenbezogenen Daten sicherzustellen. Dennoch können internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass niemand einen absoluten Schutz gewährleisten kann. Aus diesem Grund steht es Ihnen frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise per Post, Fax oder telefonisch, an uns zu übermitteln.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'privacy',
}
</script>

<style lang="scss">
  .privacy-wrap {
    max-width: 950px;

    section {
      margin: 2.5rem 0;
    }
  }
</style>
