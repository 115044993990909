export default {
  state: {
    text: '',
    color: 'info',
    isVisible: false,
  },

  mutations: {
    setSnackbar (state, { text = '', color = '', isVisible = true }) {
      text && (state.text = text)
      color && (state.color = color)
      state.isVisible = isVisible
    },

    setSnackbarVisibility (state, visibility = false) {
      state.isVisible = visibility
    },
  },
}
