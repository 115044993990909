var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-series-overview-wrap"},[_vm._m(0),_c('div',{staticClass:"company-table-wrap"},[_c('data-table',{attrs:{"is-loading":_vm.isLoading,"items":_vm.series,"columns":_vm.columnOptions,"default-options":{
        sortBy: ['title'],
        sortDesc: [false]
      },"state-key":"series","search-placeholder":"ID/Name"},on:{"dblclick:row":function (e, ref) {
            var item = ref.item;

            return _vm.$router.push({ name: 'AdminSeriesBikeDetails', params: { id: item.standardBikeSeriesId } });
}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{ name: 'AdminSeriesBikeDetails', params: { id: item.standardBikeSeriesId } }}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" create ")])],1)]}},{key:"item.active",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.hasImage",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.hasImage),callback:function ($$v) {_vm.$set(item, "hasImage", $$v)},expression:"item.hasImage"}})]}}],null,true)})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action-header"},[_c('h1',[_vm._v("Serienräder")])])}]

export { render, staticRenderFns }