<template>
  <div class="frame-options--wrap">
    <template v-for="({ label, options }, i) in groupedOptions">
      <div :key="i">
        <h5>{{ label }}</h5>

        <v-list>
          <v-list-item
            v-for="option in options"
            :key="option.id"
            v-on="options.length > 1 ? { click: () => $emit('picked:option', option) } : {}"
          >
            <v-list-item-content>
              {{ option.label }}
            </v-list-item-content>

            <v-list-item-action v-if="optionIsPicked(option)">
              <v-icon>check</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'frame-options',

  props: {
    frameComponent: {
      type: Object,
      default: null,
    },
    chosenOptions: {
      type: Array,
      default: () => ({}),
    }
  },

  computed: {
    groupedOptions () {
      if (!this.frameComponent) {
        return []
      }

      return this.frameComponent.options.reduce((groups, option) => {
        const group = groups.find(group => group.label === option.optionCategoryLabel)

        if (group) {
          group.options.push(option)
          return groups
        }

        return [...groups, { label: option.optionCategoryLabel, options: [option] }]
      }, [])
    },
  },

  methods: {
    optionIsPicked (option) {
      return this.chosenOptions.find(o => o.optionId === option.optionId) !== undefined
    },
  },
}
</script>
