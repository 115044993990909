<template>
  <div class="catalogue-wrap">
    <h1>Katalog</h1>

    <v-alert color="primary" dark icon="menu_book" class="mb-10">
      Hier finden Sie den aktuellen Katalog für das Manufaktur-Programm und unsere Lagerware.
    </v-alert>

    <div class="iframe-wrap">
      <iframe
        src="https://e.issuu.com/embed.html?identifier=q4oqyv8hjit3&amp;embedType=script#33575364/64012168"
        title="issuu.com"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        msallowfullscree
      />
    </div>

    <h2>Links</h2>

    <v-card outlined>
      <v-list dense>
        <template v-for="(link, i) in links">
          <v-list-item :key="`item_${i}`" :href="link.link" target="_blank" rel="noopener">
            <v-list-item-icon>
              <v-icon>link</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ link.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="i < links.length - 1" :key="i" />
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'catalogue',
  data () {
    return {
      links: [
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/WMF_Manufaktur_2016.pdf', label: 'WMF-Programm' },
        { link: 'https://www.studiolog.de/wmf_wordpress/wp-content/uploads/WMF_Lagerware_2016.pdf', label: 'Wittich Lagerware' },
        { link: 'http://www.facebook.com/WMFRad', label: 'WMF auf Facebook' },
        { link: 'http://www.wittich-gmbh.de/', label: 'Wittich GmbH' },
      ],
    }
  },

  mounted () {
    this.$store.commit('setBreadcrumbItems', [{ text: 'Katalog', disabled: true }])
  },
}
</script>

<style lang="scss">
  .catalogue-wrap {
    .iframe-wrap {
      height: 960px;
      background-color: #efefef;
      margin-bottom: 3rem;

      iframe {
        border:none;
        width:100%;
        height:100%;
      }
    }
  }
</style>
