<template>
  <v-navigation-drawer
    ref="componentColorSidebar"
    app
    hide-overlay
    right
    clipped
    disable-resize-watcher
    :width="380"
    :value="isVisible"
    class="color-sidebar px-4 py-6"
    @input="visibility => $emit('change:visibility', visibility)"
  >
    <v-btn
      fab
      x-small
      outlined
      color="primary"
      class="close-btn"
      elevation="0"
      @click.prevent="$emit('click:close')"
    >
      <v-icon small>
        close
      </v-icon>
    </v-btn>

    <div>
      <div v-if="toColorize" class="mb-1">
        <h2 class="mb-0">
          {{ toColorize.componentType.label }}
        </h2>

        <h3 class="font-weight-regular">
          {{ toColorize.label }}
        </h3>
      </div>
      <div class="d-flex align-center">
        <a @click.prevent="$emit('click:close')">
          Schließen
        </a>
      </div>
    </div>

    <v-divider class="my-6" />

    <color-picker
      v-if="isVisible"
      :value="toColorize ? toColorize.pickedColor : null"
      :to-colorize="toColorize"
      @input="color => $emit('choose:color', color)"
      @changed:visibility="visibility => $emit('changed:picker-visibility', showColorPicker)"
    />
  </v-navigation-drawer>
</template>

<script>
import ColorPicker from '@/components/ColorPicker'

export default {
  name: 'component-color-sidebar',

  components: {
    ColorPicker,
  },

  props: {
    toColorize: {
      type: Object,
      default: null,
    },

    isVisible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
  .color-sidebar {
    .close-btn {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
      z-index: 2;
    }
  }
</style>
