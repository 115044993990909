import Admin from '@/views/Admin/Admin'
import Companies from '@/views/Admin/Companies/Companies'
import CompanyDetails from '@/views/Admin/Companies/Details'
import CompanyOverview from '@/views/Admin/Companies/Overview'
import ComponentDetails from '@/views/Admin/Components/Details'
import ComponentOverview from '@/views/Admin/Components/Overview'
import Components from '@/views/Admin/Components/Components'
import ModelDetails from '@/views/Admin/Models/Details'
import ModelOverview from '@/views/Admin/Models/Overview'
import Models from '@/views/Admin/Models/Models'
import OptionCategories from '@/views/Admin/OptionCategories/OptionCategories'
import OptionCategory from '@/views/Admin/OptionCategories/OptionCategory'
import OptionCategoryOverview from '@/views/Admin/OptionCategories/OptionCategoryOverview'
import SeriesBikes from '@/views/Admin/SeriesBikes/Index'
import SeriesBikesOverview from '@/views/Admin/SeriesBikes/Overview'
import SeriesBikeDetails from '@/views/Admin/SeriesBikes/Details'

import store from '@/store'

const checkWithUser = check =>
  store.state.user.userChecked
    ? check()
    : store.dispatch('getUser', check)

export default [
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: (to, from, next) => checkWithUser(() => {
      return store.state.user.user !== null ? next() : next({ name: 'Login' })
    }),
    children: [
      {
        path: 'firmen',
        component: Companies,
        children: [
          {
            path: 'uebersicht',
            name: 'CompanyOverview',
            component: CompanyOverview,
            beforeEnter: (to, from, next) => checkWithUser(() =>
              store.getters.userCanEditCompanies ? next() : next(false)
            ),
          },
          {
            path: ':id?',
            name: 'CompanyDetails',
            component: CompanyDetails,
            beforeEnter: (to, from, next) => checkWithUser(() =>
              store.getters.userCanEditCompanies ? next() : next(false)
            ),
          },
        ],
      },
      {
        path: 'komponenten',
        component: Components,
        beforeEnter: (to, from, next) => checkWithUser(() =>
          store.getters.userCanEditComponents ? next() : next(false)
        ),
        children: [
          {
            path: 'uebersicht',
            name: 'ComponentOverview',
            component: ComponentOverview,
          },
          {
            path: 'komponente/:id?',
            name: 'ComponentDetails',
            component: ComponentDetails,
          },
        ],
      },
      {
        path: 'modellreihen',
        component: Models,
        beforeEnter: (to, from, next) => checkWithUser(() =>
          store.getters.userCanEditComponents ? next() : next(false)
        ),
        children: [
          {
            path: 'uebersicht',
            name: 'ModelOverview',
            component: ModelOverview,
          },
          {
            path: 'modellreihe/:id?',
            name: 'ModelDetails',
            component: ModelDetails,
          },
        ],
      },
      {
        path: 'optionskategorien',
        component: OptionCategories,
        beforeEnter: (to, from, next) => checkWithUser(() =>
          store.getters.userCanEditComponents ? next() : next(false)
        ),
        children: [
          {
            path: 'uebersicht',
            name: 'OptionCategoryOverview',
            component: OptionCategoryOverview,
          },
          {
            path: 'optionskategorie/:id?',
            name: 'OptionCategory',
            component: OptionCategory,
          },
        ],
      },
      {
        path: 'serienraeder',
        component: SeriesBikes,
        children: [
          {
            path: 'uebersicht',
            name: 'AdminSeriesBikesOverview',
            component: SeriesBikesOverview,
            beforeEnter: (to, from, next) => checkWithUser(() =>
              store.getters.userCanManageSeries ? next() : next(false)
            ),
          },
          {
            path: ':id?',
            name: 'AdminSeriesBikeDetails',
            component: SeriesBikeDetails,
          },
        ],
      },
    ],
  },
]
