<template>
  <v-menu
    v-if="slotsWithoutImages.length > 0"
    v-model="showComponentMenu"
    :close-on-click="false"
    :close-on-content-click="false"
    :nudge-width="200"
    left
    offset-overflow
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        small
        class="ml-auto"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>
          brush
        </v-icon>
        Weitere Farben
      </v-btn>
    </template>
    <v-card>
      <v-list dense>
        <v-list-item
          v-for="(slot, i) in slotsWithoutImages"
          :key="i"
          :input-value="activeComponent && activeComponent.componentId === slot.pickedComponent.componentId"
          @click="$emit('click:componenent', slot.pickedComponent)"
        >
          <v-list-item-avatar
            v-if="slot.pickedComponent.pickedColor"
            :color="slot.pickedComponent.pickedColor.colorValue"
          />
          <v-list-item-avatar v-else>
            <v-icon small>
              check_box_outline_blank
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ slot.pickedComponent.label }}</v-list-item-title>
            <v-list-item-subtitle>{{ slot.componentType.label }}</v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ slot.pickedComponent.pickedColor ? slot.pickedComponent.pickedColor.label : 'Keine Farbe gewählt' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-spacer />

        <v-btn text @click="closeMenu">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'component-color-menu',

  props: {
    activeComponent: {
      type: Object,
      default: null,
    },

    slotsWithoutImages: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      showComponentMenu: false,
    }
  },

  methods: {
    closeMenu () {
      this.showComponentMenu = false
      this.$emit('close')
    }
  },
}
</script>
