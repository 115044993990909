import CartApi from '@/api/Cart.js'
import ShopApi from '@/api/Shop.js'

export default {
  state: {
    cart: null,
    loading: false,
  },

  getters: {
    cartItems (state) {
      return state.cart ? state.cart.cartItems : []
    },
  },

  mutations: {
    setCart (state, cart) {
      state.cart = cart
    },

    setLoading (state, isLoading) {
      state.loading = isLoading
    },
  },

  actions: {
    /**
     * Gets the cart of the current user.
     *
     * @param {object} context
     * @returns {void}
     */
    async getCart ({ commit }) {
      commit('setLoading', true)
      const res = await CartApi.get()
      commit('setLoading', false)

      if (res.ok) {
        const cart = await res.json()
        commit('setCart', cart)
      }
    },

    /**
     * Adds the desired amount of the given article-number to the cart and
     * reloads it.
     *
     * @param {object} context
     * @param {object} options
     * @param {number} options.amount
     * @param {string} options.articleNumber
     * @returns {void}
     */
    async addItem ({ dispatch }, { amount, articleNumber }) {
      if (!articleNumber || !amount) {
        return
      }

      const res = await CartApi.addItem(amount, articleNumber)
      res.ok && await dispatch('getCart')
      return res
    },

    /**
     * Removes the given articlenumber from the cart and reloads it.
     *
     * @param {object} context
     * @param {string} articleNumber
     * @returns {void}
     */
    async removeCartItem ({ dispatch }, articleNumber) {
      const res = await CartApi.deleteItem(articleNumber)
      res.ok && await dispatch('getCart')
      return res
    },

    /**
     * Updates the amount of the given articlenumber in the cart, reloads it.
     *
     * @param {object} context
     * @param {object} options
     * @param {number} options.amount
     * @param {string} options.articleNumber
     * @returns {void}
     */
    async setItemAmount ({ dispatch }, { amount, articleNumber }) {
      const res = await CartApi.updateItem(amount, articleNumber)
      res.ok && await dispatch('getCart')
      return res
    },

    async orderCart ({ dispatch }, { comment }) {
      console.log('c1', comment)
      const res = await ShopApi.createOrder(comment)
      res.ok && await dispatch('getCart')
      return res
    }
  },
}
