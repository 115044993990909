var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-orders-wrap"},[_c('div',{staticClass:"order-table-wrap mb-10"},[_c('data-table',{attrs:{"items":_vm.orders,"columns":_vm.columns,"default-options":{ sortBy: ['orderTime'], sortDesc: [true] },"search-placeholder":"Suche"},on:{"dblclick:row":function (e, item) { return _vm.showConfigurator(item.item); }},scopedSlots:_vm._u([{key:"item.orderTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReadableDate(item.orderTime))+" ")]}},{key:"item.orderActions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showConfigurator(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" refresh ")])],1)]}},{key:"item.customerPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("centsToEuro")(item.customerPrice))+" ")]}},{key:"item.retailerPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("centsToEuro")(item.retailerPrice))+" ")]}}],null,true)})],1),_c('h2',{staticClass:"mb-4"},[_vm._v(" Serienräder ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.seriesBikeOrders,"headers":_vm.seriesBikeOrderColumns,"options":{
      sortBy: ['date'],
      sortDesc: [true],
    },"show-expand":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReadableDate(item.createdAt))+" ")]}},{key:"item.total",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("centsToEuro")(item.total))+" ")]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"ma-1 rounded-0",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(" Artikel ")]),_c('th',[_vm._v(" Farbe ")]),_c('th',{staticClass:"text-right"},[_vm._v(" Anzahl ")]),_c('th',{staticClass:"text-right"},[_vm._v(" Einzelpreis ")]),_c('th',{staticClass:"text-right"},[_vm._v(" Gesamtpreis ")])])]),_c('tbody',_vm._l((item.orderItems),function(cartItem,x){return _c('tr',{key:x},[_c('td',[_vm._v(" "+_vm._s(cartItem.frame.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(cartItem.color.name)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(cartItem.amount)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("centsToEuro")(cartItem.price))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("centsToEuro")(cartItem.total))+" ")])])}),0)]},proxy:true}],null,true)}),_c('div',{staticClass:"d-none"},[_vm._v(" "+_vm._s(item)+" ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }