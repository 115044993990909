<template>
  <div class="series-bikes-details--wrap">
    <template v-if="bikeId && bike">
      <component-img
        v-if="bike.hasImage"
        :id="bike.standardBikeSeriesId"
        :has-image="bike.hasImage"
        :alt="bike.title"
        :height="600"
        is-series
        contain
        class="mb-10"
      />

      <h1>
        {{ bike.title }}
      </h1>

      <v-row v-if="dataLoaded">
        <v-col :cols="12" :lg="colWidthLg">
          <div
            v-html="bike.description"
            class="mb-10"
          />

          <template v-if="maxAmount > 0 && sortedAvailableVariants.length > 0">
            <plus-minus-input
              v-model="amount"
              :min="minAmount"
              :max="maxAmount"
              @input="getCurrentArticleInfo()"
            />

            <div class="my-2 font-weight-bold">
              Noch {{ maxAmount }} verfügbar
            </div>
          </template>

          <availability-status
            v-if="availabilityStatus"
            :status="availabilityStatus"
            class="mt-10"
          />

          <v-list
            dense
            color="grey lighten-4"
            class="pa-0 mb-4"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Radkosten</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="text-body-2">
                {{ singlePrice | centsToEuro }}
              </v-list-item-action>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Radkosten gesamt</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="text-body-2">
                {{ price | centsToEuro }}
              </v-list-item-action>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Versandkosten
                  <sup>*</sup>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="text-body-2">
                {{ shippingCosts | centsToEuro }}
              </v-list-item-action>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Versicherungskosten</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="text-body-2">
                {{ insuranceCosts | centsToEuro }}
              </v-list-item-action>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-body-1">
                  Gesamtkosten
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="font-weight-bold text-body-1">
                {{ total | centsToEuro }}
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-btn
            v-if="isBuyable && total"
            depressed
            large
            tile
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="addToCart()"
          >
            <v-icon left>
              shopping_cart
            </v-icon>
            In den Warenkorb
          </v-btn>
        </v-col>

        <v-col v-if="articleVariants.length > 0" :cols="12" :lg="colWidthLg">
          <v-list two-line>
            <template v-for="(frame, i) in sortedFrames">
              <v-list-item
                :key="frame.standardBikeSeriesFrameId"
                :input-value="frameIsChosen(frame)"
                :disabled="!frameChoosable(frame.standardBikeSeriesFrameId)"
                color="primary"
                @click="chosenFrame = frame"
              >
                <v-list-item-content>
                  <v-list-item-title
                    :class="{
                      'font-weight-bold': frameIsChosen(frame)
                    }"
                  >
                    {{ frame.frameSize }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    :class="{
                      'primary--text': frameIsChosen(frame)
                    }"
                  >
                    {{ frame.frameType }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="frameIsChosen(frame)">
                  <v-btn icon>
                    <v-icon color="primary">
                      check
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="i < bike.frames.length - 1"
                :key="`${frame.standardBikeSeriesFrameId}_div`"
              />
            </template>
          </v-list>
        </v-col>

        <v-col v-if="articleVariants.length > 0" :cols="12" :lg="colWidthLg">
          <v-list two-line>
            <template v-for="(color, x) in sortedColors">
              <v-list-item
                :key="color.standardBikeSeriesColorId"
                :input-value="colorIsChosen(color)"
                :disabled="!colorChoosable(color.standardBikeSeriesColorId)"
                color="primary"
                @click="chosenColor = color"
              >
                <v-list-item-content>
                  <v-list-item-title
                    :class="{
                      'font-weight-bold': colorIsChosen(color)
                    }"
                  >
                    {{ color.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="colorIsChosen(color)">
                  <v-btn icon>
                    <v-icon color="primary">
                      check
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="x < bike.colors.length - 1"
                :key="`${color.standardBikeSeriesColorId}_div`"
              />
            </template>
          </v-list>
        </v-col>

        <v-col v-if="bike.batteries.length > 0" :cols="12" :lg="colWidthLg">
          <v-list two-line>
            <template v-for="(battery, y) in bike.batteries">
              <v-list-item
                :key="`battery_${y}`"
                :input-value="batteryIsChosen(battery)"
                :disabled="!batteryChoosable(battery)"
                color="primary"
                @click="chosenBattery = battery"
              >
                <v-list-item-content>
                  <v-list-item-title
                    :class="{
                      'font-weight-bold': batteryIsChosen(battery)
                    }"
                  >
                    {{ battery.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Noch {{ battery.numberAvailable }} verfügbar.
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="batteryIsChosen(battery)">
                  <v-btn icon>
                    <v-icon color="primary">
                      check
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="y < bike.batteries.length - 1"
                :key="`battery_${y}_div`"
              />
            </template>
          </v-list>
        </v-col>
      </v-row>
    </template>

    <v-overlay :value="isLoading || !dataLoaded" color="white">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
import SeriesApi from '@/api/Series'
import ShopApi from '@/api/Shop'

import ComponentImg from '@/components/ComponentImg'
import AvailabilityStatus from '@/components/AvailabilityStatus.vue'
import PlusMinusInput from '@/components/PlusMinusInput.vue'

export default {
  name: 'series-bikes-details',

  components: {
    AvailabilityStatus,
    ComponentImg,
    PlusMinusInput,
  },

  data () {
    return {
      amount: 1,
      articleNumber: null,
      minAmount: 1,
      maxAmount: 1,
      articleVariants: [],
      availabilityStatus: '',
      buyableStati: ['available_instant', 'available_next_day'],
      dataLoaded: false,
      bike: null,
      chosenColor: null,
      chosenFrame: null,
      chosenBattery: null,
      isLoading: false,
      price: 0,
      total: 0,
      singlePrice: 0,
      shippingCosts: 0,
      insuranceCosts: 0,
    }
  },

  computed: {
    /**
     * Some bikes have batteries to choose from, which should get displayed in
     * an additional column.
     */
    colWidthLg () {
      return this.bike && this.bike.batteries.length === 0 ? 4 : 3
    },

    bikeId () {
      return this.$route.params.id ? +this.$route.params.id : null
    },

    isBuyable () {
      return this.amount > 0 && this.buyableStati.includes(this.availabilityStatus)
    },

    sortedAvailableVariants () {
      return this.articleVariants
        .filter(({ availabilityStatus }) => this.buyableStati.includes(availabilityStatus))
        .sort((a, b) =>
          a.frame.frameType.localeCompare(b.frame.frameType) ||
          a.frame.frameSize - b.frame.frameSize ||
          a.color.name.localeCompare(b.color.name)
        )
    },

    sortedColors () {
      return this.bike === null
        ? []
        : [...this.bike.colors].sort((a, b) => a.name.localeCompare(b.name))
    },

    sortedFrames () {
      return this.bike === null
        ? []
        : [...this.bike.frames].sort((a, b) => a.frameType.localeCompare(b.frameType) || a.frameSize - b.frameSize)
    },
  },

  watch: {
    chosenColor (_, from) {
      from !== null && this.onChoiceChange()
    },

    chosenFrame (to, from) {
      // initial choice should by fine (since its picked by us)
      if (from === null) {
        return
      }

      const desiredVariant = this.articleVariants.find(({ color, frame }) =>
        frame.standardBikeSeriesFrameId === to.standardBikeSeriesFrameId &&
        color.standardBikeSeriesColorId === this.chosenColor.standardBikeSeriesColorId
      )

      // previously/current chosen color isn't available for the newly chosen
      // frame - so we have to pick another one
      if (!desiredVariant || !this.buyableStati.includes(desiredVariant.availabilityStatus)) {
        const alternativeVariant = this.sortedAvailableVariants.find(({ frame }) => frame.standardBikeSeriesFrameId === to.standardBikeSeriesFrameId)
        alternativeVariant && (this.chosenColor = alternativeVariant.color)
      }

      this.onChoiceChange()
    },
  },

  mounted () {
    this.init()
  },

  methods: {
    async init () {
      await Promise.all([
        this.loadBike(),
        this.getArticleVariants(),
      ])

      this.dataLoaded = true
      this.setInitialChoice()
    },

    /**
     * loadBike
     *
     * @returns {Promise}
     */
    async loadBike () {
      if (this.bikeId) {
        const res = await SeriesApi.get(this.bikeId)
        res.ok && (this.bike = await res.json())
      }
    },

    /**
     * Initially we want to preselect a frame and a color. But it's possible
     * that combinations aren't currently available - so we have to pick the
     * first buyable combination.
     *
     * @returns {undefined}
     */
    setInitialChoice () {
      if (this.sortedAvailableVariants.length > 0) {
        this.chosenColor = this.sortedAvailableVariants[0].color
        this.chosenFrame = this.sortedAvailableVariants[0].frame
        this.onChoiceChange()
      } else {
        this.availabilityStatus = 'not_available'
      }
    },

    /**
     * When the user picks a new article-variant (combination of a frame and a
     * color) we have to get its price and the status/availability.
     *
     * @returns {undefined}
     */
    async onChoiceChange () {
      if (!this.bike || !this.chosenFrame || !this.chosenColor) {
        return
      }

      this.amount = 1
      this.getCurrentArticleInfo()
    },

    /**
     * The bike-dataset has the lists of related frames and colors, but those
     * don't have the availability-information (it's possible that combinations
     * aren't available at the moment). So we have to load the variants with
     * their status too.
     *
     * @returns {Promise}
     */
    async getArticleVariants () {
      const res = await ShopApi.getPrices(this.bikeId)
      res.ok && (this.articleVariants = await res.json())
    },

    /**
     * getCurrentArticleInfo
     *
     * @returns {Promise}
     */
    async getCurrentArticleInfo () {
      this.isLoading = true

      const res = await ShopApi.getPrice(
        this.bike.standardBikeSeriesId,
        this.chosenColor.standardBikeSeriesColorId,
        this.chosenFrame.standardBikeSeriesFrameId,
        this.amount
      )

      this.isLoading = false

      if (res.ok) {
        const {
          articleNumber,
          price,
          singlePrice,
          shippingCosts,
          insuranceCosts,
          availabilityStatus,
          freeAmount,
          total,
        } = await res.json()

        this.articleNumber = articleNumber
        this.price = price
        this.total = total
        this.singlePrice = singlePrice
        this.shippingCosts = shippingCosts
        this.insuranceCosts = insuranceCosts
        this.availabilityStatus = availabilityStatus
        this.maxAmount = freeAmount
      }
    },

    /**
     * Checks if the given color-id is available/choosable for the currently
     * selected frame.
     *
     * @param {number} colorId
     * @returns {boolean}
     */
    colorChoosable (colorId) {
      return this.articleVariants.length && this.chosenFrame && this.articleVariants.filter(variant =>
        variant.frame.standardBikeSeriesFrameId === this.chosenFrame.standardBikeSeriesFrameId &&
        variant.color.standardBikeSeriesColorId === colorId &&
        this.buyableStati.includes(variant.availabilityStatus)
      ).length > 0
    },

    /**
     * Checks if the given color is currently chosen.
     *
     * @param {object} color
     * @returns {boolean}
     */
    colorIsChosen (color) {
      return this.chosenColor && this.chosenColor.standardBikeSeriesColorId === color.standardBikeSeriesColorId
    },

    /**
     * Checks if the frame with the given id is currently available, choosable.
     *
     * @param {number} frameId
     * @returns {boolean}
     */
    frameChoosable (frameId) {
      return this.articleVariants.length && this.articleVariants.filter(variant =>
        variant.frame.standardBikeSeriesFrameId === frameId &&
        this.buyableStati.includes(variant.availabilityStatus)
      ).length > 0
    },

    /**
     * Checks if the given frame is currently chosen.
     *
     * @param {object} frame
     * @returns {boolean}
     */
    frameIsChosen (frame) {
      return this.chosenFrame && this.chosenFrame.standardBikeSeriesFrameId === frame.standardBikeSeriesFrameId
    },

    /**
     * batteryChoosable
     *
     * @param {object} battery
     * @returns {boolean}
     */
    batteryChoosable (battery) {
      return true
    },

    /**
     * batteryIsChosen
     *
     * @param {object} battery
     * @returns {boolean}
     */
    batteryIsChosen (battery) {
      return this.chosenBattery && this.chosenBattery.articleNumber === battery.articleNumber
    },

    /**
     * addToCart
     *
     * @returns {Promise}
     */
    async addToCart () {
      this.isLoading = true
      const res = await this.$store.dispatch('addItem', { amount: this.amount, articleNumber: this.articleNumber })
      this.isLoading = false

      if (res && res.ok) {
        return this.$store.commit('setSnackbar', {
          text: 'Artikel zum Warenkorb hinzugefügt',
          color: 'success',
        })
      }

      this.$store.commit('setSnackbar', {
        text: res.status === 400 ? 'Gewünschte Anzahl nicht verfügbar' : 'Artikel konnte nicht hinzugefügt werden',
        color: 'error',
      })
    },
  },
}
</script>

<style lang="scss">
  .series-bikes-details--wrap {
    .amount-input {
      border-radius: 0;
      max-width: 200px;

      label {
        color: #212121;
      }

      fieldset {
        border-color: #212121;
      }
    }
  }
</style>
