<template>
  <div class="cart-wrap">
    <h1>Warenkorb</h1>

    <v-sheet
      v-if="!isLoading && items.length === 0"
      color="primary"
      class="pa-4 white--text d-flex align-center"
    >
      <v-icon color="white" class="mr-3">
        info
      </v-icon>
      Ihr Warenkorb ist noch leer.
    </v-sheet>

    <template v-else>
      <v-sheet
        v-if="cart && cart.dirty"
        color="primary"
        class="pa-4 white--text d-flex align-center mb-10"
      >
        <v-icon color="white" class="mr-3">
          info
        </v-icon>
        Achtung: An Ihrem Warenkorb hat sich etwas geändert! <br>
        Bitte kontrollieren Sie vor dem Absenden Ihre Bestellung.
      </v-sheet>

      <v-data-table
        :loading="isLoading"
        :items="items"
        :headers="columns"
      >
        <template v-slot:[`item.image`]="{ item }">
          <component-img
            :id="item.standardBikeSeriesId"
            :has-image="item.hasImage"
            :height="60"
            :width="100"
            is-series
            class="my-1"
          />
        </template>

        <template v-slot:[`item.title`]="{ item }">
          {{ item.title }}

          <div v-if="item.battery">
            {{ item.battery.name }}
          </div>
        </template>

        <template v-slot:[`item.color`]="{ item }">
          {{ item.color.name }}
        </template>

        <template v-slot:[`item.frame`]="{ item }">
          {{ item.frame.name }}
        </template>

        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | centsToEuro }}
        </template>

        <template v-slot:[`item.total`]="{ item }">
          {{ item.total | centsToEuro }}
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <plus-minus-input
            v-model="item.amount"
            :min="1"
            :max="item.freeAmount"
            small
            centered-input
            :loading="isLoading"
            @input="amount => setItemAmount(amount, item.articleNumber)"
          />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            class="mr-1"
            @click="removeItem(item.articleNumber)"
          >
            <v-icon small>
              delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <v-sheet
        v-if="cart"
        class="mt-10 pa-6"
        color="grey lighten-4"
      >
        <div class="d-flex">
          <div class="flex-grow-1">
            Artikelkosten:
          </div>
          {{ cart.articleCosts | centsToEuro }}
        </div>
        <v-divider class="my-2" />
        <div class="d-flex">
          <div class="flex-grow-1">
            Versandkosten:
          </div>
          {{ cart.shippingCosts | centsToEuro }}
        </div>
        <v-divider class="my-2" />
        <div class="d-flex">
          <div class="flex-grow-1">
            Versicherungskosten:
          </div>
          {{ cart.insuranceCosts | centsToEuro }}
        </div>
        <v-divider class="my-2" />
        <div class="d-flex font-weight-bold">
          <div class="flex-grow-1">
            Gesamtpreis:
          </div>
          {{ cart.total | centsToEuro }}
        </div>
      </v-sheet>

      <v-form
        v-model="formIsValid"
        @submit.prevent="showConfirmationDialog = true"
      >
        <v-textarea
          v-model="comment"
          label="Kommentar"
          hide-details="auto"
          outlined
          counter
          :rules="[v => v.length <= commentMaxLength || `Maximal ${commentMaxLength} Zeichen.`]"
          class="my-10 rounded-0"
        />

        <div class="d-flex">
          <v-btn
            type="submit"
            depressed
            large
            tile
            color="primary"
            class="ml-auto"
            :disabled="!formIsValid"
          >
            Bestellen
          </v-btn>
        </div>
      </v-form>
    </template>

    <v-dialog
      v-model="showConfirmationDialog"
      width="500"
      max-width="90%"
    >
      <v-card :loading="orderInProgress">
        <v-card-title>
          Bestellung bestätigen
        </v-card-title>

        <v-card-text>
          Wollen Sie Ihre Auswahl zahlungspﬂichtig bestellen?
        </v-card-text>

        <v-card-actions class="px-6 pb-4">
          <v-btn
            depressed
            tile
            @click="showConfirmationDialog = false"
          >
            Abbrechen
          </v-btn>

          <v-spacer />

          <v-btn
            depressed
            tile
            color="primary"
            :loading="orderInProgress"
            @click="order()"
          >
            Bestellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ShopApi from '@/api/Shop.js'

import ComponentImg from '@/components/ComponentImg'
import PlusMinusInput from '@/components/PlusMinusInput.vue'

export default {
  name: 'cart',

  components: {
    ComponentImg,
    PlusMinusInput,
  },

  data () {
    return {
      columns: [
        { value: 'actions', text: '', sortable: false, align: 'right', width: 50 },
        { value: 'image', text: 'Vorschau', width: 120, sortable: false, filterable: false },
        { value: 'title', text: 'Bezeichnung' },
        { value: 'color', text: 'Farbe' },
        { value: 'frame', text: 'Rahmen' },
        { value: 'amount', text: 'Anzahl', width: 150 },
        { value: 'price', text: 'Einzelpreis', align: 'right' },
        { value: 'total', text: 'Gesamtpreis', align: 'right' },
      ],
      comment: '',
      commentMaxLength: 30,
      formIsValid: null,
      orderInProgress: false,
      showConfirmationDialog: false,
    }
  },

  computed: {
    cart () {
      return this.$store.state.cart.cart
    },

    isLoading () {
      return this.$store.state.cart.loading
    },

    items () {
      return this.$store.getters.cartItems
    },
  },

  mounted () {
    this.$store.dispatch('getCart')
  },

  methods: {
    /**
     * setItemAmount
     *
     * @param {string} articleNumber
     * @param {number} amount
     * @returns {Promise}
     */
    async setItemAmount (amount, articleNumber) {
      const res = await this.$store.dispatch('setItemAmount', { amount, articleNumber })

      if (!res.ok) {
        this.$store.commit('setSnackbar', {
          text: res.status === 400 ? 'Gewünschte Anzahl nicht verfügbar' : 'Ein Fehler ist aufgetreten',
          color: 'error',
        })
      }
    },

    /**
     * removeItem
     *
     * @param {string} articleNumber
     * @returns {Promise}
     */
    async removeItem (articleNumber) {
      const res = await this.$store.dispatch('removeCartItem', articleNumber)

      this.$store.commit('setSnackbar', {
        text: res.ok ? 'Serienrad entfernt' : 'Ein Fehler ist aufgetreten',
        color: res.ok ? 'success' : 'error',
      })
    },

    /**
     * order
     *
     * @returns {Promise}
     */
    async order () {
      this.orderInProgress = true
      const res = await ShopApi.createOrder(this.comment)
      this.$store.dispatch('getCart')
      this.orderInProgress = false
      this.showConfirmationDialog = false

      if (!res.ok) {
        return this.$store.commit('setSnackbar', {
          text: 'Bestellung konnte nicht abgeschlossen werden, probieren sie es später noch einmal oder wenden sie sich an den Support',
          color: 'error',
        })
      }

      this.$router.push({ name: 'AfterBuy' })
    },
  },
}
</script>
