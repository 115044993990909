<template>
  <transition name="scale">
    <div v-if="errorsToShow.length > 0" class="config-error-indicator--wrap">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="error"
            v-bind="attrs"
            v-on="on"
          >
            report
          </v-icon>
        </template>
        <div>
          <div v-for="({ message }, i) in errorsToShow" :key="i">
            <span v-if="errorsToShow.length > 1">
              -
            </span>
            {{ message }}
          </div>
        </div>
      </v-tooltip>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'config-error-indicator',

  props: {
    componentId: {
      type: Number,
      default: null,
    },

    slotId: {
      type: Number,
      default: null,
    },

    errors: {
      type: Array,
      default: () => ([]),
    }
  },

  computed: {
    errorsToShow () {
      return this.componentId && this.slotId ? this.getComponentErrors() : this.getSlotErrors()
    },
  },

  methods: {
    /**
     * Checks if there are errors related to the component.
     *
     * @returns {boolean}
     */
    getComponentErrors () {
      return this.errors.filter(({ slotId, componentId }) =>
        slotId === this.slotId && (Array.isArray(componentId)
          ? componentId.includes(this.componentId)
          : componentId === this.componentId
        )
      )
    },

    /**
     * Checks if there are errors related to the slot.
     *
     * @returns {array}
     */
    getSlotErrors () {
      if (!this.slotId) {
        return []
      }

      return this.errors
        .filter(({ slotId }) => slotId === this.slotId) // only this slot
        .filter(({ message }, i, self) => i === self.findIndex(error => error.message === message)) // no duplicates
    },
  },
}
</script>

<style lang="scss">
  .scale-enter-active {
    animation: scale-in .2s;
  }
  .scale-leave-active {
    animation: scale-in .2s reverse;
  }

  @keyframes scale-in {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  .v-application {
    .v-tooltip__content {
      background: #333333;
    }
  }
</style>
