var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-company-overview-wrap"},[_c('div',{staticClass:"action-header"},[_c('h1',[_vm._v("Firmen")]),_c('v-btn',{attrs:{"to":{ name: 'CompanyDetails' },"exact":"","depressed":""}},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v(" create ")]),_vm._v(" Neue Firma ")],1)],1),_c('div',{staticClass:"company-table-wrap"},[_c('data-table',{attrs:{"is-loading":_vm.isLoading,"items":_vm.companies,"columns":_vm.columnOptions,"state-key":"companies","search-placeholder":"Kundennummer/Name/.."},on:{"dblclick:row":function (e, ref) {
	var item = ref.item;

	return _vm.$router.push({ name: 'CompanyDetails', params: { id: item.companyId } });
}},scopedSlots:_vm._u([{key:"item.surchargeFactor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.surchargeFactor.toFixed(2).replace('.', ','))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){_vm.companyToRemove = item.companyId}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1),_c('v-btn',{attrs:{"icon":"","to":{ name: 'CompanyDetails', params: { id: item.companyId } }}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" create ")])],1)]}}],null,true)})],1),_c('base-dialog',{attrs:{"headline":"Firma wirklich löschen?","is-visible":_vm.companyToRemove !== null},on:{"cancel":function($event){_vm.companyToRemove = null},"ok":_vm.deleteSelectedCompany}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }