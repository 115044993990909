<template>
  <v-navigation-drawer
    ref="dekorSidebar"
    app
    hide-overlay
    right
    fixed
    clipped
    disable-resize-watcher
    :width="340"
    :value="isVisible"
    @input="visibility => $emit('change:visibility', visibility)"
  >
    <div class="pa-5">
      <bike-dekor :frame-component="frameComponent" />
      <v-divider class="my-10" />
      <bike-mudguard />
    </div>
  </v-navigation-drawer>
</template>

<script>
import BikeDekor from './BikeDekor'
import BikeMudguard from './BikeMudguard'

export default {
  name: 'dekor-sidebar',

  components: {
    BikeDekor,
    BikeMudguard,
  },

  props: {
    frameComponent: {
      type: Object,
      default: null,
    },

    isVisible: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
