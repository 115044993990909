<template>
  <div class="datatable-wrap">
    <div v-if="isLoading" class="text-center mb-4">
      <v-progress-circular
        color="primary"
        indeterminate
        rounded
        height="6"
        class="mb-4"
      />
      <div>
        Daten werden geladen
      </div>
    </div>

    <v-data-table
      v-if="items.length"
      :headers="columns"
      :items="filteredItems"
      :items-per-page="pageSize"
      :search="search"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': pageSizes
      }"
      locale="de-DE"
      class="elevation-1"
      @click:row="(e, item) => $emit('click:row', e, item)"
      @dblclick:row="(e, item) => $emit('dblclick:row', e, item)"
    >
      <template v-if="!hideSearch" v-slot:top>
        <v-row>
          <v-col :md="6">
            <v-text-field
              :value="search"
              prepend-inner-icon="search"
              class="pa-4"
              hide-details
              outlined
              dense
              :label="searchPlaceholder || ''"
              clearable
              @input="setSearch"
            />
          </v-col>

          <v-col :md="6">
            <v-select
              v-if="filterColumn"
              :value="filter"
              :items="filterOptions"
              label="Filter"
              prepend-inner-icon="filter_list"
              class="pa-4"
              hide-details
              outlined
              dense
              clearable
              @change="setFilter"
            />
          </v-col>
        </v-row>

        <v-divider class="mt-3" />
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="data">
        <slot v-if="data && data.item" :name="slot" :item="data.item" />
        <slot v-else :name="slot" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'datatable',

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    columns: {
      type: Array,
      default: () => ([]),
    },
    defaultOptions: {
      type: Object,
      default: () => ({
        sortBy: ['label'],
        sortDesc: [false]
      }),
    },
    stateKey: {
      type: String,
      default: null,
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      pageSizes: [5, 10, 25, 50, -1],
      pageSize: 10,
      currentPage: 1,
      input: '',
    }
  },

  computed: {
    /**
     * Checks if there's a column within the current dataset defined as a filter.
     *
     * @returns {boolean}
     */
    filterColumn () {
      return this.columns.find(column => column.isFilter)
    },

    /**
     * Creates the options for the filter-select (if a filter is defined).
     *
     * @returns {array}
     */
    filterOptions () {
      const options = this.filterColumn ? this.items.reduce((options, item) => {
        const key = this.filterColumn.value
        const value = this.filterColumn.filterKey
          ? this.getValueByPath(item, this.filterColumn.filterKey)
          : item[key]

        if (!options.includes(value)) {
          options.push({ text: value, value: value })
        }

        return options
      }, []) : []

      options.sort((a, b) => a.text.localeCompare(b.text))
      return options
    },

    /**
     * Items matching the current filter.
     *
     * @returns {array}
     */
    filteredItems () {
      return this.filter && this.filter.length ? this.items.filter(item => {
        if (this.filterColumn && this.filter !== '') {
          const value = this.filterColumn.filterKey
            ? this.getValueByPath(item, this.filterColumn.filterKey)
            : item[this.filterColumn.value]

          return value === this.filter
        } else {
          return true
        }
      }) : this.items
    },

    search () {
      if (this.stateKey) {
        const state = this.$store.state.filters[this.stateKey]
        return state ? state.search : ''
      }

      return this.input
    },

    filter () {
      const state = this.$store.state.filters[this.stateKey]
      return state ? state.filter : ''
    },

    options: {
      get () {
        const state = this.$store.state.filters[this.stateKey]
        return state && state.options ? state.options : this.defaultOptions
      },
      set (options) {
        this.items.length && this.$store.commit('updateOptions', { key: this.stateKey, options })
      },
    },
  },

  mounted () {
    this.pageSize = this.pageSizes[0].value
  },

  methods: {
    /**
     * Returns the value found at the path of the given object.
     *
     * @param {Object} object
     * @param {String} path
     * @returns {any}
     */
    getValueByPath (object, path) {
      return path.split('.').reduce((res, key) => res[key], object)
    },

    setFilter (filter) {
      this.$store.commit('updateFilter', { key: this.stateKey, filter })
    },

    setSearch (search) {
      this.stateKey
        ? this.$store.commit('updateSearch', { key: this.stateKey, search })
        : (this.input = search)
    },

    setPage (page) {
      this.currentPage = page
    },

    setPageSize (pageSize) {
      this.pageSize = pageSize
      this.currentPage = 1
    },
  },
}
</script>
