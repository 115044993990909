<template>
  <div class="account-orders-wrap">
    <div class="order-table-wrap mb-10">
      <data-table
        :items="orders"
        :columns="columns"
        :default-options="{ sortBy: ['orderTime'], sortDesc: [true] }"
        search-placeholder="Suche"
        @dblclick:row="(e, item) => showConfigurator(item.item)"
      >
        <template v-slot:[`item.orderTime`]="{ item }">
          {{ getReadableDate(item.orderTime) }}
        </template>

        <template v-slot:[`item.orderActions`]="{ item }">
          <v-btn icon @click="showConfigurator(item)">
            <v-icon color="primary">
              refresh
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.customerPrice`]="{ item }">
          {{ item.customerPrice | centsToEuro }}
        </template>

        <template v-slot:[`item.retailerPrice`]="{ item }">
          {{ item.retailerPrice | centsToEuro }}
        </template>
      </data-table>
    </div>

    <h2 class="mb-4">
      Serienräder
    </h2>

    <v-data-table
      :items="seriesBikeOrders"
      :headers="seriesBikeOrderColumns"
      :options="{
        sortBy: ['createdAt'],
        sortDesc: [true],
      }"
      show-expand
      class="elevation-1"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ getReadableDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.orderItems`]="{ item }">
        {{ item.orderItems.length }}
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ item.total | centsToEuro }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-simple-table dense class="ma-1 rounded-0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    Bezeichnung
                  </th>
                  <th>
                    Rahmen (Größe / Art)
                  </th>
                  <th>
                    Farbe
                  </th>
                  <th class="text-right">
                    Anzahl
                  </th>
                  <th class="text-right">
                    Einzelpreis
                  </th>
                  <th class="text-right">
                    Gesamtpreis
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(cartItem, x) in item.orderItems"
                  :key="x"
                >
                  <td>
                    {{ cartItem.frame.name }}
                  </td>
                  <td>
                    {{ cartItem.frame.frameSize }} / {{ cartItem.frame.frameType }}
                  </td>
                  <td>
                    {{ cartItem.color.name }}
                  </td>
                  <td class="text-right">
                    {{ cartItem.amount }}
                  </td>
                  <td class="text-right">
                    {{ cartItem.price | centsToEuro }}
                  </td>
                  <td class="text-right">
                    {{ cartItem.total | centsToEuro }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="d-none">
            {{ item }}
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns'

import OrderApi from '@/api/Order'
import DataTable from '@/components/DataTable'
import StandardBikeSeriesOrderApi from '@/api/StandardBikeSeriesOrder'

export default {
  name: 'account-orders',

  components: {
    DataTable,
  },

  data () {
    return {
      orders: [],
      seriesBikeOrders: [],
    }
  },

  computed: {
    columns () {
      return [
        { value: 'orderTime', text: 'Bestellzeitpunkt' },
        {
          value: this.user.showCustomerPrice ? 'customerPrice' : 'retailerPrice',
          text: this.user.showCustomerPrice ? 'Preis' : 'Fachhändlerpreis',
          align: 'right'
        },
        { value: 'orderActions', text: 'Aktionen', align: 'center' },
      ]
    },

    seriesBikeOrderColumns () {
      return [
        { value: 'createdAt', text: 'Bestellzeitpunkt' },
        { value: 'comment', text: 'Kommentar' },
        { value: 'orderItems', text: 'Positionen' },
        { value: 'total', text: 'Gesamtpreis', align: 'right' },
        { text: '', value: 'data-table-expand' },
      ]
    },

    user () {
      return this.$store.state.user.user
    },
  },

  mounted () {
    this.loadOrders()
    this.loadStandardBikeSeriesOrders()
  },

  methods: {
    /**
     * Loads orders of the current user.
     *
     * @returns {void}
     */
    async loadOrders () {
      const res = await OrderApi.getAll()
      res.ok && (this.orders = await res.json())
    },

    /**
     * Loads orders (standard bike series) of the current user.
     *
     * @returns {void}
     */
    async loadStandardBikeSeriesOrders () {
      const res = await StandardBikeSeriesOrderApi.getAll()
      res.ok && (this.seriesBikeOrders = await res.json())
    },

    getReadableDate (date) {
      return format(parseISO(date), 'dd.MM.yyyy - HH:mm')
    },

    showConfigurator (entry) {
      const { model, frame } = entry.orderConfiguration

      this.$router.push({
        name: 'BikeConfigurator',
        query: { model, frame, order: entry.orderId },
      })
    },
  },
}
</script>

<style lang="scss">
  .account-orders-wrap {
    .v-data-table {
      .v-data-table__wrapper {
        tbody {
          tr.v-data-table__expanded {
            &.v-data-table__expanded__content {
              box-shadow: none;
              background-color: #f2f2f2;
            }
          }
        }
      }
    }
  }
</style>
