<template>
  <div class="component-image--wrap">
    <v-img
      :key="`${isModel ? 'model' : 'component'}-image_${id}`"
      :src="imageUrl"
      :alt="alt"
      :height="height"
      :width="width"
      :contain="contain"
    >
      <template v-slot:placeholder>
        <v-sheet color="grey lighten-5" class="d-flex align-center justify-center fill-height py-4">
          <v-icon>
            image
          </v-icon>
        </v-sheet>
      </template>
    </v-img>
  </div>
</template>

<script>
import { baseUrl } from '@/api/Api'

export default {
  name: 'component-image',

  props: {
    alt: {
      type: String,
      default: '',
    },

    contain: {
      type: Boolean,
      default: false,
    },

    id: {
      type: Number,
      default: null,
    },

    isModel: {
      type: Boolean,
      default: false,
    },

    isSeries: {
      type: Boolean,
      default: false,
    },

    hasImage: {
      type: Boolean,
      default: false,
    },

    height: {
      type: [Number, String],
      default: null,
    },

    width: {
      type: [Number, String],
      default: null,
    },

    // adds a parameter to the image-url to ensure a non-cached variant gets loaded
    forceUpdate: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      baseUrl,
    }
  },

  computed: {
    imageUrl () {
      let type = 'component'

      if (this.isModel) {
        type = 'modelSeries'
      }

      if (this.isSeries) {
        type = 'standard_bike_series'
      }

      return this.id !== null && this.hasImage
        ? `${baseUrl}/${type}/${this.id}/image${this.forceUpdate ? `?${Date.now()}` : ''}`
        : ''
    },
  },
}
</script>

<style lang="scss">
  .component-image--wrap {
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
</style>
