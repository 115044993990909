<template>
  <div class="user-list-wrap">
    <div class="action-header">
      <h2>Nutzer</h2>

      <v-btn depressed @click="showUserModal = true">
        <v-icon left>
          create
        </v-icon>
        Nutzer hinzufügen
      </v-btn>
    </div>

    <user-modal
      :is-visible="showUserModal"
      :permissions="permissions"
      :with-permissions="userCanEditCompanies"
      @submit="addUser"
      @cancel="showUserModal = false"
      @change="visibility => showUserModal = visibility"
    />

    <base-dialog
      headline="Nutzer wirklich löschen?"
      :is-visible="userToDelete !== null"
      @cancel="userToDelete = null"
      @ok="deleteUser"
    />

    <v-list v-if="usersLoaded && users && users.length">
      <template v-for="(user, i) in users">
        <v-list-item :key="`item_${i}`">
          <v-list-item-icon>
            <v-icon>face</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
            <v-list-item-subtitle>
              <a :href="`mailto:${user.email}`">{{ user.email }}</a>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="userCanEditCompanies">
            <div class="list-actions">
              <permission-chips
                :permissions="permissions"
                :active-permissions="user.permissions"
                @click="permission => onPermissionClick(permission, user)"
              />
              <v-btn icon class="ml-5" @click="userToDelete = user.userId">
                <v-icon>delete</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="i < users.length - 1" :key="i" inset />
      </template>
    </v-list>

    <v-alert v-else border="left" icon="info" color="primary" text class="d-inline-block">
      Dieser Firma wurden noch keine Nutzer zugeordnet.
    </v-alert>
  </div>
</template>

<script>
import { baseUrl, baseHeader } from '@/api/Api'

import BaseDialog from '@/components/BaseDialog'
import CompanyApi from '@/api/Company'
import PermissionChips from '@/components/PermissionChips'
import UserApi from '@/api/User'
import UserModal from '@/components/UserModal'

export default {
  name: 'user-list',

  components: {
    BaseDialog,
    PermissionChips,
    UserModal,
  },

  props: {
    companyId: {
      type: Number,
      default: null,
    }
  },

  data () {
    return {
      users: [],
      usersLoaded: false,
      userToDelete: null,
      showUserModal: false,
      confirmDialogVisible: false,
      permissions: [],
    }
  },

  computed: {
    userCanEditCompanies () {
      return this.$store.getters.userCanEditCompanies
    },
  },

  mounted () {
    this.getUsers()
    this.getPermissions()
  },

  methods: {
    /**
     * Loads users related to the company (given as a prop)
     *
     * @returns {void}
     */
    async getUsers () {
      if (this.companyId === null) {
        return
      }

      const res = await CompanyApi.getUsers(this.companyId)

      if (res.ok) {
        this.users = await res.json()
      }

      this.usersLoaded = true
    },

    /**
     * Fetches available permissions for users.
     *
     * @returns {void}
     */
    async getPermissions () {
      const res = await fetch(`${baseUrl}/permissions`, {
        headers: baseHeader,
      })

      res.ok && (this.permissions = await res.json())
    },

    async addUser (user) {
      if (this.companyId === null || !user) {
        return
      }

      const res = await UserApi.invite({ companyId: this.companyId, user })
      this.showUserModal = false

      if (res.status === 400) {
        return this.$store.commit('setSnackbar', { text: 'Die E-Mail-Adresse ist bereits vergeben', color: 'error' })
      }

      if (!res.ok) {
        const { error } = await res.json()
        return this.onError(error)
      }

      this.$store.commit('setSnackbar', { text: 'Nutzer eingeladen', color: 'success' })
    },

    /**
     * Deletes the currently selected (by pressing the 'delete'-button before
     * confirming the action) user.
     *
     * @returns {void}
     */
    async deleteUser () {
      const res = await UserApi.delete({ companyId: this.companyId, userId: this.userToDelete })

      if (!res.ok) {
        return this.onError()
      }

      this.getUsers()
      this.userToDelete = null
      this.$store.commit('setSnackbar', { text: 'Nutzer gelöscht', color: 'success' })
    },

    onError (error = 'Ein Fehler ist aufgetreten') {
      this.$store.commit('setSnackbar', { text: error, color: 'error' })
    },

    /**
     * Toggles the given permission of the given user.
     *
     * @returns {void}
     */
    async onPermissionClick ({ permission }, user) {
      const currentPermissions = JSON.parse(JSON.stringify(user.permissions))

      user.permissions = user.permissions.includes(permission)
        ? user.permissions.filter(p => p !== permission)
        : [...user.permissions, permission]

      const res = await UserApi.update(user)

      this.$store.commit('setSnackbar', {
        text: res.ok ? 'Rolle aktualisiert' : 'Aktion fehlgeschlagen',
        color: res.ok ? 'success' : 'error',
      })

      if (!res.ok) {
        user.permissions = currentPermissions
      }
    },
  },
}
</script>

<style lang="scss">
  .user-list-wrap {
    .list-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .v-input {
        max-width: 15rem;
      }
    }
  }
</style>
