<template>
  <div v-if="frameComponent" class="tab-view--wrap">
    <v-tabs-items v-model="activeTab" continuous>
      <v-tab-item eager>
        <component-img
          :id="frameComponent.componentId"
          :has-image="frameComponent.hasImage"
          :alt="frameComponent.label"
          :height="420"
          :component="frameComponent"
          contain
        />
      </v-tab-item>

      <v-tab-item eager>
        <bike-preview
          fit-into=".tab-view--wrap .v-tabs-items"
          :color-choice-active="colorChoiceIsActive"
          :frame="frameComponent.frame"
          :sidebar-visible="colorChoiceIsActive && toColorize !== null"
          :to-colorize="toColorize"
          @loaded="$emit('loaded:preview')"
          @show:picker="componentId => $emit('start:color-choice', componentId)"
        />

        <component-color-sidebar
          :to-colorize="toColorize"
          :is-visible="colorChoiceIsActive && toColorize !== null"
          @choose:color="color => $emit('choose:color', color)"
          @click:close="$emit('stop:color-choice')"
        />
      </v-tab-item>

      <v-tab-item eager>
        <v-sheet color="grey lighten-5" class="pa-4">
          <v-img
            :src="require('@/assets/images/dekor.jpeg')"
            alt="Dekor-Elemente"
            contain
            eager
          />
        </v-sheet>
        <dekor-sidebar
          :frame-component="frameComponent"
          :is-visible="dekorChoiceIsActive"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import BikePreview from './ColorChoice/BikePreview'
import ComponentColorSidebar from './ColorChoice/ComponentColorSidebar'
import ComponentImg from '@/components/ComponentImg'
import DekorSidebar from './DekorSidebar/DekorSidebar'

export default {
  name: 'tab-view',

  components: {
    BikePreview,
    ComponentColorSidebar,
    ComponentImg,
    DekorSidebar,
  },

  props: {
    activeTab: {
      type: Number,
      default: null,
    },

    frameComponent: {
      type: Object,
      default: null,
    },

    toColorize: {
      type: Object,
      default: null,
    },
  },

  computed: {
    colorChoiceIsActive () {
      return this.activeTab === 1
    },

    dekorChoiceIsActive () {
      return this.activeTab === 2
    },
  }
}
</script>
