<template>
  <div class="option-category-overview-wrap">
    <div class="action-header">
      <h1>Optionskategorien</h1>

      <v-btn :to="{ name: 'OptionCategory' }" depressed>
        <v-icon color="primary" left>
          create
        </v-icon>
        Neue Optionskategorie
      </v-btn>
    </div>

    <div class="option-category-table-wrap">
      <data-table
        :is-loading="isLoading"
        :items="optionCategories"
        :columns="columnOptions"
        search-placeholder="Bezeichnung/Kategorie"
        state-key="optionCategories"
        @dblclick:row="(e, { item }) => $router.push({
          name: 'OptionCategory',
          params: { id: item.optionCategoryId }
        })"
      >
        <template v-slot:[`item.showInSearch`]="{ item }">
          <v-switch :input-value="item.showInSearch" inset disabled />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon class="mr-1" @click="optionCategoryToRemove = item.optionCategoryId">
            <v-icon small>
              delete
            </v-icon>
          </v-btn>
          <v-btn icon :to="{ name: 'OptionCategory', params: { id: item.optionCategoryId } }">
            <v-icon color="primary" small>
              create
            </v-icon>
          </v-btn>
        </template>
      </data-table>
    </div>

    <base-dialog
      headline="Optionskategorie wirklich löschen?"
      :is-visible="optionCategoryToRemove !== null"
      @cancel="optionCategoryToRemove = null"
      @ok="deleteSelectedOptionCategory"
    />
  </div>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'
import DataTable from '@/components/DataTable'
import OptionCategoryApi from '@/api/OptionCategory'

export default {
  name: 'option-category-overview',

  components: {
    BaseDialog,
    DataTable,
  },

  data () {
    return {
      optionCategories: [],
      optionCategoryToRemove: null,
      columnOptions: [
        { value: 'label', text: 'Bezeichnung' },
        { value: 'componentType.label', text: 'Kategorie', isFilter: true, filterKey: 'componentType.label' },
        { value: 'showInSearch', text: 'In Suche anzeigen' },
        { value: 'actions', text: 'Aktionen', sortable: false, align: 'right' },
      ],
      isLoading: false,
    }
  },

  mounted () {
    this.getOptionCategories()
    this.$store.commit('setBreadcrumbItems', [{ text: 'Optionskategorien', disabled: true }])
  },

  methods: {
    /**
     * getOptionCategories
     *
     * @returns {void}
     */
    async getOptionCategories () {
      this.isLoading = true
      const res = await OptionCategoryApi.getAll()
      res.ok && (this.optionCategories = await res.json())
      this.isLoading = false
    },

    /**
     * Deletes the currently selected (by pressing the 'delete'-button before
     * confirming the action) optioncategory.
     *
     * @returns {void}
     */
    async deleteSelectedOptionCategory () {
      if (this.optionCategoryToRemove === null) {
        return
      }

      const res = await OptionCategoryApi.delete(this.optionCategoryToRemove)
      this.optionCategoryToRemove = null

      if (res.ok) {
        this.getOptionCategories()
        this.$store.commit('setSnackbar', { text: 'Optionskategorie gelöscht', color: 'success' })
      } else {
        this.$store.commit('setSnackbar', { text: 'Löschen fehlgeschlagen', color: 'error' })
      }
    },
  },
}
</script>
