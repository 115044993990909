<template>
  <div class="account-overview-wrap">
    <user-form
      class="mb-10"
      :user="user"
      @submit:user="updateUser"
      @submit:password="changePassword"
    >
      <template v-if="company" #below-userdata>
        <div class="mb-10">
          <h2>
            Firma: {{ company.label }}
          </h2>

          <strong>Kundennummer:</strong> {{ company.customerNumber }} <br>
          <strong>Zuständiger Außendienstler:</strong> {{ company.contactPerson }}

          <div class="mt-1">
            <router-link
              :to="{
                name: userCanEditCompanies ? 'CompanyDetails' : 'UserCompany',
                params: { id: company.companyId },
              }"
              class="d-inline-flex"
            >
              Zur Detailansicht

              <v-icon color="primary" size="16">
                chevron_right
              </v-icon>
            </router-link>
          </div>
        </div>
      </template>
    </user-form>
  </div>
</template>

<script>
import CompanyApi from '@/api/Company.js'
import UserApi from '@/api/User'
import UserForm from '@/components/UserForm'

export default {
  name: 'account-overview',

  components: {
    UserForm
  },

  data () {
    return {
      company: null
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },

    userCanEditCompanies () {
      return this.$store.getters.userCanEditCompanies
    },
  },

  mounted () {
    this.getUserCompany()
    this.$store.commit('setBreadcrumbItems', [{ text: 'Mein Konto', disabled: true }])
  },

  methods: {
    /**
     * Loads the data of the company related to the current user.
     *
     * @returns {Promise}
     */
    async getUserCompany () {
      if (this.user && this.user.companyId) {
        const res = await CompanyApi.get(this.user.companyId)
        res.ok && (this.company = await res.json())
      }
    },

    async updateUser ({ email = '', firstName = '', lastName = '' }) {
      const res = await UserApi.update({ ...this.user, email, firstName, lastName })

      if (!res.ok) {
        return this.$store.commit('setSnackbar', { text: 'Aktualisierung gescheitert', color: 'error' })
      }

      this.$store.dispatch('getUser')
      this.$store.commit('setSnackbar', { text: 'Daten aktualisiert', color: 'success' })
    },

    async changePassword ({ oldPassword = '', newPassword = '' }) {
      const res = await UserApi.setPassword({ oldPassword, newPassword })

      if (res.ok) {
        this.$store.commit('setSnackbar', { text: 'Passwort geändert', color: 'success' })
        return
      }

      const { error } = await res.json()

      this.$store.commit('setSnackbar', {
        text: error === 'wrong password' ? 'Falsches Passwort' : 'Änderung fehlgeschlagen',
        color: 'error',
      })
    },
  },
}
</script>
