<template>
  <base-dialog
    headline="Fehlende Komponenten"
    action-text="Verstanden"
    action-icon="done"
    cancel-text=""
    info-text=""
    :is-visible="missingComponents.length > 0 && !confirmed"
    @ok="confirmed = true"
  >
    <div class="mb-4">
      <p>
        Ihre geladene Konfiguration enthält Komponenten, welche für dieses Modell nicht mehr verfügbar sind.
        Für diese wurde der jeweilige Standard ausgewählt.
      </p>

      Betroffen sind folgende Komponenten:
    </div>
    <v-list>
      <v-list-item v-for="component in missingComponents" :key="`missing_${component.componentId}`">
        <v-list-item-content>
          <v-list-item-title>{{ component.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </base-dialog>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'

export default {
  name: 'missing-component-dialog',

  components: {
    BaseDialog,
  },

  props: {
    missingComponents: {
      type: Array,
      default: () => ([])
    },
  },

  data () {
    return {
      confirmed: false,
    }
  },
}
</script>
