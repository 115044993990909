import Vue from 'vue'
import Vuex from 'vuex'

import BikeStore from '@/store/bike'
import CartStore from '@/store/cart'
import BreadcrumbStore from '@/store/breadcrumb'
import FilterStore from '@/store/filters'
import OptionsStore from '@/store/options'
import PricesStore from '@/store/prices'
import SnackbarStore from '@/store/snackbar'
import UserStore from '@/store/user'

import { baseUrl, baseHeader } from '@/api/Api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    frameCategory: 'Rahmen',
    bikeCategories: ['Bike', 'Pedelec', 'Rohloff', 'Gates'],
  },

  actions: {
    async getPrices ({ commit }) {
      const res = await fetch(`${baseUrl}/prices`, { headers: baseHeader })
      res.ok && commit('setPrices', await res.json())
    }
  },

  modules: {
    bike: BikeStore,
    cart: CartStore,
    breadcrumb: BreadcrumbStore,
    filters: FilterStore,
    options: OptionsStore,
    prices: PricesStore,
    snackbar: SnackbarStore,
    user: UserStore,
  },
})
