import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl: `${baseUrl}/optionCategory`,
  baseHeader,

  get (optionCategoryId) {
    return fetch(`${this.baseUrl}/${optionCategoryId}`, {
      headers: this.baseHeader,
    })
  },

  /**
   * getAll
   *
   * @returns {Promise[]}
   */
  getAll () {
    return fetch(this.baseUrl, { headers: this.baseHeader })
  },

  getCategoryTypes () {
    return fetch(`${this.baseUrl}/categoryTypes`, {
      headers: this.baseHeader,
    })
  },

  /**
   * create
   *
   * @returns {Promise[]}
   */
  create (optionCategory) {
    return fetch(this.baseUrl, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify(optionCategory),
    })
  },

  /**
   * createOption
   *
   * @returns {Promise[]}
   */
  createOption (optionCategoryId, option) {
    return fetch(`${this.baseUrl}/${optionCategoryId}/option`, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify(option),
    })
  },

  /**
   * deleteOption
   *
   * @returns {Promise[]}
   */
  deleteOption (optionCategoryId, optionId) {
    return fetch(`${this.baseUrl}/${optionCategoryId}/option/${optionId}`, {
      method: 'DELETE',
      headers: this.baseHeader,
    })
  },

  getOptions (optionCategoryId) {
    return fetch(`${this.baseUrl}/${optionCategoryId}/option`, { headers: this.baseHeader })
  },

  /**
   * delete
   *
   * @param {number} optionCategoryId ID of the optionCategory to remove
   * @returns {Promise[]}
   */
  delete (optionCategoryId) {
    return fetch(`${this.baseUrl}/${optionCategoryId}`, {
      method: 'DELETE',
      headers: this.baseHeader,
    })
  },

  /**
   * update
   *
   * @param {object} optionCategory optionCategory to update
   * @returns {Promise[]}
   */
  update (optionCategory) {
    if (!optionCategory || !Object.keys(optionCategory).length) {
      return false
    }

    return fetch(`${this.baseUrl}/${optionCategory.optionCategoryId}`, {
      method: 'PUT',
      headers: this.baseHeader,
      body: JSON.stringify(optionCategory),
    })
  },
}
