<template>
  <div class="bike-slots-wrap">
    <v-expansion-panels v-if="slots.length" v-model="activePanel" class="mb-10">
      <v-expansion-panel v-for="(slot, index) in slots" :key="index">
        <v-expansion-panel-header>
          <div class="d-flex align-center">
            <config-error-indicator
              :slot-id="slot.slotId"
              :errors="errors"
              class="mr-4"
            />

            <div>
              <strong>{{ slot.componentType.label }}</strong>
              <div v-if="slot.pickedComponent && index !== activePanel" class="mt-1">
                {{ slot.pickedComponent.label }}
              </div>
            </div>
          </div>
          <div
            v-if="slot.pickedComponent && slot.pickedComponent.paintable && slot.pickedComponent.pickedColor"
            class="ml-auto d-flex justify-end mr-2"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-chip
                    v-if="slot.pickedComponent && slot.pickedComponent.paintable && slot.pickedComponent.pickedColor"
                    x-small
                    :color="slot.pickedComponent.pickedColor.colorValue"
                    class="px-2"
                    @click.stop="$emit('request:color-change', slot.pickedComponent)"
                  />
                </div>
              </template>
              <span>Lackierung: {{ slot.pickedComponent.pickedColor.label }}</span>
            </v-tooltip>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <frame-options
            v-if="slot.componentType.componentType === frameCategory"
            :frame-component="frameComponent"
            :chosen-options="frameConfig"
            @picked:option="option => $emit('picked:option', option)"
          />

          <v-list v-else dense flat>
            <v-list-item
              v-for="(component, i) in getSortedSlotComponents(slot)"
              :key="i"
              :class="{ chosen: componentIsChosen(component, slot) }"
              class="px-2"
              :disabled="!component.available"
              @mouseenter="showComponentImageAtMouse(component)"
              @mouseleave="removeImageAtMouse"
              @click="chooseComponent(component, slot)"
            >
              <v-list-item-avatar tile :width="60" @click="showPreviewDialog(component)">
                <component-img
                  :id="component.componentId"
                  :has-image="component.hasImage"
                  :alt="component.label"
                  :height="40"
                  :width="60"
                  contain
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <span class="d-inline-flex align-center">
                  <config-error-indicator
                    :slot-id="slot.slotId"
                    :component-id="component.componentId"
                    :errors="errors"
                    class="mr-2"
                  />
                  {{ component.label }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-if="component.paintable" small class="d-inline ml-2" v-bind="attrs" v-on="on">
                        palette
                      </v-icon>
                    </template>
                    <span>lackierbar</span>
                  </v-tooltip>
                </span>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip
                  small
                  label
                  :color="componentIsChosen(component, slot) ? 'grey darken-3' : 'grey lighten-3'"
                  :dark="componentIsChosen(component, slot)"
                >
                  <span v-if="slot.defaultComponentId === null">
                    {{ component[priceKeyForUser] | centsToEuro }}
                  </span>
                  <span v-else>
                    {{ (i === 0 ? 0 : component[priceKeyForUser] - getDefaultComponent(slot)[priceKeyForUser]) | centsToEuro }}
                  </span>
                </v-chip>
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              v-if="slot.componentType.isNullable"
              :class="{ chosen: slot.pickedComponent === null }"
              class="px-2"
              @click="$emit('unselect:slot-component', slot)"
            >
              <v-list-item-avatar color="transparent" tile :width="60" />
              <v-list-item-content>
                Ohne {{ slot.componentType.label }}
              </v-list-item-content>
              <v-list-item-action>
                <v-chip
                  small
                  label
                  :color="slot.pickedComponent === null ? 'grey darken-3' : 'grey lighten-3'"
                  :dark="slot.pickedComponent === null"
                >
                  <span v-if="slot.defaultComponentId === null">
                    {{ 0 | centsToEuro }}
                  </span>
                  <span v-else>
                    - {{ getDefaultComponent(slot)[priceKeyForUser] | centsToEuro }}
                  </span>
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div
      ref="previewClone"
      class="preview-clone elevation-5"
      :class="{ visible: mousePreviewComponent }"
    >
      <component-img
        v-if="mousePreviewComponent"
        :id="mousePreviewComponent.componentId"
        :has-image="mousePreviewComponent.hasImage"
        :alt="mousePreviewComponent.label"
        :width="350"
      />
    </div>

    <component-dialog
      :is-visible="previewDialogVisible"
      :preview-to-show="previewToShow"
      @close="previewDialogVisible = false"
    />
  </div>
</template>

<script>
import ComponentDialog from './ComponentDialog'
import ComponentImg from '@/components/ComponentImg'
import FrameOptions from './FrameOptions'
import ConfigErrorIndicator from '@/components/ConfigErrorIndicator'

export default {
  name: 'bike-slots',

  components: {
    ComponentImg,
    ComponentDialog,
    FrameOptions,
    ConfigErrorIndicator,
  },

  props: {
    errors: {
      type: Array,
      default: () => ([])
    },

    frameComponent: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      activePanel: null,
      mousePreviewComponent: null,
      previewToShow: null,
      previewDialogVisible: false,
    }
  },

  computed: {
    frameCategory () {
      return this.$store.state.frameCategory
    },

    frameConfig () {
      return this.$store.state.bike.frameConfig
    },

    slots () {
      return this.$store.state.bike.slots
    },

    user () {
      return this.$store.state.user.user
    },

    priceKeyForUser () {
      return this.user.showCustomerPrice ? 'customerPrice' : 'retailerPrice'
    },
  },

  methods: {
    /**
     * Requests the parent-component to pick the given component for the given
     * slot.
     *
     * @param {object} component
     * @param {object} slot
     * @returns {void}
     */
    chooseComponent (component, slot) {
      if (!slot.pickedComponent || slot.pickedComponent.componentId !== component.componentId) {
        this.$emit('picked:component', { slot, component })
      }
    },

    /**
     * Returns the sorted components of the given slot.
     *
     * @param {object} slot
     * @return {array}
     */
    getSortedSlotComponents (slot) {
      return [...slot.slotComponents]
        .sort((a, b) => a.position - b.position)
        .map(({ component }) => component)
    },

    /**
     * Checks which component is the default of the given slot.
     *
     * @returns {object}
     */
    getDefaultComponent (slot) {
      const defaultSlotComponent = slot.slotComponents.find(({ component }) =>
        component.componentId === slot.defaultComponentId
      )

      return defaultSlotComponent ? defaultSlotComponent.component : null
    },

    /**
     * Uses the given image as mouseover-preview, adds a listener so the image
     * follows the mouse.
     *
     * @param {object} component
     */
    showComponentImageAtMouse (component) {
      component.hasImage && (this.mousePreviewComponent = component)
      window.addEventListener('mousemove', this.followMouse)
    },

    /**
     * Removes the mouseover-preview and related event-listeners.
     *
     * @returns {void}
     */
    removeImageAtMouse () {
      this.mousePreviewComponent = null
      window.removeEventListener('mousemove', this.followMouse)
    },

    /**
     * Set the position of the mouseover-preview based on the position of the
     * given mouseevent.
     *
     * @param {Event} e
     * @returns {void}
     */
    followMouse (e) {
      const el = this.$refs.previewClone
      el.style.left = `${e.pageX - window.scrollX}px`
      el.style.top = `${e.pageY - window.scrollY}px`
    },

    /**
     * Shows a preview-dialog for the given component.
     *
     * @param {object} component
     * @returns {void}
     */
    showPreviewDialog (component) {
      this.previewToShow = component
      this.previewDialogVisible = true
    },

    /**
     * Checks if the given component is currently chosen in the given slot.
     *
     * @param {object} component
     * @param {object} slot
     * @returns {boolean}
     */
    componentIsChosen (component, slot) {
      return slot.pickedComponent && slot.pickedComponent.componentId === component.componentId
    },
  },
}
</script>

<style lang="scss">
  .bike-slots-wrap {
    .preview-clone {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      display: none;
      pointer-events: none;
      margin-top: 2rem;
      background-color: #fff;

      &.visible {
        display: block;
      }
    }

    .v-list-item {
      font-size: 14px;
      border: 1px solid transparent;
      pointer-events: auto; // allow tooltips even though the item is disabled

      &.chosen {
        background-color: #efefef;
        border: 1px solid #000;
        border-radius: 4px;
      }
    }
  }
</style>
