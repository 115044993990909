<template>
  <div class="options--wrap">
    <v-form ref="form" v-model="formIsValid" class="mb-10" @submit.prevent="createOption">
      <h2 class="mb-8">
        Optionen
      </h2>
      <v-row>
        <v-col :lg="4">
          <v-text-field
            v-model="newOption.label"
            prepend-inner-icon="create"
            hide-details
            label="Bezeichnung"
            :rules="requiredRules"
            solo
          />
        </v-col>
        <v-col :lg="8" class="d-flex align-center">
          <v-btn depressed type="submit" :disabled="!formIsValid">
            <v-icon color="primary" left>
              add
            </v-icon>
            Neue Option anlegen
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <data-table
      :items="options"
      :columns="columnOptions"
      :show-edit-button="false"
      search-placeholder="Bezeichnung"
      class="mb-15"
      @delete="option => optionToRemove = option.optionId"
    />

    <base-dialog
      headline="Option wirklich löschen?"
      :is-visible="optionToRemove !== null"
      @cancel="optionToRemove = null"
      @ok="deleteSelectedOption"
    />
  </div>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'
import DataTable from '@/components/DataTable'
import OptionCategoryApi from '@/api/OptionCategory'

export default {
  name: 'options',

  components: {
    BaseDialog,
    DataTable,
  },

  props: {
    optionCategoryId: {
      type: Number,
      required: true,
    }
  },

  data () {
    return {
      options: [],
      optionToRemove: null,
      columnOptions: [
        { value: 'label', text: 'Bezeichnung' },
        { value: 'actions', text: 'Aktionen', sortable: false, align: 'right' },
      ],
      newOption: {
        label: '',
        optionId: null,
        optionCategoryId: this.optionCategoryId,
      },
      requiredRules: [v => !!v],
      formIsValid: false,
    }
  },

  mounted () {
    this.loadOptions()
  },

  methods: {
    /**
     * createOption
     *
     * @returns {void}
     */
    async createOption () {
      const res = await OptionCategoryApi.createOption(this.optionCategoryId, this.newOption)

      res.ok && this.loadOptions()

      this.$store.commit('setSnackbar', {
        text: res.ok ? 'Option erstellt' : 'Erstellen fehlgeschlagen',
        color: res.ok ? 'success' : 'error',
      })
    },

    /**
     * loadOptions
     *
     * @returns {void}
     */
    async loadOptions () {
      const res = await OptionCategoryApi.getOptions(this.optionCategoryId)

      if (res.ok) {
        const options = await res.json()
        this.options = options.sort((a, b) => a.label.localeCompare(b.label))
      }
    },

    /**
     * Deletes the currently selected (by pressing the 'delete'-button before
     * confirming the action) option.
     *
     * @returns {void}
     */
    async deleteSelectedOption () {
      if (this.optionToRemove === null) {
        return
      }

      const res = await OptionCategoryApi.deleteOption(this.optionCategoryId, this.optionToRemove)
      this.optionToRemove = null

      if (res.ok) {
        this.loadOptions()
        this.$store.commit('setSnackbar', { text: 'Option gelöscht', color: 'success' })
      } else {
        this.$store.commit('setSnackbar', { text: 'Löschen fehlgeschlagen', color: 'error' })
      }
    },
  },
}
</script>

<style>

</style>
