var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-lg-none",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("menu")])],1)]}}])},[_c('v-list',[_vm._l((_vm.navEntries),function(ref,i){
var label = ref.label;
var route = ref.route;
var icon = ref.icon;
var restricted = ref.restricted;
return _c('v-list-item',{key:i,attrs:{"to":{ name: route },"disabled":restricted && _vm.user === null}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(label))])],1)}),(_vm.user)?[_vm._l((_vm.userNavEntries),function(ref,i){
var label = ref.label;
var route = ref.route;
var icon = ref.icon;
return _c('v-list-item',{key:("u_" + i),attrs:{"to":{ name: route }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(label))])],1)}),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("logout")])],1),_c('v-list-item-title',[_vm._v("Logout")])],1)]:_c('v-list-item',{attrs:{"to":{ name: 'Login' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("login")])],1),_c('v-list-item-title',[_vm._v("Login")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }