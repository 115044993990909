<template>
  <v-dialog persistent :value="isVisible" width="500">
    <v-card>
      <v-card-title>
        {{ headline }}
      </v-card-title>

      <v-card-text>
        {{ infoText }}
        <slot />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn v-if="cancelText" depressed @click="$emit('cancel')">
          <v-icon left>
            cancel
          </v-icon>
          {{ cancelText }}
        </v-btn>
        <v-btn depressed @click="$emit('ok')">
          <v-icon color="primary" left>
            {{ actionIcon }}
          </v-icon>
          {{ actionText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'base-dialog',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    headline: {
      type: String,
      default: 'Wirklich löschen?'
    },

    infoText: {
      type: String,
      default: 'Achtung! Diese Aktion kann nicht rückgängig gemacht werden.'
    },

    cancelText: {
      type: String,
      default: 'Abbrechen'
    },

    actionText: {
      type: String,
      default: 'Löschen'
    },

    actionIcon: {
      type: String,
      default: 'delete'
    },
  },
}
</script>

<style>

</style>
