<template>
  <div class="reset-button--wrap">
    <v-btn depressed @click="dialogVisible = true">
      <v-icon left>
        restart_alt
      </v-icon>
      Zurücksetzen
    </v-btn>

    <base-dialog
      headline="Konfiguration zurücksetzen"
      info-text="Sämtliche Änderungen gehen dabei verloren."
      action-text="Zurücksetzen"
      :is-visible="dialogVisible"
      @cancel="dialogVisible = false"
      @ok="onClickReset"
    />
  </div>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'

export default {
  name: 'reset-button',

  components: {
    BaseDialog,
  },

  data () {
    return {
      dialogVisible: false,
    }
  },

  methods: {
    onClickReset () {
      this.dialogVisible = false
      this.$emit('request:reset')
    }
  }
}
</script>
