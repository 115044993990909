<template>
  <div class="agb-wrap">
    <h1>AGB</h1>

    <section>
      <p>
        02704-01 (Stand: 22.09.2017)
      </p>
      <h2>I. Gültigkeit der Bedingungen</h2>
      <ol>
        <li>Wir schließen ausschließlich zu unseren nachfolgenden Lieferbedingungen ab. Sie gelten auch für alle künftigen Geschäftsbeziehungen, selbst wenn sie nicht ausdrücklich nochmals vereinbart werden. Abweichungen von diesen Bedingungen sind nur wirksam, wenn wir sie schriftlich bestätigen. Geschäftsbedingungen des Bestellers, die wir nicht schriftlich anerkennen, sind für uns unverbindlich, auch wenn wir ihnen nicht ausdrücklich widersprechen.</li>
        <li>Änderungen dieser Bedingungen werden dem Besteller schriftlich bekannt gegeben. Sie gelten als genehmigt, wenn der Besteller nicht schriftlich Widerspruch erhebt. Auf diese Folge werden wir ihn bei der Bekanntgabe besonders hinweisen. Der Besteller muss den Widerspruch innerhalb eines Monats nach Bekanntgabe der Änderungen an uns absenden.</li>
        <li>Diese Lieferbedingungen gelten nur gegenüber Unternehmern im Sinne von § 14 BGB</li>
      </ol>
    </section>

    <section>
      <h2>II. Angebote, Umfang der Lieferung</h2>
      <ol>
        <li>Unsere Angebote sind freibleibend. Mündliche und fernmündliche Vereinbarungen bedürfen zu ihrer Gültigkeit unserer schriftlichen Bestätigung.</li>
        <li>Die zu unseren Angeboten gehörenden Unterlagen wie Prospekte, Abbildungen und Zeichnungen sowie Gewichts- und Maßangaben sind nur annähernd maßgebend, soweit wir sie nicht ausdrücklich als verbindlich bezeichnen. Entscheidend für die Qualität des Liefergegenstandes ist allein unsere Auftragsbestätigung. An Kostenvoranschlägen, Zeichnungen und anderen Unterlagen behalten wir uns Eigentums- und Urheberrechte vor. Diese Unterlagen dürfen ohne unsere Zustimmung Dritten nicht zugänglich gemacht werden.</li>
        <li>Die Lieferteile entsprechen den in der Bundesrepublik Deutschland geltenden Standards und Bestimmungen. Für eine etwa erforderliche Prüfung und Abnahme der Lieferteile nach ausländischen technischen Standards und Bestimmungen hat der Besteller zu sorgen.</li>
      </ol>
    </section>

    <section>
      <h2>III. Preise und Zahlungsbedingungen</h2>
      <ol>
        <li>Maßgeblich sind die bei Lieferung geltenden Listenpreise. Sie gelten, wenn nichts anderes vereinbart ist, ab Niederlassung ohne Verpackung.</li>
        <li>Die Zahlung per Scheck erfolgt erfüllungshalber. Kommt der Besteller seinen Zahlungsverpflichtungen schuldhaft nicht nach, löst er insbesondere Schecks nicht ein oder stellt seine Zahlungen ein, so sind wir berechtigt, die gesamte Restschuld fällig zu stellen, auch wenn wir Schecks angenommen haben. Außerdem steht uns dann das Recht zu, Vorauszahlungen zu verlangen.</li>
        <li>Wird nach Vertragsabschluss erkennbar, dass unser Anspruch auf die Zahlung durch mangelnde Leistungsfähigkeit des Bestellers gefährdet wird, so können wir die uns obliegende Leistung verweigern und dem Besteller eine Frist zur Zahlung Zug-um-Zug gegen Lieferung oder zur Sicherheitsleistung bestimmen. Im Falle des erfolglosen Fristablaufs sind wir berechtigt, von dem Vertrag zurückzutreten und Schadensersatz zu verlangen. Die Fristsetzung ist entbehrlich, wenn der Besteller die Zahlung ernsthaft und endgültig verweigert oder wenn besondere Umstände vorliegen, die unter Abwägung der beiderseitigen Interessen unseren sofortigen Rücktritt rechtfertigen.</li>
      </ol>
    </section>

    <section>
      <h2>IV. Lieferzeit</h2>
      <ol>
        <li>Maßgeblich sind die in unseren Auftragsbestätigungen genannten oder anderweitig mit dem Besteller vereinbarten Fristen. Die Einhaltung dieser Fristen setzt den rechtzeitigen Eingang sämtlicher vom Besteller zu liefernden Unterlagen sowie die Einhaltung der vereinbarten Zahlungsbedingungen und sonstigen Verpflichtungen voraus. Werden diese Voraussetzungen nicht rechtzeitig erfüllt, so verlängert sich die Lieferfrist um die Dauer der Verzögerung. Teillieferungen sind in einem dem Besteller zumutbaren Umfang zulässig.</li>
        <li>Bei Kaufverträgen gilt die Lieferfrist als eingehalten, wenn die betriebsbereite Sendung innerhalb dieser Frist zum Versand gebracht oder abgeholt wird. Verzögert sich die Ablieferung aus von dem Besteller zu vertretenden Gründen, so gilt die Frist als eingehalten bei Meldung der Fertigstellung bzw. Versandbereitschaft innerhalb der vereinbarten Frist. Teillieferungen sind in einem dem Besteller zumutbaren Maß zulässig.</li>
        <li>Wenn wir an der Erfüllung unserer Verpflichtungen durch den Eintritt von unvorhersehbaren außergewöhnlichen Umständen gehindert werden, die wir trotz der nach den Umständen des Falles zumutbaren Sorgfalt nicht abwenden konnten – gleichviel ob in unserem Werk oder bei unseren Vorlieferanten eingetreten – zum Beispiel Betriebsstörungen, behördliche Eingriffe, Verzögerungen in der Anlieferung wesentlicher Roh- und Baustoffe, Energieversorgungsschwierigkeiten, so verlängert sich, wenn die Lieferung oder Leistung nicht unmöglich ist, die Lieferfrist um die Dauer der Behinderung. Wird durch die oben angegebenen Umstände die Lieferung oder Leistung unmöglich, so werden wir von der Lieferverpflichtung frei.</li>
        <li>
          Auch im Falle von Streik oder Aussperrung verlängert sich die Lieferfrist in angemessenem Umfang. Wenn die Lieferung oder Leistung unmöglich wird, werden wir von der Lieferverpflichtung frei.<br>
          Verlängert sich in den oben genannten Fällen die Lieferzeit um mehr als einen Monat, so ist der Besteller berechtigt, vom Vertrag zurückzutreten. Die Geltendmachung von Schadensersatzansprüchen ist ausgeschlossen.<br>
          Treten die vorgenannten Umstände bei dem Besteller ein, so gelten dieselben Rechtsfolgen auch für seine Annahmeverpflichtung. Auf die hier genannten Umstände können wir uns nur berufen, wenn wir den Besteller unverzüglich benachrichtigen.
        </li>
        <li>Verzögert sich der Versand oder die Zustellung auf Wunsch des Bestellers, so können wir, beginnend einen Monat nach Anzeige der Fertigstellung bzw. Versandbereitschaft, Lagergeld in Höhe von einem halben Prozent des Nettorechnungsbetrages für jeden angefangenen Monat berechnen. Das Lagergeld wird auf fünf Prozent des Nettorechnungsbetrages begrenzt, es sei denn, wir weisen höhere Kosten nach.</li>
      </ol>
    </section>

    <section>
      <h2>V. Versand und Gefahrübergang</h2>
      <ol>
        <li>Die Gefahr geht mit der Absendung auf den Besteller über. Verzögert sich der Versand aus Gründen, die im Einwirkungsbereich des Bestellers oder seiner Erfüllungsgehilfen liegen, so geht die Gefahr bereits am Tage der Meldung der Versandbereitschaft auf den Besteller über. Wird die Ware durch unsere Fahrzeuge/Mitarbeiter ausgeliefert, so geht die Gefahr mit Abschluss des Abladevorgangs auf den Besteller über.</li>
        <li>Grundsätzlich versichern wir auf Kosten des Bestellers die gesamte Sendung durch eine branchenübliche Transportversicherung einschließlich Auf- und Abladen sowie Verbringen der Waren unmittelbar nach dem Abladen an den Aufstellungsort. Weitere Versicherungen werden nur auf schriftlichen Wunsch des Bestellers und gegen Vorauszahlung abgeschlossen.</li>
      </ol>
    </section>

    <section>
      <h2>VI. Eigentumsvorbehalt</h2>
      <ol>
        <li>Die gelieferte Ware bleibt bis zur vollständigen Bezahlung des vereinbarten Preises einschließlich sämtlicher Forderungen aus der Geschäftsverbindung und zukünftiger Forderungen sowie bis zur Einlösung von Wechseln und Schecks unser Eigentum.</li>
        <li>Eine Weiterveräußerung ist dem Besteller im Rahmen eines ordnungsgemäßen Geschäftsverkehrs gestattet. Der Besteller tritt bereits jetzt seine Ansprüche aus der Weiterveräußerung der Vorbehaltsware, insbesondere den Zahlungsanspruch gegen seine Abnehmer, an uns ab. Wir nehmen diese Abtretung an. Der Besteller ist verpflichtet, seinen Schuldnern die Abtretung auf unser Verlangen hin anzuzeigen. Forderungen und Namen der Schuldner des Bestellers sind uns mitzuteilen.</li>
        <li>Der Besteller ist berechtigt, Forderungen aus der Weiterveräußerung einzuziehen. Bei Zahlungsverzug oder sofern uns Umstände bekannt werden, die nach kaufmännischem Ermessen geeignet sind, die Kreditwürdigkeit des Bestellers zu mindern, sind wir zum Widerruf des Einzugsrechtes berechtigt.</li>
        <li>Be- und Verarbeitung der Vorbehaltsware erfolgt für uns als Hersteller im Sinne von § 950 BGB. Wird die Vorbehaltsware mit anderen, uns nicht gehörenden Gegenständen verarbeitet oder vermischt, so erwerben wir das Miteigentum an der neuen Sache im Verhältnis des Nettorechnungswertes der Vorbehaltsware zum Nettorechnungswert der anderen verwendeten Waren zum Zeitpunkt der Verarbeitung oder Vermischung.</li>
        <li>Die Sicherungsübereignung von in unserem Eigentum stehender Ware ist unzulässig. Bei Zugriffen Dritter auf die Vorbehaltsware, insbesondere Pfändungen, wird der Besteller auf unser Eigentum an der Ware hinweisen und uns unverzüglich unter Übersendung einer Abschrift des Pfändungsprotokolls benachrichtigen.</li>
        <li>Wir sind berechtigt, bei vertragswidrigem Verhalten des Bestellers vom Vertrag zurückzutreten und die von uns gelieferte Ware heraus zu verlangen.</li>
        <li>Übersteigt der Wert der eingeräumten Sicherheiten unsere Forderungen um mehr als 20%, so sind wir auf Verlangen des Bestellers insoweit zur Rückübertragung oder Freigabe von Sicherheiten nach unserer Wahl verpflichtet.</li>
      </ol>
    </section>

    <section>
      <h2>VII. Warenbeschaffenheit/Produkte aus China/der GUS</h2>
      <ol>
        <li>Gelieferte Ware weist nur dann Mängel auf, wenn sie den vereinbarten oder üblicherweise vorauszusetzenden Qualitätsanforderungen nicht entspricht. Wir weisen darauf hin, dass das Qualitätsniveau von Produkten aus VR-China und den GUS-Staaten nicht dem westeuropäischen Qualitätsstandard und dem hiesigen Stand der Technik entspricht. Diese Produkte sollten nur für untergeordnete Anwendungen – z. B. in der Räder- und Rollindustrie und dem Landmaschinenbau (Langsamläufer) eingesetzt werden.</li>
        <li>Für Mängel derartiger Produkte haften wir nur, wenn selbst das mindere Qualitätsniveau nicht eingehalten ist. Der Besteller muss selbstständig prüfen, ob die eingeschränkte Produktqualität für die von ihm vorgesehenen Zwecke ausreichend ist.</li>
      </ol>
    </section>

    <section>
      <h2>VIII. Rechte des Bestellers bei Mängeln</h2>
      <ol>
        <li>Wir treten unsere Ansprüche gegen Lieferanten wesentlicher Fremderzeugnisse hiermit an den Besteller ab. Der Besteller kann uns wegen Mängeln wesentlicher Fremderzeugnisse nur haftbar machen, wenn eine vorherige Inanspruchnahme der Fremdlieferanten erfolglos war.</li>
        <li>Ist der Kauf für den Besteller ein Handelsgeschäft, so sind uns Mängel unverzüglich, spätestens jedoch innerhalb einer Woche nach Eingang der Ware schriftlich mitzuteilen. Mängel, die auch bei sorgfältiger Prüfung innerhalb dieser Frist nicht entdeckt werden können, muss der Besteller unverzüglich nach ihrer Entdeckung in der oben genannten Form rügen. Wesentliche Vertragspflichten sind solche, deren Erfüllung den Vertrag trägt und auf die der Vertragspartner vertrauen darf.</li>
        <li>Bei berechtigten Mängelrügen haben wir das Recht, binnen angemessener Frist von mindestens 14 Tagen nach unserer Wahl nachzubessern oder Ersatz zu liefern. Schlägt die Nacherfüllung fehl, so kann der Besteller den Preis mindern oder – sofern die Vertragswidrigkeit nicht nur geringfügig ist – von dem Vertrag zurücktreten. Daneben ist er gegebenenfalls berechtigt, Schadensersatz oder Aufwendungsersatz zu verlangen. Tritt der Besteller vom Vertrag zurück, so hat er uns den Liefergegenstand zurückzugeben und – ungeachtet sonstiger Ansprüche – für die Zeit der Nutzung ein angemessenes Entgelt in Höhe des üblichen Mietzinses zu zahlen.</li>
        <li>Ansprüche des Bestellers wegen der zum Zweck der Nacherfüllung erforderlichen Aufwendungen, insbesondere Transport-, Wege-, Arbeits- und Materialkosten sind ausgeschlossen, soweit die Aufwendungen sich erhöhen, weil der Liefergegenstand von dem Besteller oder einem Dritten nachträglich an einen anderen Ort als den Lieferort verbracht worden ist, es sei denn, die Verbringung entspricht dem bestimmungsgemäßen Gebrauch des Liefergegenstandes oder war bei Vertragsabschluss mit uns vereinbart worden.</li>
        <li>Ansprüche des Bestellers wegen Mängeln verjähren in 12 Monaten. Dies gilt nicht, soweit das Gesetz in §§ 438 Abs. 1 Nr. 2, 479 Abs. 1 und 634 a Abs. 1 Nr. 2 BGB längere Fristen vorschreibt, nämlich für Bauwerke und Sachen für Bauwerke, Rückgriffsansprüche und Baumängel.</li>
        <li>
          Schadensersatzansprüche wegen Sachmängeln werden wie folgt begrenzt:<br>
          Bei leicht fahrlässiger Verletzung unwesentlicher Vertragspflichten haften wir nicht. Unsere Haftung für Mangelfolgeschäden ist außer bei Vorsatz, grober Fahrlässigkeit oder Verletzung wesentlicher Vertragspflichten ausgeschlossen. Soweit wir für Mangelfolgeschäden haften, ist die Haftung auf vorhersehbare, nicht auf außergewöhnliche Umstände zurückzuführende Schäden begrenzt. Durch die vorstehende Haftungsbegrenzung werden Ansprüche des Käufers wegen uns zurechenbarer Körper- oder Gesundheitsschäden sowie bei Verlust des Lebens des Käufers oder seiner Erfüllungsgehilfen nicht beschränkt. Unberührt bleiben auch die Ansprüche des Käufers aus dem Produkthaftungsgesetz und Ansprüche bei einer von uns gegebenen Garantie sowie bei arglistigem Verschweigen eines Mangels.
        </li>
      </ol>
    </section>

    <section>
      <h2>IX. Haftungsbeschränkungen, Schadensersatz</h2>
      <ol>
        <li>Die nachfolgenden Beschränkungen gelten für unsere vertragliche und außervertragliche (deliktische) Haftung sowie die Haftung wegen Verschuldens bei Vertragsschluss. Die Beweislast für die eine Haftungsbegrenzung oder einen Haftungsausschluss begründenden Tatsachen obliegt uns.</li>
        <li>Wir haften nicht für die leicht fahrlässige Verletzung unwesentlicher Vertragspflichten. Bei der leicht fahrlässigen Verletzung wesentlicher Vertragspflichten ist der Schadensersatzanspruch auf den vertragstypischen, vorhersehbaren Schaden begrenzt. Bei grob fahrlässiger Verletzung nicht wesentlicher Vertragspflichten haften wir auf den vertragstypischen, vorhersehbaren Schaden. Im Übrigen ist unsere Haftung nicht begrenzt.</li>
        <li>Eine Haftungsbegrenzung gilt nicht, soweit wir wegen Verletzung des Lebens, des Körpers oder der Gesundheit haften.</li>
        <li>Eventuelle Ansprüche des Käufers aus dem Podukthaftungsgesetz werden durch die vorstehenden Haftungsbegrenzungen nicht berührt.</li>
      </ol>
    </section>

    <section>
      <h2>X. Warenrückgaben</h2>
      <ol>
        <li>Wir sind unter den nachfolgend dargestellten Umständen grundsätzlich bereit, Retouren zu akzeptieren. Die Regelungen gelten nicht für die Rückgabe mängelbehafteter Liefergegenstände.</li>
        <li>Rücknahmefähig sind nur vollständige, unbenutzte und unbeschädigte Liefergegenstände, welche sich in einem einwandfreien, unbeschädigten Zustand sowie in einer unbeschädigten Originalverpackung befinden. Die Rücknahme von Sonderteilen, elektronischen Bauteilen etc. ist nicht möglich.</li>
        <li>Rücknahmefähige Gegenstände werden bis zu 14 Tage nach Kauf ohne Abzug von Kosten und gegen Erstattung des Nettokaufpreises entgegengenommen. Bei einer Rückgabe binnen 15 bis 30 Tagen nach Kauf nehmen wir einen Abzug von 15 % des Nettokaufpreises vor. Erfolgt die Rückgabe zwischen 30 und 90 Tagen nach Kauf, so erstatten wir 80 % des Nettokaufpreises. Maßgeblich ist jeweils das Datum des Lieferscheins oder der Barrechnung. Bei späterer Rückgabe behalten wir uns eine Prüfung im Einzelfall und unter Vornahme eines Abzuges vom Kaufpreis in Höhe von mindestens 25 % vor. Bei Käufen auf Lieferschein wird eine Gutschrift erstellt. Ist der Besteller nicht registrierter Kunde, so kann die Rücknahme nur erfolgen, wenn er den Erwerb durch Vorlage des Kaufbelegs nachweist.</li>
        <li>Wir weisen darauf hin, dass die Erteilung von Gutschriften bzw. die Vornahme von Auszahlungen bei Warenrückgaben ein freiwilliges Entgegenkommen darstellt und wir uns im Einzelfall eine abweichende Handhabung vorbehalten müssen.</li>
      </ol>
    </section>

    <section>
      <h2>XI. Erfüllungsort, Gerichtsstand und anwendbares Recht</h2>
      <ol>
        <li>Erfüllungsort für alle Verpflichtungen aus dem Vertragsverhältnis ist der Ort unserer jeweiligen, die Lieferung ausführenden Niederlassung.</li>
        <li>Gerichtsstand für alle sich aus dem Vertragsverhältnis ergebenden Streitigkeiten ist, wenn es sich bei dem Besteller um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen handelt, Bielefeld. Es steht uns jedoch frei, das für den Sitz des Bestellers zuständige Gericht anzurufen.</li>
        <li>Es gilt ausschließlich deutsches Recht. Die Anwendung des Übereinkommens der Vereinten Nationen über Verträge über den Internationalen Warenkauf vom 11. April 1980 (UN-Abkommen) wird ausgeschlossen.</li>
      </ol>
    </section>

    <section>
      <h2>XII. Datenschutz</h2>
      Wir sind berechtigt, personenbezogene Daten des Bestellers zu speichern, zu übermitteln, zu verändern und zu löschen. Der Besteller erhält hiermit Kenntnis gemäß § 33 BDSG.
    </section>
  </div>
</template>

<script>
export default {
  name: 'agb',

  mounted () {
    this.$store.commit('setBreadcrumbItems', [{ text: 'AGB', disabled: true }])
  },
}
</script>

<style lang="scss">
  .agb-wrap {
    section {
      max-width: 950px;
      margin: 2.5rem 0;
    }
  }
</style>
