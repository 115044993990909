import Agb from '@/views/Info/Agb'
import Catalogue from '@/views/Catalogue'
import Downloads from '@/views/Downloads'
import Gallery from '@/views/Gallery'
import Home from '@/views/Home'
import Imprint from '@/views/Info/Imprint'
import Login from '@/views/Login/Login'
import LoginForm from '@/views/Login/LoginForm'
import Privacy from '@/views/Info/Privacy'
import Registration from '@/views/Registration'
import ForgotPassword from '@/views/Login/ForgotPassword'
import SetNewPassword from '@/views/Login/SetNewPassword'

const homeBreadcrumb = { text: 'Start', to: '/' }

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    component: Login,
    children: [
      {
        path: '/',
        name: 'Login',
        component: LoginForm,
        meta: {
          breadcrumb: [
            homeBreadcrumb,
            { text: 'Login', disabled: true },
          ]
        },
      },
      {
        path: 'passwort-vergessen',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
          breadcrumb: [
            homeBreadcrumb,
            { text: 'Login', to: { name: 'Login' }, exact: true },
            { text: 'Passwort vergessen', disabled: true },
          ]
        },
      },
      {
        path: 'neues-passwort/:token',
        name: 'SetNewPassword',
        component: SetNewPassword,
        meta: {
          breadcrumb: [
            homeBreadcrumb,
            { text: 'Neues Passwort festlegen', disabled: true },
          ]
        },
      },
    ],
  },
  {
    path: '/galerie',
    name: 'Gallery',
    component: Gallery,
    meta: {
      breadcrumb: [
        homeBreadcrumb,
        { text: 'Galerie', disabled: true },
      ]
    },
  },
  {
    path: '/dokumente',
    name: 'Downloads',
    component: Downloads,
    meta: {
      breadcrumb: [
        homeBreadcrumb,
        { text: 'Downloads', disabled: true },
      ]
    },
  },
  {
    path: '/katalog',
    name: 'Catalogue',
    component: Catalogue,
    meta: {
      breadcrumb: [
        homeBreadcrumb,
        { text: 'Katalog', disabled: true },
      ]
    },
  },
  {
    path: '/agb',
    name: 'Agb',
    component: Agb,
    meta: {
      breadcrumb: [
        homeBreadcrumb,
        { text: 'AGB', disabled: true },
      ]
    },
  },
  {
    path: '/impressum',
    name: 'Imprint',
    component: Imprint,
    meta: {
      breadcrumb: [
        homeBreadcrumb,
        { text: 'Impressum', disabled: true },
      ]
    },
  },
  {
    path: '/datenschutz',
    name: 'Privacy',
    component: Privacy,
    meta: {
      breadcrumb: [
        homeBreadcrumb,
        { text: 'Datenschutz', disabled: true },
      ]
    },
  },
  {
    path: '/firmen/:companyId/benutzer/registrieren/:token',
    name: 'Registration',
    component: Registration,
    meta: {
      breadcrumb: [
        homeBreadcrumb,
        { text: 'Registrierung', disabled: true },
      ]
    },
  },
]
