import Account from '@/views/Account/Account'
import AccountConfigurations from '@/views/Account/AccountConfigurations'
import AccountOrders from '@/views/Account/AccountOrders'
import AccountOverview from '@/views/Account/AccountOverview'
import AfterBuy from '@/views/AfterBuy'
import BikeIndex from '@/views/BikeConfigurator/Index'
import BikeConfigurator from '@/views/BikeConfigurator/Configuration/BikeConfigurator'
import BikeOverview from '@/views/BikeConfigurator/Overview/BikeOverview'
import Cart from '@/views/Cart'

import SeriesBikeIndex from '@/views/SeriesBikes/Index.vue'
import SeriesBikeOverview from '@/views/SeriesBikes/Overview.vue'
import SeriesBikeDetails from '@/views/SeriesBikes/Details.vue'

import store from '@/store'

const homeBreadcrumb = { text: 'Start', to: '/' }
const checkWithUser = check =>
  store.state.user.userChecked
    ? check()
    : store.dispatch('getUser', check)

export default [
  {
    path: '/konfigurator',
    component: BikeIndex,
    children: [
      {
        path: '/',
        redirect: 'uebersicht',
      },
      {
        path: 'uebersicht',
        name: 'BikeOverview',
        component: BikeOverview,
        meta: {
          breadcrumb: [
            homeBreadcrumb,
            { text: 'Konfigurator', disabled: true },
          ]
        },
      },
      {
        path: 'bike',
        name: 'BikeConfigurator',
        component: BikeConfigurator,
        beforeEnter: (to, from, next) => checkWithUser(() =>
          store.state.user !== null ? next() : next({ name: 'Login' })
        ),
        meta: {
          breadcrumb: [
            homeBreadcrumb,
            { text: 'Konfigurator', to: { name: 'BikeOverview' }, exact: true },
            { text: 'Modell', disabled: true },
          ]
        },
      },
    ],
  },
  {
    path: '/account',
    component: Account,
    children: [
      {
        path: '/',
        redirect: 'uebersicht',
      },
      {
        path: 'uebersicht',
        name: 'AccountOverview',
        component: AccountOverview,
        meta: {
          breadcrumb: [
            homeBreadcrumb,
            { text: 'Mein Konto', disabled: true },
          ]
        },
      },
      {
        path: 'bestellungen',
        name: 'AccountOrders',
        component: AccountOrders,
        meta: {
          breadcrumb: [
            homeBreadcrumb,
            { text: 'Meine Bestellungen', disabled: true },
          ]
        },
      },
      {
        path: 'konfigurationen',
        name: 'AccountConfigurations',
        component: AccountConfigurations,
        meta: {
          breadcrumb: [
            homeBreadcrumb,
            { text: 'Meine Konfigurationen', disabled: true },
          ]
        },
      },
    ],
  },
  {
    path: '/kauf-erfolgreich',
    name: 'AfterBuy',
    component: AfterBuy,
  },
  {
    path: '/serienraeder',
    component: SeriesBikeIndex,
    children: [
      {
        path: '/',
        redirect: 'uebersicht',
      },
      {
        path: 'uebersicht',
        name: 'SeriesBikeOverview',
        component: SeriesBikeOverview,
        meta: {
          breadcrumb: [
            homeBreadcrumb,
            { text: 'Übersicht', disabled: true },
          ]
        },
      },
      {
        path: 'modell/:id',
        name: 'SeriesBikeDetails',
        component: SeriesBikeDetails,
        beforeEnter: (to, from, next) => checkWithUser(() =>
          store.state.user !== null ? next() : next({ name: 'Login' })
        ),
        meta: {
          breadcrumb: [
            homeBreadcrumb,
            { text: 'Übersicht', to: { name: 'SeriesBikeOverview' }, exact: true },
            { text: 'Modell', disabled: true },
          ]
        },
      },
    ]
  },
  {
    path: '/warenkorb',
    name: 'Cart',
    component: Cart,
    beforeEnter: (to, from, next) => checkWithUser(() =>
      store.state.user !== null ? next() : next({ name: 'Login' })
    ),
    meta: {
      breadcrumb: [
        homeBreadcrumb,
        { text: 'Warenkorb', disabled: true },
      ]
    },
  },
]
