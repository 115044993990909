<template>
  <div class="series-bikes-overview--wrap">
    <div class="action-header">
      <h1>Serienräder</h1>

      <v-text-field
        v-model="search"
        outlined
        hide-details
        dense
        label="Suche"
        placeholder="Bezeichnung/Modellnummer"
        prepend-inner-icon="search"
        clearable
        class="search-input"
      />
    </div>

    <v-row>
      <v-col
        v-for="bike in filteredBikes"
        :key="bike.standardBikeSeriesId"
        :sm="6"
        :lg="4"
        class="cell"
      >
        <div class="preview-wrap" role="link">
          <v-card
            outlined
            class="preview"
            :to="{ name: 'SeriesBikeDetails', params: { id: bike.standardBikeSeriesId } }"
          >
            <component-img
              :id="bike.standardBikeSeriesId"
              :has-image="bike.hasImage"
              :alt="bike.title"
              :height="245"
              is-series
              contain
            />
            <v-divider />
            <v-card-title class="bike-title justify-center">
              {{ bike.title }}
            </v-card-title>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SeriesApi from '@/api/Series'

import ComponentImg from '@/components/ComponentImg'

export default {
  name: 'series-bikes-overview',

  components: {
    ComponentImg,
  },

  data () {
    return {
      bikes: [],
      isLoading: false,
      search: '',
    }
  },

  computed: {
    filteredBikes () {
      return this.bikes.filter(bike => {
        const term = (this.search || '').toLowerCase()

        return bike.modelCode.toLowerCase().includes(term) ||
          bike.title.toLowerCase().includes(term)
      })
    },
  },

  mounted () {
    this.getBikes()
    this.$store.commit('setBreadcrumbItems', [{ text: 'Serienräder', disabled: true }])
  },

  methods: {
    /**
     * getBikes
     *
     * @returns {void}
     */
    async getBikes () {
      this.isLoading = true
      const res = await SeriesApi.getAll(true)
      this.isLoading = false

      if (res.ok) {
        this.bikes = (await res.json()).sort((a, b) => a.title.localeCompare(b.title))
      }
    },
  },
}
</script>

<style lang="scss">
  .search-input {
    max-width: 280px;
  }
</style>
