<template>
  <div ref="previewCanvasWrap" class="preview-canvas-wrap">
    <canvas
      ref="previewCanvas"
      :width="defaultDimensions.width"
      :height="defaultDimensions.height"
    />

    <div class="additional-actions">
      <component-color-menu
        :active-component="toColorize"
        :slots-without-images="paintableSlotsWithoutImage"
        @click:componenent="component => $emit('show:picker', component.componentId)"
      />
    </div>
  </div>
</template>

<script>
import { fabric } from 'fabric'

import ComponentColorMenu from './ComponentColorMenu'
import DefaultBike from '@/assets/data/bike-base.json'

const DATA_KEY = 'customData'

export default {
  name: 'bike-preview',

  components: {
    ComponentColorMenu,
  },

  props: {
    fitInto: {
      type: String,
      default: null,
    },
    colorChoiceActive: {
      type: Boolean,
      default: false,
    },
    sidebarVisible: {
      type: Boolean,
      default: false,
    },
    toColorize: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      defaultBike: DefaultBike,
      defaultDimensions: {
        width: 1296,
        height: 900
      },
      previewCanvas: null,
      colorBoxElements: [],
      possibleBoxPositions: [],
      boxWidth: 60,
      boxHeight: 30,
      strokeColor: '#000'
    }
  },

  computed: {
    frameCategory () {
      return this.$store.state.frameCategory
    },

    images () {
      return this.previewCanvas ? this.previewCanvas.getObjects() : []
    },

    slots () {
      return this.$store.state.bike.slots
    },

    paintableSlotsWithoutImage () {
      return this.slots
        .filter(slot => slot.pickedComponent && slot.pickedComponent.paintable)
        .filter(slot =>
          this.images.filter(image =>
            image[DATA_KEY] && image[DATA_KEY].componentType === slot.componentType.componentType
          ).length === 0
        )
    }
  },

  watch: {
    slots () {
      // when the slot-configuration changes, we have to update our canvas based on that
      this.onUpdatedSlots()
    },

    colorChoiceActive () {
      // wait for the tab-animation to finish (there's no event available)
      setTimeout(this.resizePreviewCanvas, 300)
    },

    sidebarVisible () {
      // the sidebar can change the availabel width, so we have to react
      this.resizePreviewCanvas()
    },

    toColorize () {
      // when the component to colorize changes, we want to highlight the related box
      this.highlightActiveColorbox()
    }
  },

  /**
   * mounted
   *
   * @returns {void}
   */
  mounted () {
    this.slots.length && this.initCanvas()
  },

  /**
   * created
   *
   * @returns {void}
   */
  created () {
    window.addEventListener('resize', this.resizePreviewCanvas)
  },

  /**
   * destroyed
   *
   * @returns {void}
   */
  destroyed () {
    window.removeEventListener('resize', this.resizePreviewCanvas)
  },

  methods: {
    /**
     * initCanvas
     *
     * @returns {void}
     */
    initCanvas () {
      fabric.Object.NUM_FRACTION_DIGITS = 8 // we want to use precice positions
      this.previewCanvas = new fabric.Canvas(this.$refs.previewCanvas, { selection: false })
      this.jsonToPreviewCanvas()
    },

    /**
     * Loads the images of our default-bike into the canvas.
     *
     * @returns {void}
     */
    jsonToPreviewCanvas () {
      this.previewCanvas.loadFromJSON(this.defaultBike, () => {
        this.resizePreviewCanvas()
        this.setBoxPositions()
        this.drawColorBoxes()
        this.$emit('loaded')
      }, this.onImageLoaded)
    },

    /**
     * Shows/hides, colorizes the loaded image based on the related component-
     * dataset.
     *
     * @param {object} _ Json-object
     * @param {object} image fabric.Object instance
     *
     * @returns {void}
     */
    onImageLoaded (_, image) {
      this.lockImage(image)

      image.opacity = 0
      image.hoverCursor = 'default'

      const relatedComponent = this.getRelatedComponent(image)

      if (!relatedComponent) {
        return
      }

      if (relatedComponent.paintable) {
        relatedComponent.pickedColor && this.colorizeImage(image, relatedComponent.pickedColor.colorValue)
      } else {
        relatedComponent.color && this.colorizeImage(image, relatedComponent.color)
      }

      image.opacity = this.componentIsPicked(relatedComponent) ? 1 : 0
    },

    /**
     * Tries to find the component-dataset related to the given image.
     *
     * @param {object} image
     * @returns {object|undefined}
     */
    getRelatedComponent (image) {
      const relatedSlot = this.slots.find(slot =>
        image[DATA_KEY] && slot.componentType.componentType === image[DATA_KEY].componentType
      )

      if (relatedSlot) {
        return relatedSlot.pickedComponent
      }
    },

    /**
     * Checks if the given component is currently selected in its slot.
     *
     * @param {object} component
     * @returns {boolean}
     */
    componentIsPicked (component) {
      return this.slots.find(slot =>
        slot.pickedComponent && slot.pickedComponent.componentId === component.componentId
      ) !== undefined
    },

    /**
     * colorizeImage
     *
     * @param {object} image fabric.js-image
     * @param {string} color hex-color to use
     * @param {number} alpha alpha to use for the color-overlay
     */
    colorizeImage (image, color, alpha = 1) {
      if (image[DATA_KEY] && image[DATA_KEY].slotIndependent) {
        return
      }

      image.filters = [
        new fabric.Image.filters.BlendColor({
          color: color,
          mode: 'tint',
          alpha: alpha,
        }),
      ]

      image.applyFilters()
    },

    /**
     * Scales elements of the canvas if it has a size that differs from the
     * default one (e.g when resizing).
     *
     * @returns {void}
     */
    resizePreviewCanvas () {
      if (!this.previewCanvas || !this.$refs.previewCanvasWrap) {
        return
      }

      // const { width, top } = this.$refs.previewCanvasWrap.getBoundingClientRect()
      const { width, top } = this.fitInto
        ? document.querySelector(this.fitInto).getBoundingClientRect()
        : this.$refs.previewCanvasWrap.getBoundingClientRect()

      // canvas/element to fit into is currently hidden -> fallback to default
      if (width === 0) {
        this.previewCanvas.setZoom(1)
        this.previewCanvas.setDimensions({ width: this.defaultDimensions.width, height: this.defaultDimensions.height })
        return
      }

      const availableHeight = window.innerHeight - top
      const scaling = this.defaultDimensions.height * (width / this.defaultDimensions.width) > availableHeight
        ? availableHeight / this.defaultDimensions.height
        : width / this.defaultDimensions.width

      this.previewCanvas.setZoom(scaling)
      this.previewCanvas.setDimensions({
        width: this.defaultDimensions.width * scaling,
        height: this.defaultDimensions.height * scaling,
      })
    },

    /**
     * If the slots get updated (=the user changed the bike-configuration), the
     * canvas  must be updated according to the changes.
     *
     * @returns {void}
     */
    onUpdatedSlots () {
      this.slots.forEach(slot => {
        const component = slot.pickedComponent

        // canvas-images related to the components within the current slot
        const relatedImages = this.images.filter(image => {
          return image[DATA_KEY] && image[DATA_KEY].componentType === slot.componentType.componentType
        })

        // remove previous, set new filters based on the updated dataset
        relatedImages.forEach(image => {
          image.filters = []
          image.applyFilters()
          image.set('opacity', component ? 1 : 0)

          if (component) {
            component.paintable
              ? component.pickedColor && this.colorizeImage(image, component.pickedColor.colorValue)
              : component.color && this.colorizeImage(image, component.color)
          }
        })
      })

      this.drawColorBoxes()
      this.previewCanvas && this.previewCanvas.renderAll()
    },

    /**
     * Locks the given image so the user can't interact with it.
     *
     * @param {object} image fabric-image to lock
     * @returns {void}
     */
    lockImage (image) {
      image.set('selectable', false)
      image.set('lockMovementX', true)
      image.set('lockMovementY', true)
    },

    /**
     * Returns the first non-transparent pixel of the given fabric-image.
     * Starts at the vertical center of the horizontal end, moves to the start.
     *
     * @param {object} image fabric-image
     * @param {number} offset Amount of pixels to skip per iteration (gets set by recursion-logic)
     *
     * @returns {object} Point with a non-transparent pixel or the center of the image as fallback
     */
    getLineStart (image, offset = 0) {
      // const { x, y } = image.oCoords.ml
      // const xTry = x + offset

      const { x, y } = image.getCenterPoint()
      const xTry = x - image.width / 2 + offset
      const zoom = this.previewCanvas.getZoom()
      // the helper-fn doesn't include the zoom in its calculation, so we have to do this
      const isTransparent = this.previewCanvas.isTargetTransparent(image, xTry * zoom, y * zoom)

      // we found a pixel! Lets return the point
      if (!isTransparent) {
        return { x: xTry, y }
      }

      // no pixel on this axis - give up, use the center of the image as fallback
      if (xTry >= image.left + image.width) {
        return image.getCenterPoint()
      }

      return this.getLineStart(image, offset + 10) // smaller value = higher precision, worse performance
    },

    /**
     * Checks the position of all visible bike-elements to calculate the
     * boundingbox of the whole bike.
     *
     * @returns {object} Coordinates of the bbox
     */
    getBikeBoundingBox () {
      const bikeImages = this.images.filter(img => img[[DATA_KEY]] && this.getRelatedComponent(img) && img.width)

      return {
        x: bikeImages.reduce((x, img) => img.left < x ? img.left : x, this.previewCanvas.width),
        x1: bikeImages.reduce((x1, img) => {
          const imgX1 = img.left + img.width * img.scaleX
          return imgX1 > x1 ? imgX1 : x1
        }, 0),
        y: bikeImages.reduce((y, img) => img.top < y ? img.top : y, this.previewCanvas.height),
        y1: bikeImages.reduce((y1, img) => {
          const imgY1 = img.top + img.height * img.scaleY
          return imgY1 > y1 ? imgY1 : y1
        }, 0),
      }
    },

    /**
     * We want to draw, sort the colorboxes based on the y-position of the
     * related image, but we mustn't change the order of the original array
     * since the z-position of the images is based on that.
     *
     * @returns {array} Sorted images with their related components
     */
    prepareImagesForDrawing () {
      return [...this.images]
        .map(image => {
          const component = this.getRelatedComponent(image)
          return { image, component }
        })
        .filter(({ component }) => component && component.paintable)
        .sort(({ image: a }, { image: b }) => a.getCenterPoint().y - b.getCenterPoint().y)
    },

    /**
     * Draws dots onto images of components. Those will get connected to color-
     * boxes in another step.
     *
     * @returns {void}
     */
    drawComponentDots () {
      const images = this.prepareImagesForDrawing()
      const dotRadius = 5

      images.forEach(({ image, component }) => {
        if (this.colorBoxElements.find(el => el.type === 'circle' && el.relatedComponent.componentId === component.componentId)) {
          return
        }

        const { x: startX, y: startY } = this.getLineStart(image)
        const startDot = new fabric.Circle({
          radius: dotRadius,
          fill: this.strokeColor,
          left: startX - dotRadius,
          top: startY - dotRadius,
          stroke: '#fff',
          selectable: false,
          hoverCursor: 'default',
          relatedComponent: component,
        })

        this.previewCanvas.add(startDot)
        this.colorBoxElements = [...this.colorBoxElements, startDot]
      })
    },

    /**
     * Removes the drawn colorboxes from the canvas if the related component
     * isn't picked anylonger.
     *
     * @returns {void}
     */
    clearUnusedBoxes () {
      this.colorBoxElements = this.colorBoxElements.filter(element => {
        const relatedComponentIsPicked = this.slots.reduce((picked, slot) =>
          picked || (slot.pickedComponent && slot.pickedComponent.componentId === element.relatedComponent.componentId)
        , false)

        !relatedComponentIsPicked && this.previewCanvas.remove(element)

        return relatedComponentIsPicked
      })
    },

    clearBoxesAndConnections () {
      this.colorBoxElements = this.colorBoxElements.filter(element => {
        const isCircle = element.type === 'circle'
        !isCircle && this.previewCanvas.remove(element)
        return isCircle
      })
    },

    setBoxPositions (offset = 90) {
      const width = this.boxWidth
      const height = this.boxHeight
      const { x, x1, y, y1 } = this.getBikeBoundingBox()

      if (x1 - x < 0 || y1 - y < 0) {
        return
      }

      const possibleXBoxes = Array.from(Array(Math.floor((x1 - x) / (width + offset))))
      const possibleYBoxes = Array.from(Array(Math.floor((y1 - y) / (height + offset))))

      const bottom = possibleXBoxes.map((_, i) => ({ x: x + width + i * (width + offset), y: y1 + height }))
      const left = possibleYBoxes.map((_, i) => ({ x: x - width * 1.25, y: y + (i * (height + offset)) }))
      const right = possibleYBoxes.map((_, i) => ({ x: x1 + width / 2, y: y + (i * (height + offset)) }))

      this.possibleBoxPositions = [...left, ...right, ...bottom]
    },

    getBestBoxPosition (image) {
      const dot = this.colorBoxElements.find(el =>
        el.type === 'circle' && el.relatedComponent.componentType.componentType === image.customData.componentType
      )

      const alreadyUsed = position => this.colorBoxElements.reduce((used, box) => {
        return used || (box.type === 'rect' && box.left === position.x && box.top === position.y)
      }, false)

      const bestPosition = this.possibleBoxPositions
        .filter(pos => !alreadyUsed(pos))
        .reduce((bestPosition, position) => {
          const a = position.x - dot.left
          const b = position.y - dot.top
          const dist = Math.sqrt(a * a + b * b)

          return bestPosition
            ? bestPosition.dist < dist ? bestPosition : { ...position, dist }
            : { ...position, dist }
        }, null)

      return bestPosition
    },

    /**
     * drawColorBoxes
     *
     * @returns {void}
     */
    drawColorBoxes () {
      this.clearUnusedBoxes()
      this.clearBoxesAndConnections()
      this.drawComponentDots()

      const images = this.prepareImagesForDrawing()

      images.forEach(({ image, component }, i) => {
        const relatedBox = this.colorBoxElements.find(el =>
          el.type === 'rect' && el.relatedComponent && el.relatedComponent.componentId === component.componentId
        )

        // box for the same type exists already, just update it
        if (relatedBox) {
          component.pickedColor && relatedBox.set('fill', component.pickedColor.colorValue)
          return
        }

        // component not chosen or was removed, so the image isn't visible
        if (!this.componentIsPicked(component)) {
          // relatedBox &&
          return
        }

        const bestPosition = this.getBestBoxPosition(image)
        const boxX = bestPosition.x
        const boxY = bestPosition.y

        const selectionRect = new fabric.Rect({
          left: boxX,
          top: boxY,
          width: this.boxWidth,
          height: this.boxHeight,
          fill: component.pickedColor ? component.pickedColor.colorValue : '#fff',
          stroke: this.strokeColor,
          strokeWidth: 2,
          selectable: false,
          hoverCursor: 'pointer',
          relatedComponent: component,
        })

        const dot = this.colorBoxElements.find(element =>
          element.type === 'circle' && element.relatedComponent.componentId === component.componentId
        )

        const lineConnection = new fabric.Line([
          dot.left + dot.width / 2,
          dot.top + dot.height / 2,
          boxX + this.boxWidth / 2,
          boxY + this.boxHeight / 2
        ], {
          stroke: this.strokeColor,
          strokeWidth: 1,
          selectable: false,
          relatedComponent: component,
          evented: false,
        })

        selectionRect.on('mousedown', () => this.$emit('show:picker', component.componentId))

        this.previewCanvas.add(lineConnection)
        this.previewCanvas.add(selectionRect)
        this.colorBoxElements = [...this.colorBoxElements, lineConnection, selectionRect]
      })
    },

    /**
     * If there's a component defined that should get painted/colorized, we
     * want to highlight the related color-box.
     *
     * @returns {void}
     */
    highlightActiveColorbox () {
      this.colorBoxElements.forEach(box => {
        if (!box.relatedComponent) {
          return
        }

        box.dirty = true

        const colorActive = this.$vuetify.theme.defaults.light.primary
        const isActive = this.toColorize &&
          box.relatedComponent &&
          box.relatedComponent.componentId === this.toColorize.componentId

        if (box.type === 'circle') {
          box.fill = isActive ? colorActive : this.strokeColor
          return
        }

        box.stroke = isActive ? colorActive : this.strokeColor
        box.strokeWidth = isActive ? 3 : 2
      })

      this.previewCanvas.renderAll()
    },

    overlaps (rect1, rect2) {
      return !(rect1.right < rect2.left ||
        rect1.left > rect2.right ||
        rect1.bottom < rect2.top ||
        rect1.top > rect2.bottom)
    }
  },
}
</script>

<style lang="scss">
  .preview-canvas-wrap {
    position: relative;
    overflow: hidden;
    background-color: #f9f9f9;

    .canvas-container {
      margin: 0 auto;
    }

    .additional-actions {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      z-index: 1;
    }
  }
</style>
