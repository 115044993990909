<template>
  <div class="admin-components-overview-wrap">
    <div class="action-header">
      <h1>Komponenten</h1>

      <v-btn :to="{ name: 'ComponentDetails' }" depressed>
        <v-icon color="primary" left>
          create
        </v-icon>
        Neue Komponente
      </v-btn>
    </div>

    <div class="components-table-wrap">
      <data-table
        :is-loading="isLoading"
        :items="components"
        :columns="columnOptions"
        state-key="components"
        search-placeholder="Bezeichnung/Kategorie"
        @dblclick:row="(e, { item }) => $router.push({ name: 'ComponentDetails', params: { id: item.componentId } })"
      >
        <template v-slot:[`item.image`]="{ item }">
          <component-img
            :id="item.componentId"
            :has-image="item.hasImage"
            :alt="item.label"
            :height="60"
            :width="100"
            class="my-1"
          />
        </template>

        <template v-slot:[`item.paintable`]="{ item }">
          <v-switch :input-value="item.paintable" inset disabled />
        </template>

        <template v-slot:[`item.available`]="{ item }">
          <v-switch :input-value="item.available" inset disabled />
        </template>

        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | centsToEuro }}
        </template>

        <template v-slot:[`item.color`]="{ item }">
          <v-chip v-if="item.color" x-small :color="item.color" class="px-2 mx-2" />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon class="mr-1" @click="componentToRemove = item.componentId">
            <v-icon small>
              delete
            </v-icon>
          </v-btn>
          <v-btn icon :to="{ name: 'ComponentDetails', params: { id: item.componentId } }">
            <v-icon color="primary" small>
              create
            </v-icon>
          </v-btn>
        </template>
      </data-table>
    </div>

    <remove-component-dialog
      :component-id="componentToRemove"
      @cancel="componentToRemove = null"
      @ok="deleteSelectedComponent"
    />
  </div>
</template>

<script>
import ComponentApi from '@/api/Component'
import ComponentImg from '@/components/ComponentImg'
import DataTable from '@/components/DataTable'
import RemoveComponentDialog from './RemoveComponentDialog'

export default {
  name: 'admin-components-overview',

  components: {
    ComponentImg,
    DataTable,
    RemoveComponentDialog,
  },

  data () {
    return {
      components: [],
      componentToRemove: null,
      columnOptions: [
        { value: 'image', text: 'Vorschau', width: 150, sortable: false, filterable: false },
        { value: 'label', text: 'Bezeichnung' },
        { value: 'componentType.label', text: 'Kategorie', isFilter: true, filterKey: 'componentType.label' },
        { value: 'available', text: 'Verfügbar', width: 130 },
        { value: 'paintable', text: 'Lackierbar', width: 130 },
        { value: 'color', text: 'Farbe', width: 100, sortable: false },
        { value: 'price', text: 'Preis', align: 'right', width: 120 },
        { value: 'actions', text: 'Aktionen', sortable: false, align: 'right', width: 120 },
      ],
      isLoading: false,
    }
  },

  mounted () {
    this.getComponents()
    this.$store.commit('setBreadcrumbItems', [{ text: 'Komponenten', disabled: true }])
  },

  methods: {
    /**
     * getComponents
     *
     * @returns {void}
     */
    async getComponents () {
      this.isLoading = true
      const res = await ComponentApi.getAll()
      res.ok && (this.components = await res.json())
      this.isLoading = false
    },

    /**
     * Deletes the currently selected (by pressing the 'delete'-button before
     * confirming the action) component.
     *
     * @returns {void}
     */
    async deleteSelectedComponent () {
      if (this.componentToRemove === null) {
        return
      }

      const res = await ComponentApi.delete(this.componentToRemove)
      this.componentToRemove = null

      if (res.ok) {
        this.getComponents()
        this.$store.commit('setSnackbar', { text: 'Komponente gelöscht', color: 'success' })
        return
      }

      if (res.status === 400) {
        const { error } = await res.json()
        this.$store.commit('setSnackbar', { text: error, color: 'error' })
        return
      }

      this.$store.commit('setSnackbar', { text: 'Löschen fehlgeschlagen', color: 'error' })
    },
  },
}
</script>
