<template>
  <div class="permission-chips">
    <v-chip
      v-for="(permission, i) in permissions"
      :key="i"
      small
      :color="permissionIsActive(permission) ? 'success' : 'grey lighten-4'"
      class="ma-1"
      @click="$emit('click', permission)"
    >
      {{ permission.label }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'permission-chips',

  props: {
    permissions: {
      type: Array,
      default: () => ([])
    },

    activePermissions: {
      type: Array,
      default: () => ([])
    },
  },

  methods: {
    permissionIsActive ({ permission }) {
      return this.activePermissions.includes(permission)
    }
  },
}
</script>

<style>

</style>
