import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl: `${baseUrl}/shop`,
  baseHeader,

  /**
   * getPrice
   *
   * @param {number} seriesId
   * @param {number} colorId
   * @param {number} frameId
   * @param {number} amount
   * @returns {Promise}
   */
  getPrice (seriesId, colorId, frameId, amount = 1) {
    const params = new URLSearchParams({
      standard_bike_series_id: seriesId,
      standard_bike_series_color_id: colorId,
      standard_bike_series_frame_id: frameId,
      amount,
    })

    return fetch(`${this.baseUrl}/price?${params}`, {
      headers: this.baseHeader,
    })
  },

  /**
   * getPrice
   *
   * @param {number} seriesId
   * @returns {Promise}
   */
  getPrices (seriesId) {
    return fetch(`${this.baseUrl}/price/${seriesId}`, {
      headers: this.baseHeader,
    })
  },

  /**
   * createOrder
   *
   * @returns {Promise}
   */
  createOrder (comment = '') {
    return fetch(`${this.baseUrl}/order`, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify({ comment }),
    })
  },
}
