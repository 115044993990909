<template>
  <div class="admin-company-wrap">
    <h1>Firma {{ isEdit ? 'bearbeiten' : 'erstellen' }}</h1>

    <h2>Daten</h2>
    <v-form v-model="formIsValid" class="mb-5">
      <v-row>
        <v-col :lg="6">
          <v-text-field
            v-model="company.label"
            outlined
            prepend-inner-icon="business"
            label="Firmenname*"
            :rules="requiredRules"
            :disabled="!userCanEditCompanies"
          />

          <v-text-field
            v-model="company.phoneNumber"
            outlined
            prepend-inner-icon="phone"
            label="Telefonnummer"
            :disabled="!userCanEditCompanies"
          />
          <v-text-field
            v-model="company.customerNumber"
            outlined
            prepend-inner-icon="fingerprint"
            label="Kundennummer*"
            :rules="requiredRules"
            :disabled="!userCanEditCompanies"
          />
          <v-text-field
            v-model="company.contactPerson"
            outlined
            prepend-inner-icon="mail"
            label="Zuständiger Außendienstler"
            :disabled="!userCanEditCompanies"
          />
          <v-row>
            <v-col>
              <v-text-field
                :value="company.surchargeFactor.toFixed(2)"
                :rules="surchargeRules"
                outlined
                label="Aufschlagsfaktor"
                type="number"
                min="1"
                step="0.01"
                @input="value => company.surchargeFactor = +value"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col :lg="6">
          <v-text-field
            v-model="company.addressLine1"
            outlined
            prepend-inner-icon="edit_road"
            label="Adresszeile 1"
            :disabled="!userCanEditCompanies"
          />
          <v-text-field
            v-model="company.addressLine2"
            outlined
            prepend-inner-icon="edit_road"
            label="Adresszeile 2"
            :disabled="!userCanEditCompanies"
          />
          <v-text-field
            v-model="company.postCode"
            outlined
            prepend-inner-icon="location_city"
            label="PLZ"
            :disabled="!userCanEditCompanies"
          />
          <v-text-field
            v-model="company.city"
            outlined
            prepend-inner-icon="location_city"
            label="Stadt"
            :disabled="!userCanEditCompanies"
          />
        </v-col>
      </v-row>

      <div class="d-flex justify-end">
        <v-btn
          v-if="isEdit && userCanEditCompanies"
          depressed
          class="mr-2"
          @click="showDeleteDialog = true"
        >
          <v-icon color="red accent-3" left>
            delete
          </v-icon>
          Firma löschen
        </v-btn>

        <v-btn depressed class="mr-2" :disabled="!formIsValid" @click="persistCompany()">
          <v-icon color="primary" left>
            create
          </v-icon>
          {{ isEdit ? 'Speichern' : 'Erstellen' }}
        </v-btn>

        <v-btn depressed :disabled="!formIsValid" @click="persistCompany(true)">
          <v-icon color="primary" left>
            save_alt
          </v-icon>
          {{ isEdit ? 'Speichern, schließen' : 'Erstellen, schließen' }}
        </v-btn>
      </div>

      <base-dialog
        v-if="isEdit"
        headline="Firma wirklich löschen?"
        info-text="Diese Aktion kann nicht rückgängig gemacht werden und ist nur möglich, falls keinerlei Nutzer zugeordnet sind."
        :is-visible="showDeleteDialog"
        @cancel="showDeleteDialog = false"
        @ok="deleteCompany"
      />
    </v-form>

    <div v-if="companyId">
      <v-divider class="my-10" />
      <user-list :company-id="companyId" />
    </div>

    <leave-confirmation
      :current-data="company"
      :default-data="loadedCompany"
      :route-handler="routeHandler"
      @decided="routeHandler = null"
    />
  </div>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'
import CompanyApi from '@/api/Company'
import LeaveConfirmation from '@/components/LeaveConfirmation'
import UserList from '@/components/UserList'

export default {
  name: 'admin-company',

  components: {
    BaseDialog,
    UserList,
    LeaveConfirmation,
  },

  data () {
    return {
      company: {
        label: '',
        customerNumber: '',
        addressLine1: '',
        addressLine2: '',
        postCode: '',
        city: '',
        phoneNumber: '',
        contactPerson: '',
        surchargeFactor: 1,
      },
      loadedCompany: null,
      showDeleteDialog: false,
      formIsValid: false,
      requiredRules: [v => !!v],
      surchargeRules: [v => v >= 1 || 'Muss größer als 1.00 sein.'],
      routeHandler: null,
    }
  },

  computed: {
    companyId () {
      return this.$route.params.id ? +this.$route.params.id : null
    },

    isEdit () {
      return this.$route.params.id !== undefined
    },

    userCanEditCompanies () {
      return this.$store.getters.userCanEditCompanies
    },
  },

  watch: {
    companyId (to, from) {
      to && +to !== +from && this.loadCompany()
    },
  },

  mounted () {
    this.setLoadedCompany()
    this.isEdit && this.loadCompany()
  },

  beforeRouteLeave (to, from, next) {
    this.routeHandler = next
  },

  methods: {
    /**
     * Sets the dataset to compare to when leaving the page.
     *
     * @returns {void}
     */
    setLoadedCompany () {
      this.loadedCompany = JSON.parse(JSON.stringify(this.company))
    },

    async loadCompany () {
      if (this.companyId) {
        const res = await CompanyApi.get(this.companyId)

        if (res.ok) {
          this.company = await res.json()
          this.setLoadedCompany()
        }
      }
    },

    /**
     * Creates a new company with the currently entered data or updates the
     * current one.
     *
     * @param {Boolean} close Redirect to the overview after saving
     * @returns {void}
     */
    async persistCompany (close) {
      if (!this.formIsValid) {
        return
      }

      const res = this.isEdit
        ? await CompanyApi.update({ ...this.company, id: this.companyId })
        : await CompanyApi.create(this.company)

      if (res.status === 400) {
        return this.$store.commit('setSnackbar', { text: 'Die Kundennummer ist bereits vergeben', color: 'error' })
      }

      if (!res.ok) {
        this.$store.commit('setSnackbar', { text: 'Ein Fehler ist aufgetreten', color: 'error' })
        return
      }

      this.$store.commit('setSnackbar', {
        text: `Firma ${this.isEdit ? 'aktualisiert' : 'erstellt'}`,
        color: 'success'
      })

      if (close) {
        this.setLoadedCompany()
        return this.$router.push({ name: 'CompanyOverview' })
      }

      if (this.isEdit) {
        this.loadCompany()
      } else {
        const createdCompany = await res.json()
        this.$router.push({ name: 'CompanyDetails', params: { id: createdCompany.companyId } })
      }
    },

    /**
     * Deletes the current company.
     *
     * @returns {void}
     */
    async deleteCompany () {
      if (!this.isEdit) {
        return
      }

      const res = await CompanyApi.delete(this.companyId)
      this.showDeleteDialog = false

      if (res.ok) {
        this.setLoadedCompany()
        this.$store.commit('setSnackbar', { text: 'Firma gelöscht', color: 'success' })
        this.$router.push({ name: 'CompanyOverview' })
      } else {
        this.$store.commit('setSnackbar', { text: 'Ein Fehler ist aufgetreten', color: 'error' })
      }
    },
  },
}
</script>
