<template>
  <v-dialog :value="isVisible" width="600" @input="visibility => $emit('set:visibility', visibility)">
    <form @submit.prevent="addFavorite">
      <v-card>
        <v-card-title>Konfiguration der Merkliste hinzufügen</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="favoriteTitle"
            prepend-inner-icon="title"
            hide-details
            outlined
            label="Titel"
            required
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn type="button" text @click="$emit('set:visibility', false)">
            Abbrechen
          </v-btn>
          <v-btn type="submit" depressed color="primary">
            Auf die Merkliste
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import BookmarkApi from '@/api/Bookmark'

export default {
  name: 'favorite-dialog',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      favoriteTitle: '',
    }
  },

  computed: {
    bike () {
      return this.$store.state.bike
    },
  },

  methods: {

    /**
     * addFavorite
     *
     * @returns {void}
     */
    async addFavorite () {
      const res = await BookmarkApi.create({
        bookmarkId: null,
        label: this.favoriteTitle,
        configuration: this.bike,
        visibleForCompany: false
      })

      res.ok && this.$store.commit('setSnackbar', { text: 'Konfiguration gespeichert', color: 'success' })

      this.favoriteTitle = ''
      this.$emit('set:visibility', false)
    },
  },
}
</script>

<style>

</style>
