<template>
  <div class="availability-status--wrap">
    <v-alert
      v-if="status"
      :color="isAvailable ? 'primary' : 'warning'"
      outlined
      dense
      icon="info"
      tile
    >
      {{ isAvailable
        ? 'Der Artikel ist verfügbar.'
        : 'Der Artikel ist momentan leider nicht verfügbar.'
      }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'availability-status',

  props: {
    status: {
      type: String,
      default: null,
      validator: function (value) {
        return [
          'unknown_article',
          'available_instant',
          'available_next_day',
          'alternatives_instant',
          'alternatives_next_day',
          'partial_available',
          'not_available',
          'available_from_manufacturer',
          'partial_available_from_manufacturer',
        ].includes(value)
      }
    }
  },

  computed: {
    isAvailable () {
      return this.status === 'available_instant' || this.status === 'available_next_day'
    },
  },
}
</script>
