<template>
  <v-dialog
    :value="isVisible"
    :width="1160"
    max-width="90%"
    content-class="frame-modal"
    @input="visible => !visible && $emit('close')"
  >
    <v-sheet class="pa-4">
      <h2>
        Verfügbare Rahmen
      </h2>

      <slot />

      <v-row v-if="frames && frames.length">
        <v-col
          v-for="frame in frames"
          :key="frame.componentId"
          :sm="6"
          :lg="4"
          class="cell"
        >
          <div class="preview-wrap">
            <v-card
              outlined
              :to="{
                name: 'BikeConfigurator',
                query: { model: frame.model.modelSeriesId, frame: frame.componentId },
              }"
            >
              <component-img
                :id="frame.componentId"
                :has-image="frame.hasImage"
                :alt="frame.label"
                :height="245"
                contain
              />
              <v-divider />
              <v-card-title class="bike-title justify-center">
                {{ frame.label }}
              </v-card-title>
              <v-card-subtitle class="text-subtitle-1 text-center">
                {{ frame.model.label }}
              </v-card-subtitle>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <v-alert v-if="frames !== null && !frames.length" type="info">
        Es konnten keine passenden Rahmen gefunden werden.
      </v-alert>
    </v-sheet>
  </v-dialog>
</template>

<script>
import ComponentImg from '@/components/ComponentImg'

export default {
  name: 'frame-modal',

  components: {
    ComponentImg,
  },

  props: {
    // frames to show
    frames: {
      type: Array,
      default: () => ([])
    },

    // should the dialog get shown?
    isVisible: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
