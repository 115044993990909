import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl: `${baseUrl}/standard_order`,
  baseHeader,

  /**
   * lists all standard bike series orders for current user
   *
   * @returns {Promise}
   */
  getAll () {
    return fetch(`${this.baseUrl}`, {
      method: 'GET',
      headers: this.baseHeader,
    })
  },
}
