<template>
  <div class="filter-summary--wrap">
    <v-card color="grey lighten-4" :elevation="0" class="px-4 py-2">
      <div class="mb-2 d-flex align-center justify-space-between">
        <h3 class="mr-4 d-flex align-center">
          <v-icon left>
            filter_list
          </v-icon>
          Filter
        </h3>

        <slot />
      </div>

      <div v-if="activeFilters.length">
        <v-chip
          v-for="(filter, i) in activeFilters"
          :key="i"
          color="primary"
          outlined
          class="mr-2 my-1"
          label
          close
          @click:close="removeFilter(filter)"
        >
          {{ filter.label }}
        </v-chip>
      </div>
      <div v-else>
        <v-chip class="mr-2 my-1" outlined label disabled>
          Keine ausgewählt
        </v-chip>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'filter-summary',

  model: {
    prop: 'activeFilters',
  },

  props: {
    activeFilters: {
      type: Array,
      default: () => ([]),
    },
  },

  methods: {
    /**
     * Removes the given filter from the active ones, communicates the change
     * to parent components.
     *
     * @param {object} filter
     * @return {void}
     */
    removeFilter (filter) {
      const updatedFilters = this.activeFilters.filter(({ optionCategoryId }) =>
        optionCategoryId !== filter.optionCategoryId
      )

      this.$emit('input', updatedFilters)
    }
  },
}
</script>
