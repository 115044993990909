<template>
  <v-dialog
    :value="isVisible"
    max-width="900"
    scrollable
    @input="visibility => $emit('set:visibility', visibility)"
  >
    <v-card class="summary-dialog--inner">
      <v-card-title>
        Ihre Konfiguration
      </v-card-title>

      <v-card-text>
        <components class="mb-10" />
        <frame-options class="mb-10" />
        <dekor />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-alert border="left" color="primary" dark dense class="d-inline-block ma-0">
          Gesamtpreis: <strong>{{ currentPrice | centsToEuro }}</strong>
        </v-alert>
        <v-spacer />
        <v-btn type="button" text @click="$emit('set:visibility', false)">
          <v-icon left>
            close
          </v-icon>
          Schließen
        </v-btn>

        <v-btn
          v-if="withBuyOption"
          type="button"
          color="primary"
          depressed
          :disabled="buyDisabled"
          @click="buy"
        >
          <v-icon left>
            shopping_cart
          </v-icon>
          Jetzt kaufen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Components from './Components'
import Dekor from './Dekor'
import FrameOptions from './FrameOptions'

export default {
  name: 'summary-dialog',

  components: {
    Components,
    Dekor,
    FrameOptions,
  },

  props: {
    // show the dialog
    isVisible: {
      type: Boolean,
      default: false,
    },

    // add an option to buy the current configuration
    withBuyOption: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      buyDisabled: false,
    }
  },

  computed: {
    // price of the current bike-configuration
    currentPrice () {
      return this.$store.state.prices[this.user.showCustomerPrice ? 'customerPrice' : 'retailerPrice']
    },

    // currently logged in user
    user () {
      return this.$store.state.user.user
    },
  },

  watch: {
    isVisible () {
      this.isVisible && (this.buyDisabled = false)
    },
  },

  methods: {
    buy () {
      this.buyDisabled = true
      this.$emit('buy')
    }
  },
}
</script>
