<template>
  <div class="admin-company-overview-wrap">
    <div class="action-header">
      <h1>Firmen</h1>

      <v-btn :to="{ name: 'CompanyDetails' }" exact depressed>
        <v-icon color="primary" left>
          create
        </v-icon>
        Neue Firma
      </v-btn>
    </div>

    <div class="company-table-wrap">
      <data-table
        :is-loading="isLoading"
        :items="companies"
        :columns="columnOptions"
        state-key="companies"
        search-placeholder="Kundennummer/Name/.."
        @dblclick:row="(e, { item }) => $router.push({ name: 'CompanyDetails', params: { id: item.companyId } })"
      >
        <template v-slot:[`item.surchargeFactor`]="{ item }">
          {{ item.surchargeFactor.toFixed(2).replace('.', ',') }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon class="mr-1" @click="companyToRemove = item.companyId">
            <v-icon small>
              delete
            </v-icon>
          </v-btn>
          <v-btn icon :to="{ name: 'CompanyDetails', params: { id: item.companyId } }">
            <v-icon color="primary" small>
              create
            </v-icon>
          </v-btn>
        </template>
      </data-table>
    </div>

    <base-dialog
      headline="Firma wirklich löschen?"
      :is-visible="companyToRemove !== null"
      @cancel="companyToRemove = null"
      @ok="deleteSelectedCompany"
    />
  </div>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'
import CompanyApi from '@/api/Company'
import DataTable from '@/components/DataTable'

export default {
  name: 'admin-company-overview',

  components: {
    BaseDialog,
    DataTable,
  },

  data () {
    return {
      search: '',
      companies: [],
      companyToRemove: null,
      columnOptions: [
        { value: 'label', text: 'Name' },
        { value: 'customerNumber', text: 'Kundennummer' },
        { value: 'addressLine1', text: 'Adresszeile 1' },
        { value: 'postCode', text: 'PLZ' },
        { value: 'city', text: 'Stadt' },
        { value: 'phoneNumber', text: 'Telefon' },
        { value: 'surchargeFactor', text: 'Aufschlagsfaktor' },
        { value: 'actions', text: 'Aktionen', sortable: false, align: 'right' },
      ],
      isLoading: false,
    }
  },

  mounted () {
    this.getCompanies()
    this.$store.commit('setBreadcrumbItems', [{ text: 'Firmen', disabled: true }])
  },

  methods: {
    /**
     * getCompanies
     *
     * @returns {void}
     */
    async getCompanies () {
      this.isLoading = true
      const res = await CompanyApi.getAll()
      res.ok && (this.companies = await res.json())
      this.isLoading = false
    },

    /**
     * Deletes the currently selected (by pressing the 'delete'-button before
     * confirming the action) component.
     *
     * @returns {void}
     */
    async deleteSelectedCompany () {
      if (this.companyToRemove === null) {
        return
      }

      const res = await CompanyApi.delete(this.companyToRemove)
      this.companyToRemove = null

      if (res.ok) {
        this.getCompanies()
        this.$store.commit('setSnackbar', { text: 'Firma gelöscht', color: 'success' })
      } else {
        this.$store.commit('setSnackbar', { text: 'Löschen fehlgeschlagen', color: 'error' })
      }
    },
  },
}
</script>
