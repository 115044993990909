<template>
  <div class="wishlist-button--wrap">
    <v-btn depressed @click="dialogVisible = true">
      <v-icon left>
        favorite
      </v-icon>
      Merken
    </v-btn>

    <favorite-dialog
      :is-visible="dialogVisible"
      @set:visibility="visibility => dialogVisible = visibility"
    />
  </div>
</template>

<script>
import FavoriteDialog from '@/components/FavoriteDialog'

export default {
  name: 'wishlist-button',

  components: {
    FavoriteDialog,
  },

  data () {
    return {
      dialogVisible: false,
    }
  },
}
</script>
