<template>
  <div class="admin-models-details-wrap">
    <h1>
      Modellreihe {{ isEdit ? 'bearbeiten' : 'erstellen' }}
    </h1>

    <v-form ref="form" v-model="formIsValid" class="mb-15">
      <v-row class="mb-10">
        <v-col :lg="6">
          <v-text-field
            v-model="model.label"
            prepend-inner-icon="description"
            outlined
            label="Bezeichnung*"
            :rules="requiredRules"
          />
          <v-select
            v-model="model.category"
            :items="categories"
            outlined
            prepend-inner-icon="category"
            :rules="requiredRules"
            label="Kategorie*"
          />
          <v-text-field
            v-model="model.identificationNumber"
            prepend-inner-icon="fingerprint"
            outlined
            label="Identifikationsnummer*"
            :rules="requiredRules"
          />
          <v-file-input
            ref="fileInput"
            outlined
            prepend-icon=""
            prepend-inner-icon="image"
            accept="image/*"
            label="Grafik"
            @change="setImage"
          />
        </v-col>
        <v-col :lg="6">
          <v-card outlined class="mb-10" @click="$refs.fileInput.$refs.input.click()">
            <v-img v-if="model.image" max-height="350" contain :src="model.image" />

            <component-img
              v-else
              :id="model.modelSeriesId"
              :has-image="model.hasImage"
              :alt="model.label"
              :height="400"
              is-model
              contain
              class="ma-1"
            />
          </v-card>
        </v-col>
      </v-row>

      <h2>Komponenten</h2>

      <component-modal
        v-if="slotToEdit"
        :title="slotToEdit.componentType.label"
        :components="slotToEdit.components"
        :is-visible="slotToEdit !== null"
        :chosen-components="slotToEdit.chosenComponents"
        @close="slotToEdit = null"
        @change:visibility="visibility => !visibility && (slotToEdit = null)"
        @change:components="components => slotToEdit.chosenComponents = components"
      />

      <slot-panel
        :slots="possibleSlots"
        class="mb-10"
        @change:order="onComponentOrderChange"
        @change:active="onChangeActive"
        @override:default="onOverrideDefault"
        @show:modal="slotIndex => slotToEdit = possibleSlots[slotIndex]"
      />

      <div class="d-flex justify-end">
        <v-btn v-if="isEdit" depressed class="mr-2" @click="showDeleteDialog = true">
          <v-icon color="red accent-3" left>
            delete
          </v-icon>
          Löschen
        </v-btn>

        <v-btn v-if="isEdit" depressed class="mr-2" :disabled="!formIsValid" @click="persistModel(true)">
          <v-icon color="primary" left>
            content_copy
          </v-icon>
          Als Kopie speichern
        </v-btn>

        <v-btn depressed class="mr-2" :disabled="!formIsValid" @click="persistModel()">
          <v-icon color="primary" left>
            create
          </v-icon>
          {{ isEdit ? 'Speichern' : 'Erstellen' }}
        </v-btn>

        <v-btn depressed :disabled="!formIsValid" @click="persistModel(false, true)">
          <v-icon color="primary" left>
            save_alt
          </v-icon>
          {{ isEdit ? 'Speichern, schließen' : 'Erstellen, schließen' }}
        </v-btn>
      </div>
    </v-form>

    <base-dialog
      v-if="isEdit && model.modelSeriesId"
      headline="Modellreihe wirklich löschen?"
      :is-visible="showDeleteDialog"
      @cancel="showDeleteDialog = false"
      @ok="deleteModel"
    />

    <bike-base
      v-if="showConfig"
      :frame="frameToUse"
      :slots="model.slots"
      class="mb-3"
    />

    <leave-confirmation
      :current-data="formattedData"
      :default-data="loadedModel"
      :route-handler="routeHandler"
      @decided="routeHandler = null"
    />
  </div>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'
import BikeBase from '@/views/BikeConfigurator/BikeBase'
import ComponentApi from '@/api/Component'
import ComponentImg from '@/components/ComponentImg'
import ComponentModal from '@/components/ComponentModal'
import LeaveConfirmation from '@/components/LeaveConfirmation'
import ModelApi from '@/api/Model'
import SlotPanel from './SlotPanel'

export default {
  name: 'admin-models-details',

  components: {
    BaseDialog,
    BikeBase,
    ComponentImg,
    ComponentModal,
    LeaveConfirmation,
    SlotPanel,
  },

  data () {
    return {
      possibleSlots: [],
      slotToEdit: null,
      model: {
        label: '',
        category: '',
        modelSeriesId: null,
        slots: [],
        identificationNumber: '',
        image: null,
      },
      requiredRules: [v => !!v],
      formIsValid: false,
      showDeleteDialog: false,
      loadedModel: null,
      routeHandler: null,
    }
  },

  computed: {
    categories () {
      return this.$store.state.bikeCategories
    },

    formattedData () {
      return {
        ...this.model,
        slots: this.formatSlots(),
      }
    },

    isEdit () {
      return this.$route.params.id !== undefined
    },

    showConfig () {
      return this.$route.query.cc !== undefined
    },

    frameToUse () {
      const frameCategory = this.$store.state.frameCategory
      const frameSlot = this.model.slots.find(slot => slot.componentType.componentType === frameCategory)
      return frameSlot && frameSlot.slotComponents.length ? frameSlot.slotComponents[0].component.componentId : null
    }
  },

  watch: {
    '$route.params.id': {
      handler (to, from) {
        to && +to !== +from && this.loadModel(+to)
      }
    },
  },

  async mounted () {
    this.setLoadedModel()
    await this.getComponents()
    this.isEdit && this.loadModel(this.$route.params.id)
  },

  beforeRouteLeave (to, from, next) {
    this.routeHandler = next
  },

  methods: {
    /**
     * Sets the dataset to compare to when leaving the page.
     *
     * @returns {void}
     */
    setLoadedModel () {
      this.loadedModel = JSON.parse(JSON.stringify(this.formattedData))
    },

    /**
     * Uses the base64-content of the given file as current image.
     *
     * @param {File} file File-object to use (from an upload-input)
     * @param {function} Logic to execute after the image has been set
     * @returns {void}
     */
    setImage (file, onSet) {
      if (!file) {
        this.model = { ...this.model, image: null }
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.model = { ...this.model, image: reader.result }
        typeof onSet === 'function' && onSet()
      }
    },

    /**
     * Loads all components to choose from from the api and groups those based
     * on their type/category. Those groups are the so-called slots, the first
     * level of a model.
     *
     * @returns {void}
     */
    async getComponents () {
      const res = await ComponentApi.getAll()

      if (res.ok) {
        const components = await res.json()

        this.possibleSlots = components ? components.reduce((slots, component) => {
          if (!slots.find(slot => slot.componentType.componentType === component.componentType.componentType)) {
            const slotComponents = components.filter(c =>
              c.componentType.componentType === component.componentType.componentType
            )

            slots.push({
              active: false,
              chosenComponents: [],
              useNullAsDefault: false,
              componentType: component.componentType,
              components: slotComponents,
            })
          }
          return slots
        }, []) : []

        this.possibleSlots.sort((a, b) => a.componentType.label.localeCompare(b.componentType.label))
      }
    },

    /**
     * Loads the model with the given id, uses that to populate the empty slots
     * with data.
     *
     * @param {string|number} modelId
     * @returns {void}
     */
    async loadModel (modelId) {
      const res = await ModelApi.get(modelId)

      if (res.ok) {
        this.model = await res.json()

        this.model.slots.forEach(modelSlot => {
          this.possibleSlots.forEach((slot, i) => {
            if (modelSlot.componentType.componentType === slot.componentType.componentType) {
              slot.active = modelSlot.active
              slot.slotId = modelSlot.slotId
              slot.chosenComponents = modelSlot.slotComponents
                .sort((a, b) => a.position - b.position)
                .map(slotComponent => slotComponent.component)

              slot.useNullAsDefault = modelSlot.slotComponents &&
                modelSlot.slotComponents.length &&
                modelSlot.componentType.isNullable &&
                modelSlot.defaultComponentId === null
            }
          })
        })

        if (this.model.hasImage) {
          const imageRes = await fetch(`${ModelApi.baseUrl}/${this.model.modelSeriesId}/image`)
          const file = await imageRes.blob()
          return this.setImage(file, this.setLoadedModel)
        }

        this.setLoadedModel()
      }
    },

    /**
     * Creates/updates the current model or creates a copy of that.
     *
     * @param {boolean} createCopy
     * @param {Boolean} close Redirect to the overview after saving
     * @returns {void}
     */
    async persistModel (createCopy, close) {
      if (!this.formIsValid) {
        return
      }

      const isUpdate = this.isEdit && !createCopy
      const model = {
        ...this.formattedData,
        modelSeriesId: createCopy ? null : this.model.modelSeriesId,
        slots: this.formattedData.slots.map(slot => ({
          ...slot,
          modelSeriesId: createCopy ? null : slot.modelSeriesId,
        }))
      }

      const res = isUpdate
        ? await ModelApi.update(model)
        : await ModelApi.create(model)

      if (res.status === 400) {
        return this.$store.commit('setSnackbar', { text: 'Der Name ist bereits vergeben', color: 'error' })
      }

      if (!res.ok) {
        return this.$store.commit('setSnackbar', { text: 'Ein Fehler ist aufgetreten', color: 'error' })
      }

      this.$store.commit('setSnackbar', {
        text: `Modellreihe ${isUpdate ? 'aktualisiert' : 'erstellt'}`,
        color: 'success',
      })

      this.setLoadedModel()

      if (close) {
        return this.$router.push({ name: 'ModelOverview' })
      }

      if (isUpdate) {
        this.loadModel(this.$route.params.id)
      } else {
        const newModel = await res.json()
        newModel && this.$router.push({ name: 'ModelDetails', params: { id: newModel.modelSeriesId } })
      }
    },

    /**
     * Formats the slots so the API is able to handle those.
     *
     * @return {array}
     */
    formatSlots () {
      return this.possibleSlots
        .filter(s => s.chosenComponents.length)
        .map(s => {
          const slotComponents = s.chosenComponents.map((chosenComponent, i) =>
            ({ position: i, component: chosenComponent })
          )

          const defaultComponentId = s.useNullAsDefault
            ? null
            : s.chosenComponents.length ? s.chosenComponents[0].componentId : null

          return {
            active: s.active,
            componentType: s.componentType,
            defaultComponentId,
            modelSeriesId: this.model.modelSeriesId,
            slotComponents,
            slotId: s.slotId || null,
          }
        })
    },

    /**
     * Deletes the currently edited model.
     *
     * @returns {void}
     */
    async deleteModel () {
      if (this.model.modelSeriesId === null) {
        return
      }

      const res = await ModelApi.delete(this.model.modelSeriesId)
      this.showDeleteDialog = false

      if (res.ok) {
        this.setLoadedModel()
        this.$router.push({ name: 'ModelOverview' })
        this.$store.commit('setSnackbar', { text: 'Modellreihe gelöscht', color: 'success' })
      } else {
        this.$store.commit('setSnackbar', { text: 'Löschen fehlgeschlagen', color: 'error' })
      }
    },

    /**
     * When the order of components of a slot gets changed (in a child-component),
     * we want to use that order here, too.
     * If the default-component (= the first one) gets changed, other components
     * of that slot should get sorted by their price.
     *
     * @param {object} params
     * @param {number} params.slotIndex
     * @param {array} params.components
     * @returns {void}
     */
    onComponentOrderChange ({ slotIndex, components }) {
      this.possibleSlots = this.possibleSlots.map((slot, i) => {
        if (i !== slotIndex) {
          return slot
        }

        if (slot.chosenComponents[0].componentId !== components[0].componentId) {
          components = [components[0], ...components.slice(1).sort((a, b) => a.price - b.price)]
        }

        slot.chosenComponents = components
        return slot
      })
    },

    /**
     * When a slot gets (de)activated from a child-component, we want to use
     * that info here, too.
     *
     * @param {object} params
     * @param {number} params.slotIndex
     * @param {boolean} params.active
     * @returns {void}
     */
    onChangeActive ({ slotIndex, active }) {
      this.possibleSlots = this.possibleSlots.map((slot, i) => {
        i === slotIndex && (slot.active = active)
        return slot
      })
    },

    /**
     * Usually a slot has a default-component. But for some slots it's possible
     * to ignore that, so none component gets preselected within the
     * configurator.
     *
     * @param {object} update
     * @param {number} update.slotIndex Changed slot
     * @param {boolean} update.value Use no component as default
     * @returns {void}
     */
    onOverrideDefault ({ slotIndex, value }) {
      this.possibleSlots = this.possibleSlots.map((slot, i) => {
        i === slotIndex && (slot.useNullAsDefault = value)
        return slot
      })
    }
  }
}
</script>
