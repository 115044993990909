<template>
  <div class="plus-minus-input--wrap">
    <div class="plus-minus-input--inner d-flex">
      <v-btn
        class="text-h6"
        color="primary"
        outlined
        tile
        :x-small="small"
        :disabled="value === min"
        :loading="loading"
        @click="setValue(value - 1)"
      >
        <v-icon :size="small ? 16 : 24">
          remove
        </v-icon>
      </v-btn>
      <v-text-field
        :value="value"
        outlined
        color="primary"
        step="1"
        disabled
        hide-details
        :dense="small"
        class="rounded-0"
        :class="{
          'text-h5': !small,
          'centeredInput': centeredInput,
        }"
      />
      <v-btn
        class="text-h6"
        color="primary"
        outlined
        tile
        :x-small="small"
        :disabled="value >= max"
        :loading="loading"
        @click="setValue(value + 1)"
      >
        <v-icon :size="small ? 16 : 24">
          add
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'plus-minus-input',

  props: {
    value: {
      type: Number,
      required: true,
    },

    min: {
      type: Number,
      default: null,
    },

    max: {
      type: Number,
      default: null,
    },

    small: {
      type: Boolean,
      default: false,
    },

    centeredInput: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    setValue (value) {
      value = parseInt(value)
      this.min !== null && value < this.min && (value = this.min)
      this.max !== null && value > this.max && (value = this.max)
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss">
  .plus-minus-input--wrap {
    .plus-minus-input--inner {
      button.v-btn {
        height: auto;
        align-self: stretch;
      }

      .v-text-field--outlined {
        // own outline since firefox has a weird offset in flex-context
        .v-input__slot {
          border: 1px solid currentColor;
          border-left: none;
          border-right: none;

          fieldset {
            border: none;
          }
        }

        &.centeredInput {
          input {
            text-align: center;
          }
        }
      }
    }
  }
</style>
