<template>
  <div class="category-choice--wrap">
    <v-card color="grey lighten-4" :elevation="0" class="px-4 py-2">
      <h3 class="mb-2 d-flex align-center">
        <v-icon left>
          filter_list
        </v-icon>
        Typ
      </h3>

      <v-chip
        label
        outlined
        class="my-1 mr-2"
        :color="chosenCategory === null ? 'primary' : null"
        @click="$emit('input', null)"
      >
        Alle
      </v-chip>

      <v-chip
        v-for="(category, i) in categories"
        :key="i"
        :color="chosenCategory === category ? 'primary' : null"
        outlined
        class="mr-2 my-1"
        label
        @click="$emit('input', category)"
      >
        {{ category }}
      </v-chip>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'category-choice',

  model: {
    prop: 'chosenCategory',
  },

  props: {
    chosenCategory: {
      type: String,
      default: null,
    },
  },

  computed: {
    categories () {
      return this.$store.state.bikeCategories
    },
  },
}
</script>
