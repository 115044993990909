import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import App from './App'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.use(VueReCaptcha, { siteKey: window.appsettings.captchaSitekey })
Vue.config.productionTip = false

Vue.filter('centsToEuro', cents => `${(cents / 100).toFixed(2).replace('.', ',')}\xa0€`)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
