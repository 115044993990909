<template>
  <div class="admin-series-details--wrap">
    <div class="action-header">
      <h1>Serienrad bearbeiten</h1>
      <v-switch
        v-model="seriesBike.active"
        inset
        label="Aktiv"
        hide-details
      />
    </div>

    <h2>Daten</h2>
    <v-form v-model="formIsValid" class="mb-5">
      <v-row>
        <v-col :lg="6">
          <v-text-field
            v-model="seriesBike.title"
            outlined
            prepend-inner-icon="edit"
            label="Name"
            :rules="requiredRules"
          />

          <v-file-input
            ref="fileInput"
            outlined
            prepend-icon=""
            prepend-inner-icon="image"
            accept="image/*"
            label="Grafik"
            @change="setImage"
          />

          <text-editor v-model="seriesBike.description" />
        </v-col>
        <v-col :lg="6">
          <v-card outlined class="mb-10" @click="$refs.fileInput.$refs.input.click()">
            <v-img v-if="seriesBike.image" max-height="350" contain :src="seriesBike.image" />

            <component-img
              v-else
              :id="seriesBike.standardBikeSeriesId"
              :has-image="seriesBike.hasImage"
              :alt="seriesBike.title"
              :height="400"
              is-series
              contain
              class="ma-1"
            />
          </v-card>
        </v-col>
      </v-row>

      <div class="d-flex justify-end">
        <v-btn depressed class="mr-2" :disabled="!formIsValid" @click="persistSeriesBike()">
          <v-icon color="primary" left>
            create
          </v-icon>
          Speichern
        </v-btn>

        <v-btn depressed :disabled="!formIsValid" @click="persistSeriesBike(true)">
          <v-icon color="primary" left>
            save_alt
          </v-icon>
          Speichern, schließen
        </v-btn>
      </div>
    </v-form>

    <h2>Rahmen</h2>

    <data-table
      :items="seriesBike.frames"
      :columns="frameColumns"
      :default-options="{
        sortBy: ['frameType', 'frameSize'],
        sortDesc: [false, false]
      }"
      hide-search
      class="mb-10"
    />

    <h2>Farben</h2>

    <data-table
      :items="seriesBike.colors"
      :columns="colorColumns"
      hide-search
    />

    <leave-confirmation
      :current-data="seriesBike"
      :default-data="loadedSeriesBike"
      :route-handler="routeHandler"
      @decided="routeHandler = null"
    />
  </div>
</template>

<script>
import SeriesApi from '@/api/Series'

import ComponentImg from '@/components/ComponentImg'
import DataTable from '@/components/DataTable'
import LeaveConfirmation from '@/components/LeaveConfirmation'
import TextEditor from '@/components/TextEditor.vue'

export default {
  name: 'admin-series-details',

  components: {
    ComponentImg,
    DataTable,
    LeaveConfirmation,
    TextEditor,
  },

  data () {
    return {
      seriesBike: {
        title: '',
        description: '',
        image: '',
        colors: [],
        frames: [],
      },
      loadedSeriesBike: null,
      formIsValid: false,
      requiredRules: [v => !!v],
      routeHandler: null,
    }
  },

  computed: {
    standardBikeSeriesId () {
      return this.$route.params.id ? +this.$route.params.id : null
    },

    colorColumns () {
      return [
        { value: 'name', text: 'Name' },
      ]
    },

    frameColumns () {
      return [
        { value: 'name', text: 'Name' },
        { value: 'frameType', text: 'Art' },
        { value: 'frameSize', text: 'Größe' },
      ]
    },
  },

  watch: {
    standardBikeSeriesId (to, from) {
      to && +to !== +from && this.loadSeriesBike()
    },
  },

  mounted () {
    this.setLoadedSeriesBike()
    this.loadSeriesBike()
  },

  beforeRouteLeave (to, from, next) {
    this.routeHandler = next
  },

  methods: {
    /**
     * Sets the dataset to compare to when leaving the page.
     *
     * @returns {void}
     */
    setLoadedSeriesBike () {
      this.loadedSeriesBike = JSON.parse(JSON.stringify(this.seriesBike))
    },

    async loadSeriesBike () {
      if (this.standardBikeSeriesId) {
        const res = await SeriesApi.get(this.standardBikeSeriesId)

        if (res.ok) {
          this.seriesBike = await res.json()
          this.setLoadedSeriesBike()
        }
      }
    },

    /**
     * Updates the currently edited series-bike.
     *
     * @param {Boolean} close Redirect to the overview after saving
     * @returns {void}
     */
    async persistSeriesBike (close) {
      if (!this.formIsValid) {
        return
      }

      const res = await SeriesApi.update(this.seriesBike)

      if (!res.ok) {
        this.$store.commit('setSnackbar', { text: 'Ein Fehler ist aufgetreten', color: 'error' })
        return
      }

      this.$store.commit('setSnackbar', {
        text: 'Serienrad aktualisiert',
        color: 'success'
      })

      if (close) {
        this.setLoadedSeriesBike()
        return this.$router.push({ name: 'AdminSeriesBikesOverview' })
      }

      this.loadSeriesBike()
    },

    /**
     * Uses the base64-content of the given file as current image.
     *
     * @param {File} file File-object to use (from an upload-input)
     * @param {function} Logic to execute after the image has been set
     * @returns {void}
     */
    setImage (file, onSet) {
      if (!file) {
        this.seriesBike = { ...this.seriesBike, image: null }
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.seriesBike = { ...this.seriesBike, image: reader.result }
        typeof onSet === 'function' && onSet()
      }
    },
  },
}
</script>
