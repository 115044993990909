<template>
  <div class="dekor-summary--wrap">
    <h3 class="mb-2">
      Dekor
    </h3>

    <div v-if="dekorConfig && dekorConfig.activeDekorType">
      <v-row>
        <v-col :cols="12" :md="6">
          <v-list dense class="pa-0 mb-5">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Variante
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ dekorConfig.activeDekorType.label }}
                  <span v-if="dekorConfig.activeDekorType.hint">
                    ({{ dekorConfig.activeDekorType.hint }})
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="showDekorColor">
              <v-list-item-content>
                <v-list-item-title>
                  Farbe
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ dekorConfig.color.label }}
                  <v-chip x-small tile :color="dekorConfig.color.colorValue" class="ml-1 px-2" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="mudguardConfig && mudguardConfig.customizeMudguard">
              <v-list-item-content>
                <v-list-item-title>
                  Mit Schutzblechlinien
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div v-if="mudguardConfig.chosenColor">
                    {{ mudguardConfig.chosenColor.label }}
                    <v-chip
                      x-small
                      tile
                      :color="mudguardConfig.chosenColor.colorValue"
                      class="ml-1 px-2"
                    />
                  </div>
                  <div v-else>
                    Keine Farbe gewählt
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col :cols="12" :md="6">
          <v-list v-if="dekorConfig.activeDekorType.hasTextElements" dense>
            <template v-for="(element, i) in dekorConfig.elements">
              <v-list-item v-if="element.chosenText !== ''" :key="i">
                <v-list-item-content>
                  <v-list-item-title>
                    Text {{ element.label }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ element.chosenText }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      ohne
    </div>
  </div>
</template>

<script>
export default {
  name: 'dekor-summary',

  computed: {
    // current dekor-configuration
    dekorConfig () {
      return this.$store.state.bike.dekorConfig
    },

    // current mudguard-configuration
    mudguardConfig () {
      return this.$store.state.bike.mudguardConfig
    },

    // chosen dekor-type allows the user to choose a color, a color was picked
    showDekorColor () {
      const limitation = this.dekorConfig.activeDekorType.colorLimitation
      return this.dekorConfig.color && (limitation === null || (Array.isArray(limitation) && limitation.length))
    },
  },
}
</script>
