<template>
  <div class="admin-models-overview-wrap">
    <div class="action-header">
      <h1>Modellreihen</h1>

      <v-btn :to="{ name: 'ModelDetails' }" depressed>
        <v-icon color="primary" left>
          create
        </v-icon>
        Neue Modellreihe
      </v-btn>
    </div>

    <div class="model-table-wrap">
      <data-table
        :is-loading="isLoading"
        :items="models"
        :columns="columnOptions"
        search-placeholder="Bezeichnung/Kategorie"
        state-key="models"
        @dblclick:row="(e, { item }) => $router.push({ name: 'ModelDetails', params: { id: item.modelSeriesId } })"
      >
        <template v-slot:[`item.image`]="{ item }">
          <component-img
            :id="item.modelSeriesId"
            :has-image="item.hasImage"
            :alt="item.label"
            :height="60"
            :width="100"
            is-model
            class="my-1"
          />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon class="mr-1" @click="modelToRemove = item.modelSeriesId">
            <v-icon small>
              delete
            </v-icon>
          </v-btn>
          <v-btn icon :to="{ name: 'ModelDetails', params: { id: item.modelSeriesId } }">
            <v-icon color="primary" small>
              create
            </v-icon>
          </v-btn>
        </template>
      </data-table>
    </div>

    <base-dialog
      headline="Modellreihe wirklich löschen?"
      :is-visible="modelToRemove !== null"
      @cancel="modelToRemove = null"
      @ok="deleteSelectedModel"
    />
  </div>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'
import ComponentImg from '@/components/ComponentImg'
import DataTable from '@/components/DataTable'
import ModelApi from '@/api/Model'

export default {
  name: 'admin-models-overview',

  components: {
    BaseDialog,
    ComponentImg,
    DataTable,
  },

  data () {
    return {
      search: '',
      models: [],
      modelToRemove: null,
      columnOptions: [
        { value: 'image', text: 'Vorschau', width: 150, sortable: false, filterable: false },
        { value: 'label', text: 'Bezeichnung' },
        { value: 'category', text: 'Kategorie' },
        { value: 'actions', text: 'Aktionen', sortable: false, align: 'right' },
      ],
      isLoading: false,
    }
  },

  mounted () {
    this.getModels()
  },

  methods: {
    /**
     * getModels
     *
     * @returns {void}
     */
    async getModels () {
      this.isLoading = true
      const res = await ModelApi.getAll()
      res.ok && (this.models = await res.json())
      this.isLoading = false
    },

    /**
     * Deletes the currently selected (by pressing the 'delete'-button before
     * confirming the action) model.
     *
     * @returns {void}
     */
    async deleteSelectedModel () {
      if (this.modelToRemove === null) {
        return
      }

      const res = await ModelApi.delete(this.modelToRemove)
      this.modelToRemove = null

      if (res.ok) {
        this.getModels()
        this.$store.commit('setSnackbar', { text: 'Modellreihe gelöscht', color: 'success' })
      } else {
        this.$store.commit('setSnackbar', { text: 'Löschen fehlgeschlagen', color: 'error' })
      }
    },
  },
}
</script>
