<template>
  <div class="reset-button--wrap">
    <v-btn depressed @click="summaryVisible = true">
      <v-icon left>
        table_view
      </v-icon>
      Zusammenfassung
    </v-btn>

    <summary-dialog
      :is-visible="dialogVisible"
      @set:visibility="visibility => dialogVisible = visibility"
    />
  </div>
</template>

<script>
import SummaryDialog from './SummaryDialog'

export default {
  name: 'reset-button',

  components: {
    SummaryDialog,
  },

  data () {
    return {
      dialogVisible: false,
    }
  },
}
</script>
