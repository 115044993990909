export default {
  state: {
    homeBreadcrumb: { text: 'Start', to: '/' },
    breadcrumbItems: []
  },

  getters: {
    breadcrumb (state) {
      return [state.homeBreadcrumb, ...state.breadcrumbItems]
    },
  },

  mutations: {
    setBreadcrumbItems (state, items = []) {
      state.breadcrumbItems = items
    },
  },
}
