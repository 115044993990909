<template>
  <footer>
    <v-container>
      <v-row>
        <v-col :sm="6" :md="3">
          <div class="footer-block">
            Fritz Wittich GmbH <br>
            Werningshof 4 <br>
            33719 Bielefeld <br>
            Germany
          </div>
        </v-col>

        <v-col :sm="6" :md="3">
          <div class="footer-block">
            T +49 521 9320440 <br>
            <a href="mailto:info@wittich-bikes.de">info@wittich-bikes.de</a>
          </div>
        </v-col>

        <v-col :sm="6" :md="3">
          <div class="footer-block text-md-center">
            <a href="http://www.facebook.com/WMFRad" target="_blank" rel="noopener">
              <img class="social-icon" :src="require('@/assets/images/icon_facebook.png')">
            </a>
          </div>
        </v-col>

        <v-col :sm="6" :md="3">
          <nav class="footer-block">
            <ul>
              <li>
                <router-link to="/agb">
                  AGB
                </router-link>
              </li>
              <li>
                <router-link to="/impressum">
                  Impressum
                </router-link>
              </li>
              <li>
                <router-link to="/datenschutz">
                  Datenschutz
                </router-link>
              </li>
            </ul>
          </nav>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: 'app-footer',
}
</script>

<style lang="scss">
  footer {
    font-family: 'Roboto Mono';
    font-size: 14px;
    font-style: normal;
    color: #fff;
    background-color: #000;
    padding: 1rem 2rem;

    .social-icon {
      width: 40px;
      border-radius: 4px;
      transition: border-radius 120ms cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        border-radius: 50%;
      }
    }

    .footer-block {
      ul {
        list-style: none;
        padding: 0;
      }

      p {
        margin: 0;
      }

      a {
        color: #fff;
        text-decoration: underline;

        &:hover {
          color: lighten($color: blue, $amount: 20)
        }
      }
    }
  }

  @media (min-width: 960px) {
    footer {
      .footer-block {
        padding: 2rem 0;
        border: none;
      }
    }
  }
</style>
