<template>
  <div class="buy-box-wrap">
    <v-btn
      depressed
      color="primary"
      :loading="pricesLoading || buyInProgress"
      :disabled="pricesLoading || buyInProgress"
      @click="dialogVisible = true"
    >
      <v-icon left>
        shopping_cart
      </v-icon>
      {{ currentPrice | centsToEuro }}<sup>*</sup>
      <v-divider vertical class="mx-2" dark />
      Bestellen
    </v-btn>

    <summary-dialog
      with-buy-option
      :is-visible="dialogVisible"
      @buy="onBuy"
      @set:visibility="visibility => dialogVisible = visibility"
    />
  </div>
</template>

<script>
import OrderApi from '@/api/Order'
import SummaryDialog from './Summary/SummaryDialog'

export default {
  name: 'buy-box',

  components: {
    SummaryDialog,
  },

  data () {
    return {
      dialogVisible: false,
      buyInProgress: false,
    }
  },

  computed: {
    // the complete configuration of the current bike
    bike () {
      return this.$store.state.bike
    },

    currentPrice () {
      return this.$store.state.prices[this.user.showCustomerPrice ? 'customerPrice' : 'retailerPrice']
    },

    pricesLoading () {
      return this.$store.state.prices.pricesLoading
    },

    user () {
      return this.$store.state.user.user
    },
  },

  methods: {
    /**
     * onBuy
     *
     * @returns {void}
     */
    async onBuy () {
      this.buyInProgress = true
      this.buyDialogVisible = false

      const res = await OrderApi.create(this.bike)
      this.buyInProgress = false

      if (!res.ok) {
        return this.$store.commit('setSnackbar', { text: 'Ein Fehler ist aufgetreten', color: 'error' })
      }

      this.$router.push({ name: 'AfterBuy' })
    },
  }
}
</script>
