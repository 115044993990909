const getDefaultState = () => {
  return {
    model: null,
    frame: null,
    dekorConfig: {
      activeDekorType: null,
      chosenFont: 'Arial',
      color: null,
      elements: [
        { label: 'Unterrohr', chosenText: '', componentType: 'Rahmen' },
        { label: 'Sattelrohr', chosenText: '', componentType: 'Rahmen' },
        { label: 'Steuerkopf', chosenText: '', componentType: 'Rahmen' },
        { label: 'Hinterbau oben', chosenText: '', componentType: 'Rahmen' },
        { label: 'Hinterbau unten', chosenText: '', componentType: 'Rahmen' },
      ],
    },
    mudguardConfig: {
      customizeMudguard: false,
      chosenColor: null,
    },
    frameConfig: [],
    slots: [],
  }
}

export default {
  state: getDefaultState(),

  getters: {
    /**
     * Unique component-colors picked by the user.
     *
     * @param {object} state
     * @returns {array}
     */
    pickedColors (state) {
      return state.slots.reduce((colors, slot) => {
        if (!slot.pickedComponent || !slot.pickedComponent.pickedColor) {
          return colors
        }

        return colors.find(color => color.colorId === slot.pickedComponent.pickedColor.colorId) !== undefined
          ? colors
          : [...colors, slot.pickedComponent.pickedColor]
      }, [])
    },
  },

  mutations: {
    /**
     * Replaces the mudguard-configuration with the given one.
     *
     * @param {object} state
     * @param {object} mudguardConfig
     * @returns {void}
     */
    updateMudguardConfig (state, mudguardConfig) {
      state.mudguardConfig = { ...state.mudguardConfig, ...mudguardConfig }
    },

    /**
     * Updates an entry of the dekor-configuration.
     *
     * @param {object} state
     * @param {object} data
     * @param {string} data.key
     * @param {any} data.value
     * @returns {void}
     */
    updateDekor (state, { key, value }) {
      state.dekorConfig[key] = value
    },

    /**
     * Sets the slots to use.
     *
     * @param {object} state
     * @param {array} slots
     * @returns {void}
     */
    setSlots (state, slots) {
      state.slots = slots
    },

    /**
     * Sets the frame to use.
     *
     * @param {object} state
     * @param {number} frame
     * @returns {void}
     */
    setFrame (state, frame) {
      state.frame = frame
    },

    /**
     * Sets the model to use.
     *
     * @param {object} state
     * @param {number} model
     * @returns {void}
     */
    setModel (state, model) {
      state.model = model
    },

    /**
     * Sets a full configuration, replaces every item of the current state.
     *
     * @param {object} state
     * @param {number} configuration
     * @returns {void}
     */
    setConfiguration (state, configuration) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, configuration)
    },

    /**
     * Resets the state.
     *
     * @param {object} state
     * @returns {void}
     */
    resetConfiguration (state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState())
    },

    /**
     * Sets the frame-option to use for its option-category.
     *
     * @param {object} state
     * @param {object} pickedOption
     * @returns {void}
     */
    pickFrameOption (state, pickedOption) {
      const currentOptionIndex = state.frameConfig.findIndex(option =>
        option.optionCategoryId === pickedOption.optionCategoryId
      )

      currentOptionIndex === -1
        ? state.frameConfig = [...state.frameConfig, pickedOption]
        : state.frameConfig.splice(currentOptionIndex, 1, pickedOption)
    },
  },
}
