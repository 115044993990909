<template>
  <div>
    <v-tabs v-model="activeTab" grow class="mb-5" background-color="transparent" color="grey darken-4">
      <v-tab>Standard</v-tab>
      <v-tab v-if="withRalColors">
        RAL
      </v-tab>
      <v-tab v-if="showChoiceTab">
        Auswahl ({{ pickedColors.length }})
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-list dense>
          <v-list-item
            v-for="color in defaultColors"
            :key="color.colorValue"
            :input-value="value && value.colorId === color.colorId"
            @click="() => $emit('input', color)"
          >
            <v-list-item-avatar :color="color.colorValue" :size="20" rounded />
            <v-list-item-content>
              <v-list-item-title>{{ color.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-tab-item>
      <v-tab-item v-if="withRalColors">
        <div class="pa-1">
          <v-alert color="primary" dense border="left" dark>
            Aufpreis pro RAL-Farbe: {{ ralPrice }}€
          </v-alert>
          <v-expansion-panels v-model="activePanel">
            <v-expansion-panel v-for="(ralColorGroup, index) in ralColorGroups" :key="index">
              <v-expansion-panel-header>
                {{ ralColorGroup.group }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list dense>
                  <v-list-item
                    v-for="(color, i) in ralColorGroup.colors"
                    :key="`color_${i}`"
                    @click="() => $emit('input', color)"
                  >
                    <v-list-item-avatar :color="color.colorValue" :size="20" rounded />
                    <v-list-item-content>
                      <v-list-item-title>{{ color.label }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-tab-item>
      <v-tab-item v-if="showChoiceTab">
        <v-list dense>
          <v-list-item v-for="color in pickedColors" :key="color.colorValue" @click="() => $emit('input', color)">
            <v-list-item-avatar :color="color.colorValue" :size="20" rounded />
            <v-list-item-content>
              <v-list-item-title>{{ color.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'color-picker',

  props: {
    allowChoiceTab: {
      type: Boolean,
      default: true,
    },

    colorLimitation: {
      type: Array,
      default: null,
    },

    value: {
      type: Object,
      default: null,
    },

    withRalColors: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      activeTab: 0,
      activePanel: null,
    }
  },

  computed: {
    // available, allowed colors to choose from
    colors () {
      return this.getAllowedColors(this.$store.state.options.colors)
    },

    // colors are divided into two categories: default- and ral-colors
    defaultColors () {
      return this.colors.filter(color => color.isDefaultColor)
    },

    // unique component-colors picked by the user
    pickedColors () {
      return this.$store.getters.pickedColors
    },

    // ral-colors by their color-group (e.g 'Orange')
    ralColorGroups () {
      return this.getRalColorsByGroup(this.colors)
    },

    // price per chosen ral-color
    ralPrice () {
      return (this.$store.state.prices.ralPrice / 100).toFixed(2)
    },

    // show an additional tab with already chosen colors
    showChoiceTab () {
      return this.pickedColors.length && this.allowChoiceTab
    }
  },

  methods: {
    /**
     * Checks which of the given colors are allowed by the current limitation.
     *
     * @param {array} colors
     * @returns {array}
     */
    getAllowedColors (colors = []) {
      return colors.filter(({ colorId }) => {
        if (this.colorLimitation === null) return true
        return this.colorLimitation.length ? this.colorLimitation.includes(colorId) : false
      })
    },

    /**
     * Checks which of the given colors are ral-variants and groups those based
     * on their color-group (e.g. 'Orange')
     *
     * @param {array} colors
     * @returns {array}
     */
    getRalColorsByGroup (colors = []) {
      return colors
        .filter(color => !color.isDefaultColor)
        .reduce((groups, color) => groups.find(g => g.group === color.colorGroup)
          ? groups
          : [...groups, {
            group: color.colorGroup,
            colors: this.colors.filter(c => c.colorGroup === color.colorGroup && !c.isDefaultColor),
          }]
        , [])
    },
  },
}
</script>

<style lang="scss" scoped>
  .v-avatar {
    // border gets set inline
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  .v-tabs-items {
    background-color: transparent;
  }
</style>
