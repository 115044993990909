<template>
  <div class="registration-wrap">
    <h2>
      Registrierung abschließen
    </h2>

    <form class="mb-10" @submit.prevent="createUser">
      <v-row>
        <v-col :md="6">
          <v-text-field
            v-model="user.firstName"
            prepend-inner-icon="face"
            hide-details
            outlined
            label="Vorname"
            class="mb-6"
            required
          />

          <v-text-field
            v-model="user.lastName"
            prepend-inner-icon="family_restroom"
            hide-details
            outlined
            label="Nachname"
            class="mb-6"
            required
          />

          <v-text-field
            v-model="user.email"
            prepend-inner-icon="email"
            hide-details
            outlined
            label="E-Mail"
            class="mb-6"
            required
            type="email"
          />
        </v-col>

        <v-col :md="6">
          <v-text-field
            v-model="user.password"
            prepend-inner-icon="lock"
            hide-details
            outlined
            label="Neues Passwort"
            required
            class="mb-6"
            type="password"
          />

          <v-text-field
            v-model="user.passwordConfirmation"
            prepend-inner-icon="lock"
            hide-details
            outlined
            label="Neues Passwort (Wiederholung)"
            required
            class="mb-6"
            type="password"
            :error="user.password !== user.passwordConfirmation"
          />
        </v-col>
      </v-row>

      <div class="d-flex justify-end mt-15">
        <v-btn type="submit" color="primary" depressed>
          Registrieren
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import { baseUrl, baseHeader } from '@/api/Api'

export default {
  name: 'registration',

  components: {
  },

  data () {
    return {
      user: {
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirmation: ''
      }
    }
  },

  computed: {
    companyId () {
      return this.$route.params.companyId
    },
    token () {
      return this.$route.params.token
    },
  },

  mounted () {
    this.companyId && this.token && this.getUserData()
    this.$store.commit('setBreadcrumbItems', [{ text: 'Registrierung abschließen', disabled: true }])
  },

  methods: {
    async createUser () {
      if (this.user.password !== this.user.passwordConfirmation) {
        this.$store.commit('setSnackbar', { text: 'Unterschiedliche Passwörter', color: 'error' })
        return
      }

      const res = await fetch(`${baseUrl}/company/${this.companyId}/user/register/${this.token}`, {
        method: 'POST',
        headers: baseHeader,
        body: JSON.stringify(this.user),
      })

      if (res.ok) {
        this.$store.commit('setUser', await res.json())
        this.$store.commit('setSnackbar', { text: 'Registrierung erfolgreich', color: 'success' })
        this.$router.push({ name: 'AccountOverview' })
      } else {
        this.$store.commit('setSnackbar', { text: 'Registrierung fehlgeschlagen', color: 'error' })
      }
    },

    async getUserData () {
      const res = await fetch(`${baseUrl}/company/${this.companyId}/user/invite/${this.token}`, {
        headers: baseHeader,
      })

      if (res.status === 404) {
        this.$store.commit('setSnackbar', { text: 'Ungültiges Token', color: 'error' })
        this.$router.push({ name: 'Home' })
      }

      if (res.ok) {
        const user = await res.json()
        this.user = { ...this.user, ...user }
      }
    },
  },
}
</script>
