<template>
  <div class="admin-series-overview-wrap">
    <div class="action-header">
      <h1>Serienräder</h1>
    </div>

    <div class="company-table-wrap">
      <data-table
        :is-loading="isLoading"
        :items="series"
        :columns="columnOptions"
        :default-options="{
          sortBy: ['title'],
          sortDesc: [false]
        }"
        state-key="series"
        search-placeholder="ID/Name"
        @dblclick:row="(e, { item }) => $router.push({ name: 'AdminSeriesBikeDetails', params: { id: item.standardBikeSeriesId } })"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon :to="{ name: 'AdminSeriesBikeDetails', params: { id: item.standardBikeSeriesId } }">
            <v-icon color="primary" small>
              create
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <v-checkbox v-model="item.active" disabled />
        </template>

        <template v-slot:[`item.hasImage`]="{ item }">
          <v-checkbox v-model="item.hasImage" disabled />
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import SeriesApi from '@/api/Series'
import DataTable from '@/components/DataTable'

export default {
  name: 'admin-series-overview',

  components: {
    DataTable,
  },

  data () {
    return {
      search: '',
      series: [],
      columnOptions: [
        { value: 'modelCode', text: 'Code', width: 110 },
        { value: 'title', text: 'Name' },
        { value: 'active', text: 'Aktiv', width: 100 },
        { value: 'hasImage', text: 'Bild', width: 100 },
        { value: 'actions', text: 'Aktionen', sortable: false, align: 'right', width: 100 },
      ],
      isLoading: false,
    }
  },

  mounted () {
    this.getSeries()
  },

  methods: {
    /**
     * getSeries
     *
     * @returns {void}
     */
    async getSeries () {
      this.isLoading = true
      const res = await SeriesApi.getAll()
      res.ok && (this.series = await res.json())
      this.isLoading = false
    },
  },
}
</script>
