<template>
  <div class="home-wrap">
    <div class="slider-wrap mb-10">
      <v-carousel cycle show-arrows-on-hover hide-delimiter-background :interval="4000" height="auto">
        <v-carousel-item class="slide">
          <v-img :src="require('@/assets/images/slides/banner_home01.jpeg')" />
        </v-carousel-item>
        <v-carousel-item class="slide">
          <v-img :src="require('@/assets/images/slides/banner_home02.jpeg')" />
        </v-carousel-item>
      </v-carousel>
    </div>

    <div class="content-links px-lg-15 mb-10">
      <v-row class="mb-2">
        <v-col :sm="6">
          <v-card :ripple="{ class: 'white--text' }" to="/konfigurator">
            <v-img :src="require('@/assets/images/weiche_konfigurator.jpeg')" />
          </v-card>
        </v-col>
        <v-col :sm="6">
          <v-card :ripple="{ class: 'white--text' }" to="/galerie">
            <v-img :src="require('@/assets/images/weiche_galerie.jpeg')" />
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col :lg="6">
          <v-card class="fill-height link-box" to="/katalog">
            <v-card-title>Katalog</v-card-title>
            <v-card-text>
              <p>
                Unser aktueller Bike-Katalog 2018 mit unseren Kollektionen als Download oder als digitale Version zum Blättern.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :lg="6">
          <v-card class="fill-height link-box" to="/dokumente">
            <v-card-title>Downloads</v-card-title>
            <v-card-text>
              <p>
                Gebrauchsanweisungen, Angebote und weitere Dokumente finden Sie in unserem Downloadbereich.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
}
</script>

<style lang="scss">
  .home-wrap {
    .slider-wrap {
      img {
        pointer-events: none;
      }
    }

    .content-links {
      .link-box {
        h2 {
          font-weight: 700;
          margin-bottom: 1.25rem;
        }
      }
    }
  }
</style>
