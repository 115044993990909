<template>
  <div class="account-overview-wrap">
    <user-form
      class="mb-10"
      :user="user"
      @submit:user="updateUser"
      @submit:password="changePassword"
    />
  </div>
</template>

<script>
import UserApi from '@/api/User'
import UserForm from '@/components/UserForm'

export default {
  name: 'account-overview',

  components: {
    UserForm
  },

  computed: {
    user () {
      return this.$store.state.user.user
    }
  },

  methods: {
    async updateUser ({ email = '', firstName = '', lastName = '' }) {
      const res = await UserApi.update({ ...this.user, email, firstName, lastName })

      if (!res.ok) {
        return this.$store.commit('setSnackbar', { text: 'Aktualisierung gescheitert', color: 'error' })
      }

      this.$store.dispatch('getUser')
      this.$store.commit('setSnackbar', { text: 'Daten aktualisiert', color: 'success' })
    },

    async changePassword ({ oldPassword = '', newPassword = '' }) {
      const res = await UserApi.setPassword({ oldPassword, newPassword })

      if (res.ok) {
        this.$store.commit('setSnackbar', { text: 'Passwort geändert', color: 'success' })
        return
      }

      const { error } = await res.json()

      this.$store.commit('setSnackbar', {
        text: error === 'wrong password' ? 'Falsches Passwort' : 'Änderung fehlgeschlagen',
        color: 'error',
      })
    },
  },
}
</script>
