export default {
  state: {
    companies: { options: null, search: '' },
    components: { options: null, search: '', filter: '' },
    models: { options: null, search: '' },
    series: { options: null, search: '' },
    optionCategories: { options: null, search: '', filter: '' },
  },

  mutations: {
    updateFilter (state, { key, filter = '' }) {
      state[key] = { ...state[key], filter }
    },

    updateSearch (state, { key, search = '' }) {
      state[key] = { ...state[key], search }
    },

    updateOptions (state, { key, options = null }) {
      state[key] = { ...state[key], options }
    },
  },
}
