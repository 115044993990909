<template>
  <div class="user-company--wrap">
    <h1>Firma: {{ company.label }}</h1>

    <h2>Daten</h2>

    <v-row>
      <v-col :lg="6">
        <v-text-field
          v-model="company.label"
          outlined
          prepend-inner-icon="business"
          label="Firmenname"
          disabled
        />

        <v-text-field
          v-model="company.phoneNumber"
          outlined
          prepend-inner-icon="phone"
          label="Telefonnummer"
          disabled
        />
        <v-text-field
          v-model="company.customerNumber"
          outlined
          prepend-inner-icon="fingerprint"
          label="Kundennummer"
          disabled
        />
        <v-text-field
          v-model="company.contactPerson"
          outlined
          prepend-inner-icon="mail"
          label="Zuständiger Außendienstler"
          disabled
        />
        <v-row>
          <v-col>
            <v-text-field
              :value="company.surchargeFactor.toFixed(2)"
              outlined
              label="Aufschlagsfaktor"
              type="number"
              disabled
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col :lg="6">
        <v-text-field
          v-model="company.addressLine1"
          outlined
          prepend-inner-icon="edit_road"
          label="Adresszeile 1"
          disabled
        />
        <v-text-field
          v-model="company.addressLine2"
          outlined
          prepend-inner-icon="edit_road"
          label="Adresszeile 2"
          disabled
        />
        <v-text-field
          v-model="company.postCode"
          outlined
          prepend-inner-icon="location_city"
          label="PLZ"
          disabled
        />
        <v-text-field
          v-model="company.city"
          outlined
          prepend-inner-icon="location_city"
          label="Stadt"
          disabled
        />
      </v-col>
    </v-row>

    <div v-if="companyId">
      <v-divider class="my-10" />
      <user-list :company-id="companyId" readonly />
    </div>
  </div>
</template>

<script>
import CompanyApi from '@/api/Company'
import UserList from '@/components/UserList'

export default {
  name: 'admin-company',

  components: {
    UserList,
  },

  data () {
    return {
      company: {
        label: '',
        customerNumber: '',
        addressLine1: '',
        addressLine2: '',
        postCode: '',
        city: '',
        phoneNumber: '',
        contactPerson: '',
        surchargeFactor: 1,
      },
    }
  },

  computed: {
    companyId () {
      return this.$route.params.id ? +this.$route.params.id : null
    },
  },

  watch: {
    companyId (to, from) {
      to && +to !== +from && this.loadCompany()
    },
  },

  async mounted () {
    await this.loadCompany()
    this.$store.commit('setBreadcrumbItems', [{ text: this.company.label, disabled: true }])
  },

  methods: {
    /**
     * Loads the full dataset of the company related to the id given as a url-
     * parameter.
     */
    async loadCompany () {
      if (this.companyId) {
        const res = await CompanyApi.get(this.companyId)

        if (res.ok) {
          this.company = await res.json()
        }

        if (res.status === 401) {
          this.$router.push({ name: 'AccountOverview' })
        }
      }
    },
  },
}
</script>
