import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl: `${baseUrl}/component`,
  baseHeader,

  get (componentId) {
    return fetch(`${this.baseUrl}/${componentId}`, {
      headers: this.baseHeader,
    })
  },

  /**
   * getAll
   *
   * @returns {Promise[]}
   */
  getAll () {
    return fetch(this.baseUrl, { headers: this.baseHeader })
  },

  /**
   * create
   *
   * @returns {Promise[]}
   */
  create (component) {
    return fetch(this.baseUrl, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify(component),
    })
  },

  /**
   * delete
   *
   * @param {number} componentId ID of the component to remove
   * @returns {Promise[]}
   */
  delete (componentId) {
    return fetch(`${this.baseUrl}/${componentId}`, {
      method: 'DELETE',
      headers: this.baseHeader,
    })
  },

  /**
   * update
   *
   * @param {object} component Component to update
   * @returns {Promise[]}
   */
  update (component) {
    if (!component || !Object.keys(component).length) {
      return false
    }

    return fetch(`${this.baseUrl}/${component.componentId}`, {
      method: 'PUT',
      headers: this.baseHeader,
      body: JSON.stringify(component),
    })
  },

  /**
   * getTypes
   *
   * @returns {Promise[]}
   */
  getTypes () {
    return fetch(`${this.baseUrl}/types`, { headers: this.baseHeader })
  },

  /**
   * getRelatedModels
   *
   * @param {number} componentId
   * @returns {Promise[]}
   */
  getRelatedModels (componentId) {
    return fetch(`${this.baseUrl}/${componentId}/modelSeries`, {
      headers: this.baseHeader,
    })
  },
}
