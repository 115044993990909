import UserApi from '@/api/User'

export default {
  state: {
    user: null,
    userChecked: false,
  },

  getters: {
    userCanEditCompanies (state) {
      return state.user && state.user.permissions && state.user.permissions.includes('ManageCompaniesAndUsers')
    },

    userCanEditComponents (state) {
      return state.user && state.user.permissions && state.user.permissions.includes('ManageComponents')
    },

    userCanManageSeries (state) {
      return state.user && state.user.permissions && state.user.permissions.includes('ManageSeries')
    },

    userCanSwitchPrices (state) {
      return state.user && state.user.permissions && state.user.permissions.includes('SwitchPrices')
    },

    userHasSomeAdminRole (state, getters) {
      return getters.userCanEditCompanies ||
        getters.userCanEditComponents ||
        getters.userCanManageSeries ||
        getters.userCanSwitchPrices
    }
  },

  mutations: {
    setUser (state, user) {
      state.user = user
      state.userChecked = true
    },
  },

  actions: {
    async getUser ({ commit }, callback) {
      const res = await UserApi.get()
      const user = res.ok ? await res.json() : null

      commit('setUser', user)
      typeof callback === 'function' && callback()
    }
  },
}
