<template>
  <div class="account-configurations-wrap">
    <div class="configuration-table-wrap">
      <h2>Meine Konfigurationen</h2>

      <data-table
        :items="configurations"
        :columns="columnOptions"
        search-placeholder="Suche"
        class="mb-15"
      >
        <template v-slot:[`item.visibleForCompany`]="{ item }">
          <v-switch
            :input-value="item.visibleForCompany"
            inset
            @change="visibleForCompany => togglePublic(visibleForCompany, item)"
          />
        </template>
        <template v-slot:[`item.privateActions`]="{ item }">
          <v-icon class="mr-4" @click="entryToRemove = item.bookmarkId">
            delete
          </v-icon>
          <v-icon color="primary" @click="showConfigurator(item)">
            refresh
          </v-icon>
        </template>
      </data-table>

      <h2>Öffentliche Konfigurationen</h2>

      <data-table
        :items="publicConfigurations"
        :columns="publicColumnOptions"
        search-placeholder="Suche"
        class="mb-10"
        @dblclick:row="(e, item) => showConfigurator(item.item)"
        @edit="showConfigurator"
        @delete="item => entryToRemove = item.bookmarkId"
      >
        <template v-slot:[`item.publicActions`]="{ item }">
          <v-icon color="primary" @click="showConfigurator(item)">
            refresh
          </v-icon>
        </template>
      </data-table>
    </div>

    <base-dialog
      headline="Konfiguration wirklich löschen?"
      :is-visible="entryToRemove !== null"
      @cancel="entryToRemove = null"
      @ok="deleteSelectedConfigurataion"
    />
  </div>
</template>

<script>
import BookmarkApi from '@/api/Bookmark'
import BaseDialog from '@/components/BaseDialog'
import DataTable from '@/components/DataTable'

export default {
  name: 'account-configurations',

  components: {
    BaseDialog,
    DataTable,
  },

  data () {
    return {
      search: '',
      configurations: [],
      publicConfigurations: [],
      columnOptions: [
        { value: 'label', text: 'Bezeichnung' },
        { value: 'visibleForCompany', text: 'Öffentlich' },
        { value: 'privateActions', text: 'Aktionen', sortable: false, align: 'right' },
      ],
      publicColumnOptions: [
        { value: 'label', text: 'Bezeichnung' },
        { value: 'publicActions', text: 'Aktionen', sortable: false, align: 'right' },
      ],
      entryToRemove: null,
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    }
  },

  mounted () {
    this.loadConfigurations()
    this.$store.commit('setBreadcrumbItems', [{ text: 'Konfigurationen', disabled: true }])
  },

  methods: {
    /**
     * Loads configurations of the current user.
     *
     * @returns {void}
     */
    async loadConfigurations () {
      const res = await BookmarkApi.getAll()

      if (res.ok) {
        const configurations = await res.json()
        this.configurations = configurations.filter(c => c.userId === this.user.userId)
        this.publicConfigurations = configurations.filter(c => c.visibleForCompany)
      }
    },

    showConfigurator (entry) {
      const { model, frame } = entry.configuration

      this.$router.push({
        name: 'BikeConfigurator',
        query: { model, frame, bookmark: entry.bookmarkId },
      })
    },

    /**
     * Deletes the currently selected (by pressing the 'delete'-button before
     * confirming the action) configuration.
     *
     * @returns {void}
     */
    async deleteSelectedConfigurataion () {
      if (this.entryToRemove === null) {
        return
      }

      const res = await BookmarkApi.delete(this.entryToRemove)
      this.entryToRemove = null

      if (res.ok) {
        this.loadConfigurations()
        return this.$store.commit('setSnackbar', { text: 'Eintrag entfernt', color: 'success' })
      }

      this.$store.commit('setSnackbar', { text: 'Ein Fehler ist aufgetreten', color: 'error' })
    },

    async togglePublic (isPublic, item) {
      const bookmark = { ...item, visibleForCompany: isPublic }
      const res = await BookmarkApi.update(bookmark)

      await this.loadConfigurations()

      this.$store.commit('setSnackbar', {
        text: res.ok ? 'Eintrag aktualisiert' : 'Ein Fehler ist aufgetreten',
        color: res.ok ? 'success' : 'error',
      })
    },
  },
}
</script>
