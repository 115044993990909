<template>
  <div class="bike-base--wrap">
    <h2>Konfigurator-Vorschau</h2>
    <v-row>
      <v-col :lg="4">
        <v-list class="slot-list">
          <v-list-item v-for="slot in imageSlotsWithPositions" :key="slot.slotId">
            <v-list-item-content>
              <div class="d-flex align-center">
                {{ slot.componentType.label }}
                <v-chip v-if="slot.position !== null" x-small class="px-2 ml-2">
                  #{{ slot.position }}
                </v-chip>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small icon class="mr-2" @click="moveSlotDown(slot)">
                <v-icon>arrow_downward</v-icon>
              </v-btn>
              <v-btn small icon class="mr-2" @click="moveSlotUp(slot)">
                <v-icon>arrow_upward</v-icon>
              </v-btn>
              <v-checkbox
                :input-value="slotToEdit && slotToEdit.slotId === slot.slotId"
                @change="toggleSlot(slot)"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col :lg="8">
        <div ref="canvasWrap" class="config-canvas-wrap">
          <canvas ref="configCanvas" width="1296" height="900" />
        </div>
      </v-col>
    </v-row>

    <v-sheet class="my-10">
      <v-btn depressed small class="mb-5" @click="canvasToJson">
        Konfiguration zu JSON umwandeln
      </v-btn>
      <pre style="max-height: 400px; overflow-y: auto;">{{ json }}</pre>
    </v-sheet>
  </div>
</template>

<script>
import { fabric } from 'fabric'

import SampleBike from '@/assets/data/bike-base.json'

const DATA_KEY = 'customData'

export default {
  name: 'bike-base',

  props: {
    slots: {
      type: Array,
      default: () => ([])
    },
  },

  data () {
    return {
      json: SampleBike,
      configCanvas: null,
      slotToEdit: null,
    }
  },

  computed: {
    loadedImages () {
      return this.configCanvas ? this.configCanvas.getObjects() : []
    },

    imageSlotsWithPositions () {
      return this.slots
        .map(slot => ({ ...slot, position: this.getSlotPosition(slot) }))
        .filter(slot => slot.position !== null)
        .sort((a, b) => a.componentType.label.localeCompare(b.componentType.label))
    },
  },

  watch: {
    slots () {
      this.configCanvas === null && this.slots.length && this.initCanvas()
    },
  },

  mounted () {
    this.slots.length && this.initCanvas()
  },

  /**
   * created
   *
   * @returns {void}
   */
  created () {
    window.addEventListener('resize', this.resizeCanvas)
    window.addEventListener('keydown', this.moveActiveImage)
  },

  /**
   * destroyed
   *
   * @returns {void}
   */
  destroyed () {
    window.removeEventListener('resize', this.resizeCanvas)
    window.removeEventListener('keydown', this.moveActiveImage)
  },

  methods: {
    initCanvas () {
      fabric.Object.NUM_FRACTION_DIGITS = 8

      this.configCanvas = new fabric.Canvas(this.$refs.configCanvas, { preserveObjectStacking: true })

      this.fileToConfigCanvas(() => {
        this.configCanvas.renderAll()
        this.resizeCanvas()
      })
    },

    /**
     * Scales elements of the canvas if it has a size that differs from the
     * default one (e.g when resizing).
     *
     * @returns {void}
     */
    resizeCanvas () {
      const defaultWidth = 1296
      const defaultHeight = 900
      const availableWidth = this.$refs.canvasWrap.offsetWidth
      const scaling = availableWidth / defaultWidth

      this.configCanvas.setZoom(scaling)
      this.configCanvas.setDimensions({
        width: defaultWidth * scaling,
        height: defaultHeight * scaling,
      })
    },

    fileToConfigCanvas (afterLoad) {
      // this.configCanvas.renderAll()
      this.configCanvas.loadFromJSON(this.json, () => {
        this.loadedImages.forEach(image => {
          this.lockImage(image)
          const relatedSlot = this.slots.find(slot =>
            image[DATA_KEY] && slot.componentType.componentType === image[DATA_KEY].componentType
          )

          image.opacity = relatedSlot && relatedSlot.active ? 1 : 0
        })

        typeof afterLoad === 'function' && afterLoad()
      })
    },

    /**
     * Adds the image from the given path to the canvas, stores the dataset
     * with that.
     *
     * @param {string} imagePath Path to the image to add
     * @param {any} data Dataset to store with the image-instance
     */
    addImageToCanvas (imagePath, data) {
      fabric.Image.fromURL(imagePath, img => {
        img.scaleToWidth(this.$refs.configCanvas.offsetWidth / 2)
        img.set(DATA_KEY, data)
        data.component.color && this.colorizeImage(img, data.component.color)
        this.lockImage(img)

        this.loadedImages.push(img)
        this.configCanvas.add(img)
      })
    },

    /**
     * colorizeImage
     *
     * @param {object} image fabric.js-image
     * @param {string} color hex-color to use
     * @param {number} alpha alpha to use for the color-overlay
     */
    colorizeImage (image, color, alpha = 1) {
      image.filters = [
        new fabric.Image.filters.BlendColor({
          color: color,
          mode: 'tint',
          alpha: alpha,
        }),
      ]

      image.applyFilters()
    },

    /**
     * Locks the given image so the user can't interact with it.
     * If the related component isn't the default-one of the slot, the image gets hidden.
     *
     * @param {object} image fabric-image to lock
     * @returns {void}
     */
    lockImage (image) {
      this.configCanvas.discardActiveObject()

      image.set('selectable', false)
      image.set('lockMovementX', true)
      image.set('lockMovementY', true)
      image.set('hoverCursor', 'default')

      image.originalIndex !== undefined && image.moveTo(image.originalIndex)
      image.originalIndex = undefined

      this.configCanvas.renderAll()
    },

    unlockImage (image) {
      image.set('selectable', true)
      image.set('lockMovementX', false)
      image.set('lockMovementY', false)
      image.set('opacity', 1)
      image.set('active', true)
      image.set('hoverCursor', 'move')

      image.originalIndex = this.loadedImages.indexOf(image)
      image.bringToFront()

      this.configCanvas.setActiveObject(image)
      this.configCanvas.renderAll()
    },

    toggleSlot (slotToToggle) {
      const relatedImages = this.canvasImagesBySlot(slotToToggle)

      if (this.slotToEdit && this.slotToEdit.slotId === slotToToggle.slotId) {
        this.slotToEdit = null
        relatedImages.forEach(this.lockImage)
      } else {
        this.slotToEdit = slotToToggle
        relatedImages.forEach(this.unlockImage)
      }

      this.configCanvas.renderAll()
    },

    moveSlotDown (slot) {
      const position = this.getSlotPosition(slot)
      this.canvasImagesBySlot(slot).forEach(img => img.moveTo(position - 1))

      this.configCanvas.renderAll()
    },

    moveSlotUp (slot) {
      this.canvasImagesBySlot(slot).forEach(img => img.bringForward())

      // this.canvasImagesBySlot(slot).forEach((img) => {
      //   console.log('pos now: ', this.loadedImages.indexOf(img))

      //   this.$nextTick(() => {
      //     console.log('pos then: ', this.loadedImages.indexOf(img))
      //   })
      // })

      this.configCanvas.renderAll()
    },

    moveActiveImage (e) {
      const image = this.configCanvas.getActiveObject()

      if (!image || !['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        return
      }

      e.preventDefault()

      switch (e.key) {
        case 'ArrowLeft': image.left = image.left - 1; break
        case 'ArrowRight': image.left = image.left + 1; break
        case 'ArrowUp': image.top = image.top - 1; break
        case 'ArrowDown': image.top = image.top + 1; break
      }

      this.configCanvas.renderAll()
    },

    canvasToJson () {
      const json = this.configCanvas.toJSON([DATA_KEY])
      const objects = json.objects.map(image => {
        return { ...image, src: image.src.replace(`${window.location.origin}/`, '') }
      })

      this.json = { ...json, objects }
    },

    canvasImagesBySlot (slot) {
      return slot ? this.loadedImages.filter(image =>
        image[DATA_KEY] && image[DATA_KEY].componentType === slot.componentType.componentType
      ) : []
    },

    getSlotPosition (slot) {
      const images = this.canvasImagesBySlot(slot)
      return images.length ? this.loadedImages.indexOf(images[0]) : null
    },
  },
}
</script>

<style lang="scss">
  .bike-base--wrap {
    .slot-list {
      .v-list-item__action {
        &.v-list-item__action--stack {
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
</style>
