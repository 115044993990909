<template>
  <div v-if="frameConfig.length" class="frame-option-summary--wrap">
    <h3 class="mb-2">
      Rahmen-Optionen
    </h3>

    <div class="d-flex flex-wrap">
      <v-chip v-for="option in frameConfig" :key="option.id" class="mr-4 mb-2">
        {{ option.optionCategoryLabel }}: {{ option.label }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'frame-option-summary',

  computed: {
    frameConfig () {
      return this.$store.state.bike.frameConfig
    },
  }
}
</script>

<style>

</style>
