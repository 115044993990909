<template>
  <v-app-bar
    app
    clipped-left
    clipped-right
    color="white"
    :elevation="1"
    :elevate-on-scroll="user !== null && userHasSomeAdminRole"
    light
    :scroll-threshold="1"
  >
    <v-container class="py-0 fill-height">
      <v-toolbar-title>
        <router-link to="/">
          <v-img class="logo" :src="require('@/assets/images/logo_wittich.png')" />
        </router-link>
      </v-toolbar-title>
      <v-spacer />

      <desktop-nav :nav-entries="navEntries" :user-nav-entries="userNavEntries" class="d-none d-lg-flex align-center" />
      <cart-button class="d-lg-none" />
      <mobile-nav :nav-entries="navEntries" :user-nav-entries="userNavEntries" />
    </v-container>

    <template v-if="user && userHasSomeAdminRole" v-slot:extension>
      <admin-nav />
    </template>
  </v-app-bar>
</template>

<script>
import AdminNav from '@/components/AdminNav'
import CartButton from './CartButton.vue'
import DesktopNav from '@/components/DesktopNav'
import MobileNav from '@/components/MobileNav'

export default {
  name: 'app-header',

  components: {
    AdminNav,
    CartButton,
    DesktopNav,
    MobileNav,
  },

  data () {
    return {
      navEntries: [
        { label: 'Konfigurator', route: 'BikeOverview', icon: 'build', restricted: true },
        { label: 'Serienräder', route: 'SeriesBikeOverview', icon: 'pedal_bike', restricted: true },
        { label: 'Galerie', route: 'Gallery', icon: 'collections' },
        { label: 'Katalog', route: 'Catalogue', icon: 'auto_stories' },
        { label: 'Dokumente', route: 'Downloads', icon: 'get_app' },
      ],
      userNavEntries: [
        { label: 'Mein Konto', route: 'AccountOverview', icon: 'face' },
        { label: 'Bestellungen', route: 'AccountOrders', icon: 'list_alt' },
        { label: 'Konfigurationen', route: 'AccountConfigurations', icon: 'favorite' },
      ],
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },

    userHasSomeAdminRole () {
      return this.$store.getters.userHasSomeAdminRole
    },
  },
}
</script>

<style lang="scss">
  .v-app-bar {
    .logo {
      width: 220px;
    }

    .v-toolbar__extension {
      padding: 0;

      .v-sheet {
        flex: 1;

        .admin-tabs {
          .v-tab {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
</style>
