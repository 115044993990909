<template>
  <v-dialog :value="isVisible" max-width="900" max-height="100" @input="visibility => $emit('change:visibility', visibility)">
    <v-card class="pa-4">
      <h2>Verfügbare Optionen</h2>

      <v-text-field
        v-model="search"
        prepend-inner-icon="search"
        hide-details
        outlined
        class="mb-8"
        label="Suche"
      />

      <div v-for="optionCategory in filteredOptionCategories" :key="optionCategory.optionCategoryId">
        <div v-if="optionCategory.options.length" class="mb-8">
          <h3 class="mb-4">
            {{ optionCategory.label }}
          </h3>

          <v-row dense>
            <v-col
              v-for="option in optionCategory.options"
              :key="`${optionCategory.optionCategoryId}_${option.optionId}`"
              :sm="4"
            >
              <v-checkbox
                :label="option.label"
                :input-value="optionIsChosen(option)"
                hide-details
                class="ma-0"
                @change="value => onChoice(value, option)"
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <v-divider class="mb-4" />

      <div class="d-flex justify-end">
        <v-btn depressed color="primary" @click="$emit('change:visibility', false)">
          Schließen
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'option-modal',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    chosenOptions: {
      type: Array,
      default: () => ([])
    },

    optionCategories: {
      type: Array,
      default: () => ([])
    },
  },

  data () {
    return {
      search: '',
    }
  },

  computed: {
    /**
     * Items matching the current search.
     *
     * @returns {array}
     */
    filteredOptionCategories () {
      return this.search && this.search.length ? this.optionCategories.map(optionCategory => {
        return {
          ...optionCategory,
          options: optionCategory.options.filter(option =>
            option.label.toLowerCase().includes(this.search.toLowerCase())
          )
        }
      }) : this.optionCategories
    },
  },

  methods: {
    /**
     * Checks if the given option is currently chosen.
     *
     * @param {object} option
     * @returns {boolean}
     */
    optionIsChosen (option) {
      return this.chosenOptions.find(o => o.optionId === option.optionId) !== undefined
    },

    /**
     * (Un)selects the given option.
     *
     * @param {boolean} chosen
     * @param {object} option
     * @returns {void}
     */
    onChoice (chosen, option) {
      const options = chosen
        ? [...this.chosenOptions, option]
        : this.chosenOptions.filter(o => o.optionId !== option.optionId)

      this.$emit('change:options', options)
    },
  },
}
</script>
