import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl,
  baseHeader,

  get () {
    return fetch(`${this.baseUrl}/auth/me`, {
      headers: this.baseHeader,
    })
  },

  delete ({ companyId, userId }) {
    return fetch(`${this.baseUrl}/company/${companyId}/user/${userId}`, {
      method: 'DELETE',
      headers: this.baseHeader,
    })
  },

  update (user) {
    if (!user || !Object.keys(user).length) {
      return false
    }

    return fetch(`${this.baseUrl}/company/${user.companyId}/user/${user.userId}`, {
      method: 'PUT',
      headers: this.baseHeader,
      body: JSON.stringify(user),
    })
  },

  setPassword ({ oldPassword, newPassword }) {
    return fetch(`${this.baseUrl}/auth/me/password`, {
      method: 'PUT',
      headers: this.baseHeader,
      body: JSON.stringify({ oldPassword, newPassword }),
    })
  },

  invite ({ companyId, user }) {
    return fetch(`${this.baseUrl}/company/${companyId}/user/invite`, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify(user),
    })
  },
}
