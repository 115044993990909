<template>
  <div class="login-wrap">
    <h1>Login</h1>

    <form class="mb-10" @submit.prevent="recaptcha">
      <v-row>
        <v-col :cols="12" :lg="6">
          <div class="input-wrap">
            <v-text-field
              v-model="username"
              prepend-inner-icon="face"
              hide-details
              outlined
              label="Nutzername oder E-Mail"
              class="mb-6"
              name="email"
              required
            />
          </div>
        </v-col>
        <v-col :cols="12" :lg="6">
          <v-text-field
            v-model="password"
            prepend-inner-icon="lock"
            hide-details
            outlined
            label="Passwort"
            required
            class="mb-6"
            name="password"
            type="password"
          />
        </v-col>
      </v-row>

      <div class="d-flex justify-space-between align-center">
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>lock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Passwort vergessen?</v-list-item-title>
              <v-list-item-subtitle>
                <router-link :to="{ name: 'ForgotPassword' }">
                  Zum Zurücksetzen hier klicken
                </router-link>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn type="submit" depressed>
          <v-icon color="primary" left>
            login
          </v-icon>
          Anmelden
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import AuthApi from '@/api/Auth'

export default {
  name: 'login',

  data () {
    return {
      username: '',
      password: '',
    }
  },

  methods: {
    /**
     * Gets the current reCaptcha-token before performing the login, so the
     * backend can calculate the risk.
     *
     * @returns {void}
     */
    async recaptcha () {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')
      this.login(token)
    },

    /**
     * Performs a login with the currently entered data, the given token.
     *
     * @param {string} token reCaptcha-token
     * @returns {void}
     */
    async login (token) {
      const res = await AuthApi.login({ username: this.username, password: this.password, token })

      if (res.ok) {
        this.$store.commit('setUser', await res.json())
        this.$store.commit('setSnackbar', { text: 'Login erfolgreich', color: 'success' })
        this.$router.push({ name: 'AccountOverview' })
      } else {
        this.$store.commit('setSnackbar', { text: 'Login fehlgeschlagen', color: 'error' })
      }
    },
  },
}
</script>
