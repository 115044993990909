<template>
  <div class="slot-panel--wrap">
    <div class="d-flex mb-5">
      <v-btn small depressed class="mr-2" @click="panel = []">
        <v-icon left>
          unfold_less
        </v-icon>
        Alle zuklappen
      </v-btn>
      <v-btn small depressed @click="openAllPanels">
        <v-icon left>
          unfold_more
        </v-icon>
        Alle ausklappen
      </v-btn>
    </div>

    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel v-for="(slot, i) in slotsCopy" :key="i">
        <v-expansion-panel-header>
          <div class="d-flex align-center">
            <strong :class="{ 'error--text': slotHasUnavailableComponent(slot) }">
              {{ slot.componentType.label }}
            </strong>

            <v-btn
              color="primary"
              text
              class="ml-auto"
              small
              :disabled="!slot.active"
              @click.stop.prevent="$emit('show:modal', i)"
            >
              <v-icon left>
                list
              </v-icon>
              Komponenten bearbeiten
            </v-btn>

            <v-switch
              :input-value="slot.active"
              inset
              dense
              hide-details
              label="Aktiv"
              class="mt-0 mx-10"
              @click.stop.prevent=""
              @change="active => $emit('change:active', { slotIndex: i, active })"
            />
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <div v-if="slot.componentType.isNullable" class="mb-4 d-flex align-center">
            <div class="d-flex align-center mr-4">
              <v-icon left>
                info
              </v-icon>
              Für diesen Slot ist die Option "ohne" verfügbar.
            </div>
            <v-checkbox
              :input-value="slot.useNullAsDefault"
              label="Als Standard nutzen"
              hide-details
              class="ma-0 pa-0"
              @change="value => $emit('override:default', { slotIndex: i, value })"
            />
          </div>

          <v-simple-table
            v-if="slot.chosenComponents && slot.chosenComponents.length"
            class="component-table"
          >
            <thead>
              <tr>
                <th>Grafik</th>
                <th>Bezeichnung</th>
                <th class="text-right">
                  Basispreis
                </th>
                <th v-if="!slot.useNullAsDefault" class="text-right">
                  Vergleich zum Standard
                </th>
              </tr>
            </thead>

            <draggable
              :list="slot.chosenComponents"
              tag="tbody"
              @change="$emit('change:order', {
                slotIndex: i,
                components: slot.chosenComponents
              })"
            >
              <tr v-for="(component, x) in slot.chosenComponents" :key="x">
                <td style="width: 10%">
                  <component-img
                    :id="component.componentId"
                    :has-image="component.hasImage"
                    :alt="component.label"
                    :height="50"
                    :width="80"
                  />
                </td>
                <td style="width: 50%">
                  <span class="d-inline-flex align-center">
                    <not-available-hint :is-available="component.available" class="mr-2" />
                    {{ component.label }}
                  </span>
                </td>
                <td style="width: 20%" class="text-right">
                  {{ component.price | centsToEuro }}
                </td>
                <td v-if="!slot.useNullAsDefault" style="width: 20%" class="text-right">
                  <span v-if="x === 0">Standard</span>
                  <span v-else>
                    {{ component.price - slot.chosenComponents[0].price | centsToEuro }}
                  </span>
                </td>
              </tr>
            </draggable>
          </v-simple-table>

          <v-alert v-else text border="left" icon="info" color="primary" class="d-inline-block">
            Es wurden noch keine Komponenten zugeordnet
          </v-alert>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ComponentImg from '@/components/ComponentImg'
import NotAvailableHint from '@/components/NotAvailableHint'

export default {
  name: 'slot-panel',

  components: {
    ComponentImg,
    draggable,
    NotAvailableHint,
  },

  props: {
    slots: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      panel: [],
    }
  },

  computed: {
    /**
     * If we use the given slots directly, manipulations of the order,.. would
     * affect the dataset of the parent component. That works, but is not clear
     * like communicating only wanted changes - so we use a copy.
     */
    slotsCopy () {
      return JSON.parse(JSON.stringify(this.slots))
    }
  },

  methods: {
    /**
     * Opens all panels.
     *
     * @returns {void}
     */
    openAllPanels () {
      this.panel = [...Array(this.slots.length).keys()]
    },

    /**
     * Checks if the slot has chosen components which aren't availalble.
     *
     * @param {object} slot
     * @returns {boolean}
     */
    slotHasUnavailableComponent (slot) {
      return slot.chosenComponents.reduce((hasUnavailableComponent, component) =>
        hasUnavailableComponent || !component.available
      , false)
    }
  },
}
</script>

<style lang="scss">
  .slot-panel--wrap {
    .v-expansion-panel-header {
      &:not(.v-expansion-panel-header--mousedown) {
        &:focus {
          &::before {
            opacity: 0;
          }
        }
      }
    }

    .component-table {
      tbody {
        tr:first-child {
          font-weight: 600;
        }
      }
    }
  }
</style>
