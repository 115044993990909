<template>
  <v-dialog
    :value="isVisible"
    max-width="1200"
    @input="visibility => $emit('change:visibility', visibility)"
  >
    <v-card class="pa-4">
      <h2 v-if="title">
        {{ title }}
      </h2>

      <v-text-field
        v-model="search"
        prepend-inner-icon="search"
        hide-details
        outlined
        class="mb-8"
        label="Suche"
        clearable
      />

      <v-row class="mb-8">
        <v-col
          v-for="(component, i) in filteredComponents"
          :key="component.componentId"
          :sm="6"
          class="py-0"
        >
          <v-list-item dense class="py-0">
            <v-list-item-content>
              <v-list-item-title class="font-weight-regular">
                <v-checkbox
                  :input-value="componentIsChosen(component)"
                  class="ma-0 pa-0"
                  hide-details
                  @change="value => onChoice(value, component)"
                >
                  <template v-slot:label>
                    <not-available-hint :is-available="component.available" class="mr-2" />
                    {{ component.label }}
                  </template>
                </v-checkbox>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="my-0">
              <span class="d-inline-flex align-center mr-2">
                {{ component.price | centsToEuro }}
              </span>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="i < filteredComponents.length - (filteredComponents.length % 2 === 0 ? 2 : 1)" />
        </v-col>
      </v-row>

      <v-divider class="mb-4" />

      <div class="d-flex justify-space-between">
        <div>
          <v-btn text color="primary" class="mr-2" @click="selectAllVisible">
            Alle auswählen
          </v-btn>

          <v-btn text color="primary" @click="unselectAllVisible">
            Alle abwählen
          </v-btn>
        </div>
        <v-btn depressed color="primary" @click="$emit('change:visibility', false)">
          Schließen
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import NotAvailableHint from '@/components/NotAvailableHint'

export default {
  name: 'component-modal',

  components: {
    NotAvailableHint,
  },

  props: {
    components: {
      type: Array,
      default: () => ([]),
    },

    isVisible: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },

    chosenComponents: {
      type: Array,
      default: () => ([]),
    },
  },

  data () {
    return {
      search: '',
    }
  },

  computed: {
    /**
     * Items matching the current search.
     *
     * @returns {array}
     */
    filteredComponents () {
      const components = this.search && this.search.length ? this.components.filter(component =>
        component.label.toLowerCase().includes(this.search.toLowerCase())
      ) : this.components

      return components.sort((a, b) => a.label.localeCompare(b.label))
    },
  },

  methods: {
    /**
     * Checks if the given component is currently chosen.
     *
     * @param {object} component
     * @returns {boolean}
     */
    componentIsChosen (component) {
      return this.chosenComponents.find(c => c.componentId === component.componentId) !== undefined
    },

    /**
     * (Un)selects the given component.
     *
     * @param {boolean} chosen
     * @param {object} component
     * @returns {void}
     */
    onChoice (chosen, component) {
      const components = chosen
        ? [...this.chosenComponents, component]
        : this.chosenComponents.filter(c => c.componentId !== component.componentId)

      this.$emit('change:components', components)
    },

    /**
     * Add all currently visible/filtered components to the current selection.
     *
     * @returns {void}
     */
    selectAllVisible () {
      this.$emit('change:components', [
        ...this.chosenComponents,
        ...this.filteredComponents.filter(component =>
          !this.chosenComponents.find(c => c.componentId === component.componentId)
        )
      ])
    },

    /**
     * Removes all currently visible/filtered components from the current
     * selection.
     *
     * @returns {void}
     */
    unselectAllVisible () {
      this.$emit(
        'change:components',
        this.chosenComponents.filter(component =>
          !this.filteredComponents.find(c => c.componentId === component.componentId)
        )
      )
    },
  },
}
</script>
