import Admin from '@/views/Admin/Admin'
import Companies from '@/views/Admin/Companies/Companies'
import CompanyDetails from '@/views/Admin/Companies/Details'
import CompanyOverview from '@/views/Admin/Companies/Overview'
import ComponentDetails from '@/views/Admin/Components/Details'
import ComponentOverview from '@/views/Admin/Components/Overview'
import Components from '@/views/Admin/Components/Components'
import ModelDetails from '@/views/Admin/Models/Details'
import ModelOverview from '@/views/Admin/Models/Overview'
import Models from '@/views/Admin/Models/Models'
import OptionCategories from '@/views/Admin/OptionCategories/OptionCategories'
import OptionCategory from '@/views/Admin/OptionCategories/OptionCategory'
import OptionCategoryOverview from '@/views/Admin/OptionCategories/OptionCategoryOverview'
import SeriesBikes from '@/views/Admin/SeriesBikes/Index'
import SeriesBikesOverview from '@/views/Admin/SeriesBikes/Overview'
import SeriesBikeDetails from '@/views/Admin/SeriesBikes/Details'

import store from '@/store'

const homeBreadcrumb = { text: 'Start', to: '/' }
const checkWithUser = check =>
  store.state.user.userChecked
    ? check()
    : store.dispatch('getUser', check)

export default [
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    children: [
      {
        path: 'firmen',
        component: Companies,
        children: [
          {
            path: 'uebersicht',
            name: 'CompanyOverview',
            component: CompanyOverview,
            beforeEnter: (to, from, next) => checkWithUser(() =>
              store.getters.userCanEditCompanies ? next() : next(false)
            ),
            meta: {
              breadcrumb: [
                homeBreadcrumb,
                { text: 'Firmenübersicht', disabled: true },
              ]
            },
          },
          {
            path: ':id?',
            name: 'CompanyDetails',
            component: CompanyDetails,
            meta: {
              breadcrumb: [
                homeBreadcrumb,
                { text: 'Firmenübersicht', to: { name: 'CompanyOverview' }, exact: true },
                { text: 'Firma', disabled: true },
              ]
            },
          },
        ],
      },
      {
        path: 'komponenten',
        component: Components,
        beforeEnter: (to, from, next) => checkWithUser(() =>
          store.getters.userCanEditComponents ? next() : next(false)
        ),
        children: [
          {
            path: 'uebersicht',
            name: 'ComponentOverview',
            component: ComponentOverview,
            meta: {
              breadcrumb: [
                homeBreadcrumb,
                { text: 'Komponentenübersicht', disabled: true },
              ]
            },
          },
          {
            path: 'komponente/:id?',
            name: 'ComponentDetails',
            component: ComponentDetails,
            meta: {
              breadcrumb: [
                homeBreadcrumb,
                { text: 'Komponentenübersicht', to: { name: 'ComponentOverview' }, exact: true },
                { text: 'Komponente', disabled: true },
              ]
            },
          },
        ],
      },
      {
        path: 'modellreihen',
        component: Models,
        beforeEnter: (to, from, next) => checkWithUser(() =>
          store.getters.userCanEditComponents ? next() : next(false)
        ),
        children: [
          {
            path: 'uebersicht',
            name: 'ModelOverview',
            component: ModelOverview,
            meta: {
              breadcrumb: [
                homeBreadcrumb,
                { text: 'Modellreihen', disabled: true },
              ]
            },
          },
          {
            path: 'modellreihe/:id?',
            name: 'ModelDetails',
            component: ModelDetails,
            meta: {
              breadcrumb: [
                homeBreadcrumb,
                { text: 'Modellreihen', to: { name: 'ModelOverview' }, exact: true },
                { text: 'Modellreihe', disabled: true },
              ]
            },
          },
        ],
      },
      {
        path: 'optionskategorien',
        component: OptionCategories,
        beforeEnter: (to, from, next) => checkWithUser(() =>
          store.getters.userCanEditComponents ? next() : next(false)
        ),
        children: [
          {
            path: 'uebersicht',
            name: 'OptionCategoryOverview',
            component: OptionCategoryOverview,
            meta: {
              breadcrumb: [
                homeBreadcrumb,
                { text: 'Modellreihen', disabled: true },
              ]
            },
          },
          {
            path: 'optionskategorie/:id?',
            name: 'OptionCategory',
            component: OptionCategory,
            meta: {
              breadcrumb: [
                homeBreadcrumb,
                { text: 'Optionskategorien', to: { name: 'OptionCategoryOverview' }, exact: true },
                { text: 'Optionskategorie', disabled: true },
              ]
            },
          },
        ],
      },
      {
        path: 'serienraeder',
        component: SeriesBikes,
        children: [
          {
            path: 'uebersicht',
            name: 'AdminSeriesBikesOverview',
            component: SeriesBikesOverview,
            beforeEnter: (to, from, next) => checkWithUser(() =>
              store.getters.userCanManageSeries ? next() : next(false)
            ),
            meta: {
              breadcrumb: [
                homeBreadcrumb,
                { text: 'Übersicht', disabled: true },
              ]
            },
          },
          {
            path: ':id?',
            name: 'AdminSeriesBikeDetails',
            component: SeriesBikeDetails,
            meta: {
              breadcrumb: [
                homeBreadcrumb,
                { text: 'Übersicht', to: { name: 'AdminSeriesBikesOverview' }, exact: true },
                { text: 'Serienrad', disabled: true },
              ]
            },
          },
        ],
      },
    ],
  },
]
