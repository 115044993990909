<template>
  <div class="set-password--wrap">
    <h1>Passwort vergessen</h1>

    <form class="mb-10" @submit.prevent="recaptcha">
      <v-row>
        <v-col :cols="12" :lg="6">
          <v-text-field
            v-model="email"
            prepend-inner-icon="email"
            hide-details
            outlined
            label="E-Mail"
            class="mb-6"
            required
            type="email"
          />
        </v-col>
      </v-row>

      <v-btn depressed type="submit" class="ml-auto">
        <v-icon color="primary" left>
          save
        </v-icon>
        Passwort zurücksetzen
      </v-btn>
    </form>
  </div>
</template>

<script>
import AuthApi from '@/api/Auth'

export default {
  name: 'forgot-password',

  data () {
    return {
      email: '',
    }
  },

  methods: {
    /**
     * Gets the current reCaptcha-token before performing the request, so the
     * backend can calculate the risk.
     *
     * @returns {void}
     */
    async recaptcha () {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('resetPassword')
      this.resetPassword(token)
    },

    /**
     * Requests a password-reset-mail for the entered adress, the given token.
     *
     * @param {string} token reCaptcha-token
     * @returns {void}
     */
    async resetPassword (captchaToken) {
      const res = await AuthApi.resetPassword(this.email, captchaToken)

      if (res.ok) {
        this.$store.commit('setSnackbar', {
          text: 'Anfrage erfolgreich. Bitte kontrollieren Sie Ihre E-Mails.',
          color: 'success'
        })
        this.$router.push({ name: 'Login' })
      } else {
        this.$store.commit('setSnackbar', { text: 'Anfrage fehlgeschlagen', color: 'error' })
      }
    }
  },
}
</script>
