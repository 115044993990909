import { baseUrl, baseHeader } from '@/api/Api'

export default {
  state: {
    colors: [],
    dekorTypes: [],
  },

  getters: {
    defaultColor (state) {
      return state.colors.find(color => color.label === 'Schwarz')
    },

    defaultDekorColor (state) {
      return state.colors.find(color => color.label === 'Weiß')
    },
  },

  mutations: {
    setColors (state, colors = []) {
      state.colors = colors
    },

    setDekorTypes (state, dekorTypes = []) {
      state.dekorTypes = dekorTypes
    },
  },

  actions: {
    /**
     * Loads available colors to choose from, sets defaults for dekor-elements.
     *
     * @param {object} context
     * @returns {void}
     */
    async loadColors ({ commit, getters }) {
      const res = await fetch(`${baseUrl}/color`, { headers: baseHeader })

      if (res.ok) {
        commit('setColors', await res.json())
        commit('updateDekor', { key: 'color', value: getters.defaultDekorColor }, { root: true })
        commit('updateMudguardConfig', { chosenColor: getters.defaultDekorColor }, { root: true })
      }
    },

    /**
     * Loads available dekor-types, sorts those and picks the first one as a
     * default (since a pick is required).
     *
     * @param {object} context
     * @returns {void}
     */
    async loadDekorTypes ({ commit }) {
      const res = await fetch(`${baseUrl}/decor`, { headers: baseHeader })

      if (!res.ok) {
        return
      }

      const dekorTypes = await res.json()

      if (dekorTypes.length) {
        dekorTypes.sort((a, b) => a.position - b.position)
        commit('setDekorTypes', dekorTypes)
        commit('updateDekor', { key: 'activeDekorType', value: dekorTypes[0] }, { root: true })
      }
    },
  },
}
