import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl: `${baseUrl}/cart`,

  get () {
    return fetch(this.baseUrl, {
      headers: baseHeader,
    })
  },

  /**
   * addItem
   *
   * @param {number} amount
   * @param {string} articleNumber
   * @returns {Promise}
   */
  addItem (amount, articleNumber) {
    return fetch(this.baseUrl, {
      method: 'POST',
      headers: baseHeader,
      body: JSON.stringify({ amount, articleNumber }),
    })
  },

  /**
   * addItem
   *
   * @param {number} amount
   * @param {string} articleNumber
   * @returns {Promise}
   */
  updateItem (amount, articleNumber) {
    return fetch(this.baseUrl, {
      method: 'PUT',
      headers: baseHeader,
      body: JSON.stringify({ amount, articleNumber }),
    })
  },

  /**
   * deleteItem
   *
   * @param {string} articleNumber
   * @returns {Promise}
   */
  deleteItem (articleNumber) {
    return fetch(`${this.baseUrl}/${articleNumber}`, {
      method: 'DELETE',
      headers: baseHeader,
    })
  },
}
