<template>
  <div class="biker-filters--wrap">
    <v-navigation-drawer
      :value="isVisible"
      app
      right
      clipped
      width="300"
      color="grey lighten-4"
      disable-resize-watcher
      @input="isVisible => $emit('changed:visibility', isVisible)"
    >
      <v-sheet class="pa-3">
        <h2>Filter</h2>

        <div v-for="(filterGroup, i) in filterGroups" :key="`group_${i}`" class="bike-filter-col mb-5">
          <h4 class="mb-2">
            {{ filterGroup.componentType }}
          </h4>

          <div class="d-flex flex-wrap">
            <v-select
              v-for="optionCategory in filterGroup.optionCategories"
              :key="optionCategory.id"
              dense
              hide-details
              :items="optionCategory.options"
              item-text="label"
              item-value="optionId"
              outlined
              return-object
              clearable
              :label="optionCategory.label"
              class="filter-select mr-1 mb-2"
              :value="getRelatedFilter(optionCategory) ? getRelatedFilter(optionCategory).optionId : null"
              @click:clear="onFilterChange(null, optionCategory)"
              @change="value => onFilterChange(value, optionCategory)"
            />
          </div>
        </div>
      </v-sheet>
    </v-navigation-drawer>
  </div>
</template>

<script>
import OptionCategoryApi from '@/api/OptionCategory'

export default {
  name: 'bike-filters',

  props: {
    activeFilters: {
      type: Array,
      default: () => ([]),
    },

    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      filterGroups: [],
    }
  },

  mounted () {
    this.createFilterGroups()
  },

  methods: {
    /**
     * Loads available option-categories, uses those to build filter-groups.
     *
     * @returns {void}
     */
    async createFilterGroups () {
      const res = await OptionCategoryApi.getAll()

      if (!res.ok) {
        return
      }

      const optionCategories = (await res.json())
        .sort((a, b) => a.label.localeCompare(b.label))
        .map(cat => ({ ...cat, options: cat.options.sort((a, b) => a.label.localeCompare(b.label)) }))

      // we want to group the filters by their component-type, just use filters
      // with options to choose from
      this.filterGroups = optionCategories
        .filter(oc => oc.showInSearch && oc.options.length)
        .reduce((groups, oc) => groups.find(g => g.componentType === oc.componentType.label)
          ? groups
          : [...groups, {
            componentType: oc.componentType.label,
            optionCategories: optionCategories.filter(cat =>
              cat.componentType.componentType === oc.componentType.componentType && cat.showInSearch && cat.options.length
            ),
          }]
        , [])
        .sort((a, b) => a.componentType.localeCompare(b.componentType))
    },

    /**
     * Returns the filter (if there's an active one) related to the given
     * optionCategory
     *
     * @param {object} optionCategory
     * @returns {object|undefined}
     */
    getRelatedFilter (optionCategory) {
      return this.activeFilters.find(f => f.optionCategoryId === optionCategory.optionCategoryId)
    },

    /**
     * onFilterChange
     *
     * @param {object|null} option Picked option/filter (or null if removed)
     * @param {object} optionCategory
     */
    onFilterChange (option, optionCategory) {
      let filters = this.activeFilters ? [...this.activeFilters] : []

      const filterIndex = filters.findIndex(filter =>
        filter.optionCategoryId === optionCategory.optionCategoryId
      )

      if (!option) {
        filterIndex !== -1 && filters.splice(filterIndex, 1)
        return this.$emit('changed:filters', filters)
      }

      const filter = {
        componentType: optionCategory.componentType,
        optionCategoryId: optionCategory.optionCategoryId,
        optionId: option.optionId,
        label: `${optionCategory.label} (${optionCategory.componentType.label}): ${option.label}`,
      }

      filterIndex !== -1
        ? filters.splice(filterIndex, 1, filter)
        : filters = [...filters, filter]

      this.$emit('changed:filters', filters)
    }
  },
}
</script>
