import Agb from '@/views/Info/Agb'
import Catalogue from '@/views/Catalogue'
import Downloads from '@/views/Downloads'
import Gallery from '@/views/Gallery'
import Home from '@/views/Home'
import Imprint from '@/views/Info/Imprint'
import Login from '@/views/Login/Login'
import LoginForm from '@/views/Login/LoginForm'
import Privacy from '@/views/Info/Privacy'
import Registration from '@/views/Registration'
import ForgotPassword from '@/views/Login/ForgotPassword'
import SetNewPassword from '@/views/Login/SetNewPassword'

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    component: Login,
    children: [
      {
        path: '/',
        name: 'Login',
        component: LoginForm,
      },
      {
        path: 'passwort-vergessen',
        name: 'ForgotPassword',
        component: ForgotPassword,
      },
      {
        path: 'neues-passwort/:token',
        name: 'SetNewPassword',
        component: SetNewPassword,
      },
    ],
  },
  {
    path: '/galerie',
    name: 'Gallery',
    component: Gallery,
  },
  {
    path: '/dokumente',
    name: 'Downloads',
    component: Downloads,
  },
  {
    path: '/katalog',
    name: 'Catalogue',
    component: Catalogue,
  },
  {
    path: '/agb',
    name: 'Agb',
    component: Agb,
  },
  {
    path: '/impressum',
    name: 'Imprint',
    component: Imprint,
  },
  {
    path: '/datenschutz',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/firmen/:companyId/benutzer/registrieren/:token',
    name: 'Registration',
    component: Registration,
  },
]
