import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl: `${baseUrl}/auth`,
  baseHeader,

  /**
   * login
   *
   * @param {object} options
   * @param {string} options.username
   * @param {string} options.password
   * @param {string} options.token reCaptcha-token
   *
   * @returns {Promise[]}
   */
  login ({ username = '', password = '', token = '' }) {
    if (!username.length || !password.length) {
      return false
    }

    return fetch(`${this.baseUrl}/login`, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify({ username, password, captchaToken: token }),
    })
  },

  /**
   * logout
   *
   * @returns {Promise[]}
   */
  logout () {
    return fetch(`${this.baseUrl}/logout`, {
      method: 'POST',
      headers: this.baseHeader,
    })
  },

  /**
   * resetPassword
   *
   * @param {string} email
   * @param {string} captchaToken
   *
   * @returns {Promise[]}
   */
  resetPassword (email = '', captchaToken = '') {
    return fetch(`${this.baseUrl}/requestPasswordReset`, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify({ email, captchaToken }),
    })
  },

  /**
   * setNewPassword
   *
   * @param {object} options
   * @param {string} options.token
   * @param {string} options.password
   *
   * @returns {Promise[]}
   */
  setNewPassword ({ token = '', password = '' }) {
    return fetch(`${this.baseUrl}/performPasswordReset/${token}`, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify({ password }),
    })
  },
}
