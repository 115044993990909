<template>
  <div class="admin-models-details-wrap">
    <h1>
      Optionskategorie {{ isEdit ? 'bearbeiten' : 'erstellen' }}
    </h1>

    <v-form ref="form" v-model="formIsValid" class="mb-15">
      <v-row>
        <v-col :cols="12" :md="6">
          <v-text-field
            v-model="optionCategory.label"
            prepend-inner-icon="description"
            hide-details
            outlined
            label="Bezeichnung*"
            class="mb-6"
            :rules="requiredRules"
          />

          <v-select
            v-model="optionCategory.componentType"
            :items="componentTypes"
            item-text="label"
            return-object
            label="Kategorie*"
            prepend-inner-icon="category"
            hide-details
            outlined
            :rules="requiredRules"
            class="mb-6"
          />
        </v-col>

        <v-col :cols="12" :md="6">
          <div class="d-flex justify-end mb-5">
            <v-switch v-model="optionCategory.showInSearch" inset label="In Suche anzeigen" />
          </div>

          <div class="d-flex justify-end">
            <v-btn v-if="isEdit" depressed class="mr-2" @click="showDeleteDialog = true">
              <v-icon color="red accent-3" left>
                delete
              </v-icon>
              Löschen
            </v-btn>

            <v-btn depressed class="mr-2" :disabled="!formIsValid" @click="persistOptionCategory()">
              <v-icon color="primary" left>
                create
              </v-icon>
              {{ isEdit ? 'Speichern' : 'Erstellen' }}
            </v-btn>

            <v-btn depressed :disabled="!formIsValid" @click="persistOptionCategory(true)">
              <v-icon color="primary" left>
                save_alt
              </v-icon>
              {{ isEdit ? 'Speichern, schließen' : 'Erstellen, schließen' }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <base-dialog
      v-if="isEdit && optionCategory.optionCategoryId"
      headline="Optionskategorie wirklich löschen?"
      :is-visible="showDeleteDialog"
      @cancel="showDeleteDialog = false"
      @ok="deleteOptionCatagory"
    />

    <leave-confirmation
      :current-data="optionCategory"
      :default-data="loadedOptionCategory"
      :route-handler="routeHandler"
      @decided="routeHandler = null"
    />

    <options v-if="isEdit" :option-category-id="+$route.params.id" />
  </div>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'
import ComponentApi from '@/api/Component'
import LeaveConfirmation from '@/components/LeaveConfirmation'
import Options from '@/views/Admin/OptionCategories/Options'
import OptionCategoryApi from '@/api/OptionCategory'

export default {
  name: 'option-category',

  components: {
    BaseDialog,
    LeaveConfirmation,
    Options,
  },

  data () {
    return {
      optionCategory: {
        optionCategoryId: null,
        label: '',
        componentType: null,
        showInSearch: false,
      },
      componentTypes: [],
      requiredRules: [v => !!v],
      formIsValid: false,
      showDeleteDialog: false,
      loadedOptionCategory: null,
      routeHandler: null,
    }
  },

  computed: {
    isEdit () {
      return this.$route.params.id !== undefined
    },
  },

  watch: {
    '$route.params.id': {
      handler (to, from) {
        to && +to !== +from && this.loadOptionCategory(+to)
      }
    },
  },

  async mounted () {
    this.setLoadedOptionCategory()
    this.getComponentTypes()

    if (this.isEdit) {
      await this.loadOptionCategory(this.$route.params.id)
    }

    this.setBreadcrumb()
  },

  beforeRouteLeave (to, from, next) {
    this.routeHandler = next
  },

  methods: {
    /**
     * setBreadcrumb
     *
     * @returns {undefined}
     */
    setBreadcrumb () {
      this.$store.commit('setBreadcrumbItems', [
        { text: 'Optionskategorien', to: { name: 'OptionCategoryOverview' }, exact: true },
        { text: this.isEdit ? this.loadedOptionCategory.label : 'Optionskategorie erstellen', disabled: true }
      ])
    },

    /**
     * Sets the dataset to compare to when leaving the page.
     *
     * @returns {void}
     */
    setLoadedOptionCategory () {
      this.loadedOptionCategory = JSON.parse(JSON.stringify(this.optionCategory))
      this.setBreadcrumb()
    },

    /**
     * Loads available component-types/-categories so the user can choose one.
     *
     * @returns {void}
     */
    async getComponentTypes () {
      const res = await ComponentApi.getTypes()

      if (res.ok) {
        this.componentTypes = await res.json()
        this.$refs.form.resetValidation()
      }
    },

    /**
     * Loads the optioncategory with the given id.
     *
     * @param {String|Number} id
     * @returns {void}
     */
    async loadOptionCategory (id) {
      const res = await OptionCategoryApi.get(id)

      if (res.ok) {
        this.optionCategory = await res.json()
        this.setLoadedOptionCategory()
      }
    },

    /**
     * Creates a new optioncategory or updates the current one.
     *
     * @param {Boolean} close Redirect to the overview after saving
     * @returns {void}
     */
    async persistOptionCategory (close) {
      const res = this.isEdit
        ? await OptionCategoryApi.update(this.optionCategory)
        : await OptionCategoryApi.create(this.optionCategory)

      if (!res.ok) {
        return this.$store.commit('setSnackbar', { text: 'Ein Fehler ist aufgetreten', color: 'error' })
      }

      this.$store.commit('setSnackbar', {
        color: 'success',
        text: `Optionskategorie ${this.isEdit ? 'aktualisiert' : 'erstellt'}`,
      })

      if (close) {
        this.setLoadedOptionCategory()
        return this.$router.push({ name: 'OptionCategoryOverview' })
      }

      if (this.isEdit) {
        this.loadOptionCategory(this.$route.params.id)
      } else {
        const newCategory = await res.json()
        newCategory && this.$router.push({ name: 'OptionCategory', params: { id: newCategory.optionCategoryId } })
      }
    },

    /**
     * Deletes the current optioncategory.
     *
     * @returns {void}
     */
    async deleteOptionCatagory () {
      const res = await OptionCategoryApi.delete(this.optionCategory.optionCategoryId)

      if (res.ok) {
        this.setLoadedOptionCategory()
        this.$store.commit('setSnackbar', { text: 'Optionskategorie gelöscht', color: 'success' })
        this.$router.push({ name: 'OptionCategoryOverview' })
      } else {
        this.$store.commit('setSnackbar', { text: 'Löschen fehlgeschlagen', color: 'error' })
      }
    },
  }
}
</script>
