import OrderApi from '@/api/Order'

export default {
  state: {
    customerPrice: 0,
    dekorPrice: 0,
    ralPrice: 0,
    retailerPrice: 0,
    pricesLoading: false,
  },

  mutations: {
    setBikePrice (state, { customerPrice, retailerPrice }) {
      state.customerPrice = customerPrice
      state.retailerPrice = retailerPrice
    },

    setLoading (state, isLoading) {
      state.pricesLoading = isLoading
    },

    setPrices (state, { ral = 0, decor = 0 }) {
      state.ralPrice = ral
      state.dekorPrice = decor
    },
  },

  actions: {
    /**
     * Gets the price of the current configuration.
     *
     * @param {object} context
     * @returns {void}
     */
    async getBikePrice ({ commit, rootState }) {
      commit('setLoading', true)
      const res = await OrderApi.getPrice(rootState.bike)

      if (res.ok) {
        const price = await res.json()
        commit('setBikePrice', price)
        commit('setLoading', false)
      }
    }
  },
}
