import Account from '@/views/Account/Account'
import AccountConfigurations from '@/views/Account/AccountConfigurations'
import AccountOrders from '@/views/Account/AccountOrders'
import AccountOverview from '@/views/Account/AccountOverview'
import AfterBuy from '@/views/AfterBuy'
import BikeIndex from '@/views/BikeConfigurator/Index'
import BikeConfigurator from '@/views/BikeConfigurator/Configuration/BikeConfigurator'
import BikeOverview from '@/views/BikeConfigurator/Overview/BikeOverview'
import Cart from '@/views/Cart'
import UserCompany from '@/views/Company/UserCompany.vue'

import SeriesBikeIndex from '@/views/SeriesBikes/Index.vue'
import SeriesBikeOverview from '@/views/SeriesBikes/Overview.vue'
import SeriesBikeDetails from '@/views/SeriesBikes/Details.vue'

import store from '@/store'

const checkWithUser = check =>
  store.state.user.userChecked
    ? check()
    : store.dispatch('getUser', check)

export default [
  {
    path: '/konfigurator',
    component: BikeIndex,
    beforeEnter: (to, from, next) => checkWithUser(() => {
      return store.state.user.user !== null ? next() : next({ name: 'Login' })
    }),
    children: [
      {
        path: '/',
        redirect: 'uebersicht',
      },
      {
        path: 'uebersicht',
        name: 'BikeOverview',
        component: BikeOverview,
      },
      {
        path: 'bike',
        name: 'BikeConfigurator',
        component: BikeConfigurator,
      },
    ],
  },
  {
    path: '/account',
    component: Account,
    beforeEnter: (to, from, next) => checkWithUser(() => {
      return store.state.user.user !== null ? next() : next({ name: 'Login' })
    }),
    children: [
      {
        path: '/',
        redirect: 'uebersicht',
      },
      {
        path: 'uebersicht',
        name: 'AccountOverview',
        component: AccountOverview,
      },
      {
        path: 'bestellungen',
        name: 'AccountOrders',
        component: AccountOrders,
      },
      {
        path: 'konfigurationen',
        name: 'AccountConfigurations',
        component: AccountConfigurations,
      },
    ],
  },
  {
    path: '/kauf-erfolgreich',
    name: 'AfterBuy',
    component: AfterBuy,
  },
  {
    path: '/serienraeder',
    component: SeriesBikeIndex,
    beforeEnter: (to, from, next) => checkWithUser(() => {
      return store.state.user.user !== null ? next() : next({ name: 'Login' })
    }),
    children: [
      {
        path: '/',
        redirect: 'uebersicht',
      },
      {
        path: 'uebersicht',
        name: 'SeriesBikeOverview',
        component: SeriesBikeOverview,
      },
      {
        path: 'modell/:id',
        name: 'SeriesBikeDetails',
        component: SeriesBikeDetails,
        beforeEnter: (to, from, next) => checkWithUser(() =>
          store.state.user !== null ? next() : next({ name: 'Login' })
        ),
      },
    ]
  },
  {
    path: '/warenkorb',
    name: 'Cart',
    component: Cart,
    beforeEnter: (to, from, next) => checkWithUser(() =>
      store.state.user !== null ? next() : next({ name: 'Login' })
    ),
  },
  {
    path: '/firma/:id?',
    name: 'UserCompany',
    component: UserCompany,
  },
]
