import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl: `${baseUrl}/user/bookmark`,
  baseHeader,

  get (bookmarkId) {
    return fetch(`${this.baseUrl}/${bookmarkId}`, {
      headers: this.baseHeader,
    })
  },

  /**
   * getAll
   *
   * @returns {Promise[]}
   */
  getAll () {
    return fetch(this.baseUrl, { headers: this.baseHeader })
  },

  /**
   * create
   *
   * @param {object} bookmark
   * @returns {Promise[]}
   */
  create (bookmark) {
    return fetch(this.baseUrl, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify(bookmark),
    })
  },

  /**
   * delete
   *
   * @param {number} bookmarkId ID of the bookmark to remove
   * @returns {Promise[]}
   */
  delete (bookmarkId) {
    return fetch(`${this.baseUrl}/${bookmarkId}`, {
      method: 'DELETE',
      headers: this.baseHeader,
    })
  },

  /**
   * update
   *
   * @param {object} bookmark bookmark to update
   * @returns {Promise[]}
   */
  update (bookmark) {
    return fetch(`${this.baseUrl}/${bookmark.bookmarkId}`, {
      method: 'PUT',
      headers: this.baseHeader,
      body: JSON.stringify(bookmark),
    })
  }
}
