<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon class="d-lg-none" v-bind="attrs" v-on="on">
        <v-icon>menu</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="({ label, route, icon, restricted }, i) in navEntries"
        :key="i"
        :to="{ name: route }"
        :disabled="restricted && user === null"
      >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ label }}</v-list-item-title>
      </v-list-item>

      <template v-if="user">
        <v-list-item v-for="({ label, route, icon }, i) in userNavEntries" :key="`u_${i}`" :to="{ name: route }">
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ label }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </template>

      <v-list-item v-else :to="{ name: 'Login' }">
        <v-list-item-icon>
          <v-icon>login</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Login</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import AuthApi from '@/api/Auth'

export default {
  name: 'mobile-nav',

  props: {
    navEntries: {
      type: Array,
      default: () => ([])
    },
    userNavEntries: {
      type: Array,
      default: () => ([])
    },
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },
  },

  methods: {
    async logout () {
      const res = await AuthApi.logout()

      if (res.ok) {
        this.$store.commit('setUser', null)
        this.$store.commit('setSnackbar', { text: 'Logout erfolgreich', color: 'success' })
        this.$route.name !== 'Home' && this.$router.push({ name: 'Home' })
      }
    },
  },
}
</script>
