import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl: `${baseUrl}/order`,
  baseHeader,

  get (orderId) {
    return fetch(`${this.baseUrl}/${orderId}`, {
      method: 'POST',
      headers: this.baseHeader,
    })
  },

  /**
   * getAll
   *
   * @returns {Promise[]}
   */
  getAll () {
    return fetch(this.baseUrl, { headers: this.baseHeader })
  },

  /**
   * create
   *
   * @returns {Promise[]}
   */
  create (order) {
    return fetch(this.baseUrl, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify(order),
    })
  },

  /**
   * Gets the price of the given order.
   *
   * @param {object} order
   * @returns {Promise[]}
   */
  getPrice (order) {
    return fetch(`${this.baseUrl}/price`, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify(order),
    })
  },
}
