<template>
  <v-dialog
    :value="isVisible"
    max-width="600"
    @input="visibility => $emit('change', visibility)"
  >
    <v-form ref="form" v-model="formIsValid" @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          Neuen Nutzer anlegen
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="user.firstName"
            prepend-inner-icon="face"
            hide-details
            outlined
            label="Vorname*"
            class="mb-6"
            :rules="requiredRules"
          />

          <v-text-field
            v-model="user.lastName"
            prepend-inner-icon="family_restroom"
            hide-details
            outlined
            label="Nachname*"
            class="mb-6"
            :rules="requiredRules"
          />

          <v-text-field
            v-model="user.email"
            prepend-inner-icon="email"
            hide-details
            outlined
            label="E-Mail*"
            class="mb-6"
            :rules="requiredRules"
            type="email"
          />

          <v-select
            v-if="withPermissions && permissions.length"
            v-model="user.permissions"
            :items="permissions"
            item-text="label"
            item-value="permission"
            label="Berechtigungen"
            prepend-inner-icon="admin_panel_settings"
            multiple
            chips
            deletable-chips
            small-chips
            outlined
            hide-details
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn depressed @click="onCancel">
            <v-icon left>
              cancel
            </v-icon>
            Abbrechen
          </v-btn>
          <v-btn depressed type="submit" :disabled="!formIsValid">
            <v-icon color="primary" left>
              create
            </v-icon>
            Nutzer anlegen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'user-modal',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Array,
      default: () => ([]),
    },
    withPermissions: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      user: {
        email: '',
        firstName: '',
        lastName: '',
        permissions: [],
      },
      formIsValid: false,
      requiredRules: [v => !!v],
    }
  },

  methods: {
    onCancel (e) {
      this.reset()
      this.$emit('cancel')
    },

    onSubmit () {
      if (this.formIsValid) {
        this.$emit('submit', this.user)
        this.reset()
      }
    },

    reset () {
      this.user = {
        email: '',
        firstName: '',
        lastName: '',
        permissions: [],
      }

      this.$refs.form.resetValidation()
    }
  },
}
</script>
