import { baseUrl, baseHeader } from '@/api/Api'

export default {
  baseUrl: `${baseUrl}/standard_bike_series`,
  baseHeader,

  get (standardBikeSeriesId) {
    return fetch(`${this.baseUrl}/${standardBikeSeriesId}`, {
      headers: this.baseHeader,
    })
  },

  /**
   * getAll
   *
   * @param {boolean} onlyActive
   * @returns {Promise[]}
   */
  getAll (onlyActive = false) {
    return fetch(`${this.baseUrl}?onlyActive=${onlyActive}`, { headers: this.baseHeader })
  },

  /**
   * create
   *
   * @returns {Promise[]}
   */
  create (seriesBike) {
    return fetch(this.baseUrl, {
      method: 'POST',
      headers: this.baseHeader,
      body: JSON.stringify(seriesBike),
    })
  },

  /**
   * delete
   *
   * @param {number} standardBikeSeriesId ID of the seriesBike to remove
   * @returns {Promise[]}
   */
  delete (standardBikeSeriesId) {
    return fetch(`${this.baseUrl}/${standardBikeSeriesId}`, {
      method: 'DELETE',
      headers: this.baseHeader,
    })
  },

  /**
   * update
   *
   * @param {object} seriesBike seriesBike to update
   * @returns {Promise[]}
   */
  update (seriesBike) {
    if (!seriesBike || !Object.keys(seriesBike).length) {
      return false
    }

    return fetch(`${this.baseUrl}/${seriesBike.standardBikeSeriesId}`, {
      method: 'PUT',
      headers: this.baseHeader,
      body: JSON.stringify(seriesBike),
    })
  },

  /**
   * removeImage
   *
   * @param {number} standardBikeSeriesId ID of the seriesBike with an image to remove
   * @returns {Promise}
   */
  removeImage (standardBikeSeriesId) {
    return fetch(`${this.baseUrl}/${standardBikeSeriesId}/image`, {
      method: 'DELETE',
      headers: this.baseHeader,
    })
  },
}
