<template>
  <div class="admin-company-wrap">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'admin-companies',
}
</script>
