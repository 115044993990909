import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  theme: {
    themes: {
      light: {
        primary: '#3D5AFE',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
})
