<template>
  <v-dialog persistent :value="componentId !== null" width="500">
    <v-card :loading="isLoading">
      <v-card-title>
        Komponente wirklich löschen?
      </v-card-title>

      <v-card-text>
        <div v-if="relatedModels.length">
          Diese Komponente befindet sich in folgenden Modellreihen:

          <v-list dense>
            <template v-for="(model, i) in relatedModels">
              <v-list-item :key="model.modelSeriesId">
                <v-list-item-content>
                  <v-list-item-title>{{ model.label }}</v-list-item-title>
                  <v-list-item-subtitle>{{ model.identificationNumber }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="i < relatedModels.length - 1" :key="`divider_${model.modelSeriesId}`" />
            </template>
          </v-list>

          Die Verbindung dieser wird aufgelöst.
        </div>

        Achtung! Diese Aktion kann nicht rückgängig gemacht werden.
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn depressed @click="$emit('cancel')">
          <v-icon left>
            cancel
          </v-icon>
          Abbrechen
        </v-btn>
        <v-btn depressed @click="$emit('ok')">
          <v-icon color="primary" left>
            delete
          </v-icon>
          Löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ComponentApi from '@/api/Component'

export default {
  name: 'remove-component-dialog',

  props: {
    componentId: {
      type: Number,
      default: null,
    }
  },

  data () {
    return {
      isLoading: false,
      relatedModels: [],
    }
  },

  watch: {
    componentId () {
      this.relatedModels = []
      this.componentId !== null && this.getRelatedModels()
    },
  },

  methods: {
    async getRelatedModels () {
      this.isLoading = true
      const res = await ComponentApi.getRelatedModels(this.componentId)
      this.isLoading = false

      res.ok && (this.relatedModels = await res.json())
    },
  },
}
</script>
