var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-components-overview-wrap"},[_c('div',{staticClass:"action-header"},[_c('h1',[_vm._v("Komponenten")]),_c('v-btn',{attrs:{"to":{ name: 'ComponentDetails' },"depressed":""}},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v(" create ")]),_vm._v(" Neue Komponente ")],1)],1),_c('div',{staticClass:"components-table-wrap"},[_c('data-table',{attrs:{"is-loading":_vm.isLoading,"items":_vm.components,"columns":_vm.columnOptions,"state-key":"components","search-placeholder":"Bezeichnung/Kategorie"},on:{"dblclick:row":function (e, ref) {
	var item = ref.item;

	return _vm.$router.push({ name: 'ComponentDetails', params: { id: item.componentId } });
}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('component-img',{staticClass:"my-1",attrs:{"id":item.componentId,"has-image":item.hasImage,"alt":item.label,"height":60,"width":100}})]}},{key:"item.paintable",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.paintable,"inset":"","disabled":""}})]}},{key:"item.available",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.available,"inset":"","disabled":""}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("centsToEuro")(item.price))+" ")]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [(item.color)?_c('v-chip',{staticClass:"px-2 mx-2",attrs:{"x-small":"","color":item.color}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){_vm.componentToRemove = item.componentId}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1),_c('v-btn',{attrs:{"icon":"","to":{ name: 'ComponentDetails', params: { id: item.componentId } }}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" create ")])],1)]}}],null,true)})],1),_c('remove-component-dialog',{attrs:{"component-id":_vm.componentToRemove},on:{"cancel":function($event){_vm.componentToRemove = null},"ok":_vm.deleteSelectedComponent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }