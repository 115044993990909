<template>
  <div class="set-password--wrap">
    <h1>Neues Passwort festlegen</h1>

    <form class="mb-10" @submit.prevent="changePassword">
      <v-row>
        <v-col :cols="12" :lg="6">
          <v-text-field
            v-model="password"
            prepend-inner-icon="lock"
            hide-details
            outlined
            label="Passwort"
            required
            class="mb-6"
            name="password"
            type="password"
          />
        </v-col>
        <v-col :cols="12" :lg="6">
          <v-text-field
            v-model="passwordConfirmation"
            prepend-inner-icon="lock"
            hide-details
            outlined
            label="Passwort (Wiederholung)"
            required
            class="mb-6"
            name="passwordConfirmation"
            type="password"
            :error="password !== passwordConfirmation"
          />
        </v-col>
      </v-row>

      <div class="d-flex">
        <v-btn depressed type="submit" class="ml-auto">
          <v-icon color="primary" left>
            save
          </v-icon>
          Passwort ändern
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import AuthApi from '@/api/Auth'

export default {
  name: 'set-password',

  data () {
    return {
      password: '',
      passwordConfirmation: '',
    }
  },

  computed: {
    token () {
      return this.$route.params.token
    },
  },

  mounted () {
    this.$store.commit('setBreadcrumbItems', [{ text: 'Neues Passwort festlegen', disabled: true }])
  },

  methods: {
    async changePassword () {
      if (this.password !== this.passwordConfirmation) {
        this.$store.commit('setSnackbar', { text: 'Passwörter stimmen nicht überein', color: 'error' })
        return
      }

      const res = await AuthApi.setNewPassword({ token: this.token, password: this.password })

      if (res.ok) {
        this.$store.commit('setUser', await res.json())
        this.$store.commit('setSnackbar', { text: 'Passwort geändert', color: 'success' })
        this.$router.push({ name: 'AccountOverview' })
        return
      }

      this.$store.commit('setSnackbar', {
        text: 'Änderung fehlgeschlagen',
        color: 'error',
      })
    },
  },
}
</script>
