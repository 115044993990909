<template>
  <div class="leave-confirmation--wrap">
    <base-dialog
      headline="Ansicht wirklich verlassen?"
      info-text="Es gibt noch ungesicherte Änderungen. Diese werden ggf. verworfen."
      action-icon="exit_to_app"
      action-text="Verlassen"
      :is-visible="routeHandler !== null"
      @cancel="onCancelLeave"
      @ok="onConfirmLeave"
    />
  </div>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'

export default {
  name: 'leave-confirmation',

  components: {
    BaseDialog,
  },

  props: {
    currentData: {
      type: Object,
      default: null,
    },

    defaultData: {
      type: Object,
      default: null,
    },

    routeHandler: {
      type: Function,
      default: null,
    }
  },

  data () {
    return {
      showConfirmDialog: false,
    }
  },

  watch: {
    routeHandler () {
      if (this.routeHandler === null) {
        return this.onConfirmLeave()
      }

      JSON.stringify(this.currentData) === JSON.stringify(this.defaultData)
        ? this.onConfirmLeave()
        : this.showConfirmDialog = true
    }
  },

  methods: {
    onCancelLeave () {
      if (this.routeHandler) {
        this.routeHandler(false)
        this.$emit('decided')
      }
    },

    onConfirmLeave () {
      if (this.routeHandler) {
        this.$emit('decided')
        this.routeHandler()
      }
    },
  }
}
</script>
