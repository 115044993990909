<template>
  <div class="user-form-wrap">
    <form class="mb-10" @submit.prevent="updateUserData">
      <h2>Grundlegende Daten</h2>

      <v-row>
        <v-col :cols="12" :sm="6">
          <v-text-field
            v-model="user.firstName"
            prepend-inner-icon="face"
            hide-details
            outlined
            label="Vorname"
            class="mb-6"
            required
          />

          <v-text-field
            v-model="user.lastName"
            prepend-inner-icon="family_restroom"
            hide-details
            outlined
            label="Nachname"
            class="mb-6"
            required
          />
        </v-col>

        <v-col :cols="12" :sm="6">
          <v-text-field
            v-model="user.email"
            prepend-inner-icon="email"
            hide-details
            outlined
            label="E-Mail"
            class="mb-6"
            required
            type="email"
          />

          <v-switch
            v-model="user.showCustomerPrice"
            :disabled="!userCanSwitchPrices"
            :label="`Preis für ${user.showCustomerPrice ? 'Endkunden' : 'Fachhändler'}`"
            inset
            class="mt-9"
          />
        </v-col>
      </v-row>
      <div class="d-flex">
        <v-btn depressed type="submit" class="ml-auto">
          <v-icon color="primary" left>
            save
          </v-icon>
          Daten aktualisieren
        </v-btn>
      </div>
    </form>

    <slot name="below-userdata" />

    <form @submit.prevent="changePassword">
      <h2>Passwort ändern</h2>

      <v-row>
        <v-col :md="6">
          <v-text-field
            v-model="oldPassword"
            prepend-inner-icon="lock"
            hide-details
            outlined
            label="Aktuelles Passwort"
            required
            class="mb-6"
            type="password"
          />
        </v-col>
        <v-col :md="6">
          <v-text-field
            v-model="newPassword"
            prepend-inner-icon="lock"
            hide-details
            outlined
            label="Neues Passwort"
            required
            class="mb-6"
            type="password"
          />
        </v-col>
      </v-row>
      <div class="d-flex">
        <v-btn depressed type="submit" class="ml-auto">
          <v-icon color="primary" left>
            save
          </v-icon>
          Passwort ändern
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'user-form',

  props: {
    user: {
      type: Object,
      required: true,
    }
  },

  data () {
    return {
      oldPassword: '',
      newPassword: '',
    }
  },

  computed: {
    userCanSwitchPrices () {
      return this.$store.getters.userCanSwitchPrices
    },
  },

  methods: {
    updateUserData (e) {
      this.$emit('submit:user', {
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
      })
    },

    changePassword (e) {
      this.$emit('submit:password', {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      })
    },
  }
}
</script>

<style lang="scss">

</style>
