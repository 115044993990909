<template>
  <div class="option-categories-wrap">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'option-categories',
}
</script>
