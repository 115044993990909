<template>
  <div class="admin-component-details-wrap">
    <h1>Komponente {{ isEdit ? 'bearbeiten' : 'erstellen' }}</h1>

    <v-form ref="form" v-model="formIsValid" class="mb-15">
      <v-row class="mb-10">
        <v-col :lg="6">
          <h3 class="mb-5">
            Grundlegende Daten
          </h3>

          <v-text-field
            v-model="component.label"
            prepend-inner-icon="description"
            hide-details
            outlined
            label="Bezeichnung*"
            class="mb-6"
            :rules="requiredRules"
          />

          <v-select
            v-model="component.componentType"
            :items="componentTypes"
            item-text="label"
            return-object
            label="Kategorie*"
            prepend-inner-icon="category"
            hide-details
            outlined
            :rules="requiredRules"
            class="mb-6"
          />

          <v-text-field
            :value="component.price / 100"
            prepend-inner-icon="euro"
            hide-details
            outlined
            label="Preis"
            class="mb-6"
            type="number"
            step="0.01"
            min="0"
            @change="value => component.price = value * 100"
          />

          <v-switch
            v-model="component.available"
            :label="component.available ? 'Verfügbar' : 'Nicht verfügbar'"
            inset
            class="mb-15"
          />

          <div class="mb-15">
            <component-options
              :component-type="component.componentType ? component.componentType.componentType : null"
              :chosen-options="component.options"
              @change:options="options => component = { ...component, options }"
            />
          </div>

          <h3 class="mb-5">
            Abhängigkeiten
          </h3>

          <v-autocomplete
            v-model="component.requiredComponentIds"
            :items="components"
            label="Bedingt"
            multiple
            small-chips
            deletable-chips
            outlined
            item-text="label"
            item-value="componentId"
            hint="Komponenten, welche für diese zwingend erforderlich sind"
            persistent-hint
            prepend-inner-icon="link"
            class="mb-3"
          />

          <v-autocomplete
            v-model="component.forbiddenComponentIds"
            :items="components"
            label="Verbietet"
            multiple
            small-chips
            deletable-chips
            outlined
            item-text="label"
            item-value="componentId"
            hint="Komponenten, welche nicht mit dieser kombiniert werden dürfen"
            persistent-hint
            prepend-inner-icon="not_interested"
          />
        </v-col>

        <v-col :lg="1" />

        <v-col :lg="5">
          <h3 class="mb-5">
            Darstellung
          </h3>

          <v-file-input
            ref="fileInput"
            outlined
            prepend-icon=""
            prepend-inner-icon="image"
            accept="image/*"
            label="Grafik"
            hide-details
            class="mb-6"
            @change="setImage"
          />

          <v-card outlined class="mb-10" @click="$refs.fileInput.$refs.input.click()">
            <v-img v-if="component.image" max-height="350" contain :src="component.image" />

            <component-img
              v-else
              :id="component.componentId"
              :has-image="component.hasImage"
              :alt="component.label"
              :height="400"
              contain
              class="ma-1"
            />
          </v-card>

          <h3 class="mb-5">
            Farbe
          </h3>

          <v-switch v-model="component.paintable" inset label="Lackierbar" />

          <v-color-picker
            v-if="!component.paintable"
            :value="component.color"
            mode="hexa"
            hide-mode-switch
            @update:color="color => component.color = color.hex"
          />
        </v-col>
      </v-row>

      <div class="d-flex justify-end">
        <v-btn v-if="isEdit" depressed class="mr-2" @click="showDeleteDialog = true">
          <v-icon color="red accent-3" left>
            delete
          </v-icon>
          Löschen
        </v-btn>

        <v-btn v-if="isEdit" depressed class="mr-2" :disabled="!formIsValid" @click="persistComponent(true)">
          <v-icon color="primary" left>
            content_copy
          </v-icon>
          Als Kopie speichern
        </v-btn>

        <v-btn depressed class="mr-2" :disabled="!formIsValid" @click="persistComponent()">
          <v-icon color="primary" left>
            create
          </v-icon>
          {{ isEdit ? 'Speichern' : 'Erstellen' }}
        </v-btn>

        <v-btn depressed :disabled="!formIsValid" @click="persistComponent(false, true)">
          <v-icon color="primary" left>
            save_alt
          </v-icon>
          {{ isEdit ? 'Speichern, schließen' : 'Erstellen, schließen' }}
        </v-btn>
      </div>
    </v-form>

    <remove-component-dialog
      :component-id="isEdit && showDeleteDialog ? component.componentId : null"
      @cancel="showDeleteDialog = false"
      @ok="deleteComponent"
    />

    <leave-confirmation
      :current-data="component"
      :default-data="loadedComponent"
      :route-handler="routeHandler"
      @decided="routeHandler = null"
    />
  </div>
</template>

<script>
import ComponentApi from '@/api/Component'
import ComponentImg from '@/components/ComponentImg'
import ComponentOptions from '@/views/Admin/Components/ComponentOptions'
import LeaveConfirmation from '@/components/LeaveConfirmation'
import RemoveComponentDialog from './RemoveComponentDialog'

export default {
  name: 'admin-component-details',

  components: {
    ComponentImg,
    ComponentOptions,
    LeaveConfirmation,
    RemoveComponentDialog,
  },

  data () {
    return {
      component: {
        available: true,
        componentId: null,
        label: '',
        price: 0,
        componentType: null,
        image: null,
        paintable: true,
        requiredComponentIds: [],
        forbiddenComponentIds: [],
        options: [],
        color: null,
        frame: null,
      },
      components: [],
      componentTypes: [],
      requiredRules: [v => !!v],
      formIsValid: false,
      showDeleteDialog: false,
      routeHandler: null,
      loadedComponent: null,
    }
  },

  computed: {
    frameCategory () {
      return this.$store.state.frameCategory
    },

    isEdit () {
      return this.$route.params.id !== undefined
    },
  },

  watch: {
    component: {
      handler () {
        this.component.paintable && (this.component.color = null)
      },
      deep: true,
    },

    '$route.params.id': {
      handler (to, from) {
        to && +to !== +from && this.loadComponent(+to)
      }
    },
  },

  async mounted () {
    this.getTypes()
    this.getComponents()
    this.setLoadedComponent()

    this.isEdit && this.loadComponent(+this.$route.params.id)
  },

  beforeRouteLeave (to, from, next) {
    this.routeHandler = next
  },

  methods: {
    /**
     * Sets the dataset to compare to when leaving the page.
     *
     * @returns {void}
     */
    setLoadedComponent () {
      this.loadedComponent = JSON.parse(JSON.stringify(this.component))
    },

    /**
     * Loads available component-types/-categories so the user can choose one.
     *
     * @returns {void}
     */
    async getTypes () {
      const res = await ComponentApi.getTypes()
      if (res.ok) {
        this.componentTypes = await res.json()
        this.$refs.form.resetValidation()
      }
    },

    /**
     * Loads available components so the user can choose required and forbidden
     * ones.
     *
     * @returns {void}
     */
    async getComponents () {
      const res = await ComponentApi.getAll()
      if (res.ok) {
        const components = await res.json()
        this.components = this.isEdit
          ? components.filter(c => c.componentId !== +this.$route.params.id)
          : components

        this.$refs.form.resetValidation()
      }
    },

    /**
     * Loads the component with the given id, formats its price:
     * The backend uses cents, but we want to display an euro-format.
     *
     * @param {number} componentId
     * @returns {void}
     */
    async loadComponent (componentId) {
      const res = await ComponentApi.get(componentId)

      if (!res.ok) {
        return
      }

      const component = await res.json()

      if (!component.hasImage) {
        this.component = component
        this.setLoadedComponent()
        return
      }

      const imageRes = await fetch(`${ComponentApi.baseUrl}/${component.componentId}/image`)
      const file = await imageRes.blob()

      this.fileAsBase64(file, base64 => {
        this.component = { ...component, image: base64 }
        this.setLoadedComponent()
      })
    },

    /**
     * Uses the base64-content of the given file as current image.
     *
     * @param {File} file File-object to use (from an upload-input)
     * @returns {void}
     */
    setImage (file) {
      if (!file) {
        this.component = { ...this.component, image: null }
        return
      }

      this.fileAsBase64(file, base64 => (this.component = { ...this.component, image: base64 }))
    },

    /**
     * Helper to transform the given file/blog to base64, use that result.
     *
     * @param {Blob}
     * @param {function} onload
     * @returns {void}
     */
    fileAsBase64 (file, onload) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = typeof onload === 'function' && (() => onload(reader.result))
    },

    /**
     * Creates a new component with the currently entered data.
     *
     * @param {Boolean} createCopy Create a new component with the data of the currently edited one
     * @param {Boolean} close Redirect to the overview after saving
     * @returns {void}
     */
    async persistComponent (createCopy, close) {
      if (!this.formIsValid) {
        return
      }

      const isUpdate = this.isEdit && !createCopy
      const component = { ...this.component, componentId: createCopy ? null : this.component.componentId }
      const res = isUpdate ? await ComponentApi.update(component) : await ComponentApi.create(component)

      if (res.status === 400) {
        const { error } = await res.json()
        return this.$store.commit('setSnackbar', { text: error, color: 'error' })
      }

      if (!res.ok) {
        return this.$store.commit('setSnackbar', { text: 'Ein Fehler ist aufgetreten', color: 'error' })
      }

      this.$store.commit('setSnackbar', {
        text: `Komponente ${isUpdate ? 'aktualisiert' : 'erstellt'}`,
        color: 'success'
      })

      this.setLoadedComponent()

      if (close) {
        return this.$router.push({ name: 'ComponentOverview' })
      }

      if (isUpdate) {
        this.loadComponent(+this.$route.params.id)
      } else {
        const newCompontent = await res.json()
        newCompontent && this.$router.push({ name: 'ComponentDetails', params: { id: newCompontent.componentId } })
      }
    },

    /**
     * Deletes the current component.
     *
     * @returns {void}
     */
    async deleteComponent () {
      if (this.component.componentId === null) {
        return
      }

      const res = await ComponentApi.delete(this.component.componentId)
      this.showDeleteDialog = false

      if (res.ok) {
        this.setLoadedComponent()
        this.$router.push({ name: 'ComponentOverview' })
        this.$store.commit('setSnackbar', { text: 'Komponente gelöscht', color: 'success' })
        return
      }

      if (res.status === 400) {
        const { error } = await res.json()
        this.$store.commit('setSnackbar', { text: error, color: 'error' })
        return
      }

      this.$store.commit('setSnackbar', { text: 'Löschen fehlgeschlagen', color: 'error' })
    },
  },
}
</script>

<style lang="scss">
  .admin-component-details-wrap {
    form {
      .v-select.v-select--chips {
        &:not(.v-text-field--single-line).v-text-field--enclosed {
          .v-select__selections {
            min-height: 42px;
          }
        }
      }
    }
  }
</style>
