<template>
  <div v-if="user" class="account-wrap">
    <h1>Hi, {{ user.firstName }}!</h1>

    <v-tabs class="mb-10" slider-color="primary">
      <v-tab exact to="/account/uebersicht">
        <v-icon left>
          face
        </v-icon>
        Mein Konto
      </v-tab>
      <v-tab exact to="/account/bestellungen">
        <v-icon left>
          list_alt
        </v-icon>
        Bestellungen
      </v-tab>
      <v-tab exact to="/account/konfigurationen">
        <v-icon left>
          favorite
        </v-icon>
        Konfigurationen
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'account',

  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
}
</script>
