<template>
  <div class="dekor--wrap">
    <h2>Dekor</h2>

    <v-radio-group
      :value="dekorConfig.activeDekorType"
      hide-details
      @change="onChangeDekorType"
    >
      <v-radio
        v-for="(dekorType, i) in dekorTypes"
        :key="i"
        :value="dekorType"
        :label="dekorType.label"
        class="mb-4"
      >
        <template v-slot:label>
          <div>
            {{ dekorType.label }}<br>
            <small v-if="dekorType.hint">{{ dekorType.hint }}</small>
          </div>
          <v-chip
            small
            :color="getPriceChipClass(dekorType)"
            class="ml-auto"
          >
            {{ (dekorType.useSurcharge ? dekorPrice : 0) | centsToEuro }}
          </v-chip>
        </template>
      </v-radio>
    </v-radio-group>

    <div v-if="withColorpicker">
      <v-text-field
        :value="dekorConfig.color ? dekorConfig.color.label : ''"
        prepend-inner-icon="color_lens"
        readonly
        hide-details
        outlined
        label="Schriftfarbe"
        class="mt-6 mb-6"
        @focus="showColorPicker = true"
      />

      <color-picker
        v-if="showColorPicker"
        :value="dekorConfig.color"
        class="mb-5"
        :allow-choice-tab="false"
        :with-ral-colors="false"
        :color-limitation="colorLimitation"
        @input="onColorPick"
      />
    </div>

    <div v-if="showTextInputs">
      <v-select
        :value="dekorConfig.chosenFont"
        :items="fonts"
        outlined
        label="Schriftart"
        @change="onChangeFont"
      >
        <template v-slot:item="{ item }">
          <span :style="{ fontFamily: item }">{{ item }}</span>
        </template>
      </v-select>

      <h4 class="mb-2">
        Texte
      </h4>

      <div
        v-for="(element, i) in dekorConfig.elements"
        :key="i"
        class="mb-2"
      >
        <label>
          <span class="text-caption">
            {{ element.label }}
          </span>

          <v-text-field
            :color="dekorConfig.color.colorValue"
            :background-color="getDekorBackgroundColor(element)"
            :value="element.chosenText"
            hide-details
            outlined
            maxlength="26"
            class="colored-input"
            dense
            :style="{
              color: dekorConfig.color.colorValue,
              fontFamily: dekorConfig.chosenFont
            }"
            @input="value => onElementChange(element, value)"
          />
        </label>
      </div>
    </div>

    <div class="font-preloader">
      <span v-for="(font, i) in fonts" :key="i" :style="`font-family: '${font}';`">{{ font }}</span>
    </div>
  </div>
</template>

<script>
import ColorPicker from '@/components/ColorPicker'

export default {
  name: 'bike-dekor',

  components: {
    ColorPicker,
  },

  props: {
    frameComponent: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      showColorPicker: false,
      fonts: [
        'Arial',
        'Verdana',
        'Helvetica',
        'Brush 445 BT',
        'Brush Script',
        'Exotic350 BT',
        'Comic Sans MS',
        'Arnold Bocklin',
        'Lucida Sans',
        'New Berolina',
        'Vivaldi',
        'VAG',
        'Shotgun',
      ],
    }
  },

  computed: {
    // currently chosen dekor-type
    activeType () {
      return this.dekorConfig.activeDekorType
    },

    // current bike-configuration
    bike () {
      return this.$store.state.bike
    },

    // some dekor-types don't allow a color-choice, some just with some colors
    colorLimitation () {
      return this.activeType ? this.activeType.colorLimitation : null
    },

    // available colors to choose from
    colors () {
      return this.$store.state.options.colors
    },

    // current dekor-configuration
    dekorConfig () {
      return this.$store.state.bike.dekorConfig
    },

    // price for dekor-types with a surcharge
    dekorPrice () {
      return this.$store.state.prices.dekorPrice
    },

    // available dekor-types
    dekorTypes () {
      return this.$store.state.options.dekorTypes
    },

    // a dekor-type can have additional inputs to show
    showTextInputs () {
      return this.activeType && this.activeType.hasTextElements
    },

    // currently chosen type allows the user to choose a color
    withColorpicker () {
      if (this.activeType === null) {
        return false
      }

      // = either all colors or a subset of those are allowed
      return this.colorLimitation === null || (Array.isArray(this.colorLimitation) && this.colorLimitation.length)
    },
  },

  methods: {
    /**
     * Returns the css-class to use for the price-chip of the dekor-type with
     * the given id.
     *
     * @param {number} optionId
     * @returns {string}
     */
    getPriceChipClass (optionId) {
      return this.activeType && this.activeType.decorOptionId === optionId ? 'primary' : 'grey lighten-2'
    },

    /**
     * onColorPick
     *
     * @param {string} color
     * @returns {void}
     */
    onColorPick (color) {
      this.$store.commit('updateDekor', { key: 'color', value: color })
      this.showColorPicker = false
    },

    /**
     * onElementChange
     *
     * @param {object} element
     * @param {string} value
     * @returns {void}
     */
    onElementChange (element, value) {
      const i = this.dekorConfig.elements.findIndex(el => el.label === element.label)

      if (i !== -1) {
        this.dekorConfig.elements[i].chosenText = value
        this.$store.commit('updateDekor', { key: 'elements', value: this.dekorConfig.elements })
      }
    },

    /**
     * Stores the changed dekor-type and checks if the currently picked color
     * is still valid. If that's not the case, we pick another one.
     *
     * @param {object} dekorType
     * @returns {void}
     */
    onChangeDekorType (dekorType) {
      this.$store.commit('updateDekor', { key: 'activeDekorType', value: dekorType })

      if (
        this.dekorConfig.color && // a color is picked
        this.colorLimitation !== null && // there's some kind of limitation
        this.colorLimitation.length && // only specific colors are allowed
        !this.colorLimitation.includes(this.dekorConfig.color.colorId) // picked color isn't available
      ) {
        this.onColorPick(this.colors.length
          ? this.colors.find(({ colorId }) => colorId === this.colorLimitation[0])
          : null
        )
      }
    },

    /**
     * Updates the dekorconfig with the given font.
     *
     * @param {string} font
     * @returns {void}
     */
    onChangeFont (font) {
      this.$store.commit('updateDekor', { key: 'chosenFont', value: font })
    },

    /**
     * Inputs related to dekor-elements should use the picked component-color
     * as background-color, so it's easier for the user to pick a fitting text-
     * color.
     *
     * @param {object} element
     * @returns {string}
     */
    getDekorBackgroundColor (element) {
      const slot = this.bike.slots.find(slot => slot.componentType.componentType === element.componentType)

      return slot.pickedComponent.paintable
        ? slot.pickedComponent.pickedColor.colorValue
        : slot.pickedComponent.color
    }
  }
}
</script>

<style lang="scss">
  .font-preloader {
    opacity: 0;
    height: 0;
    width: 0;
  }

  .dekor--wrap {
    .colored-input {
      label.v-label--active {
        top: 0;
      }

      input {
        color: inherit;
      }
    }
  }
</style>
