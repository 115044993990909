<template>
  <div class="after-buy--wrap">
    <v-alert color="success" dark border="left" class="pl-8 mt-10">
      <h1 class="mb-1">
        Vielen Dank!
      </h1>

      <p>
        Ihr Kauf war erfolgreich, die Bestellung wird bearbeitet. <br>
        Einen Überblick über Ihre Bestellungen können sie im <router-link :to="{ name: 'AccountOrders' }">
          Nutzerbereich
        </router-link> erhalten.
      </p>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'after-buy',
}
</script>

<style lang="scss">
  .after-buy--wrap {
    .v-alert {
      a {
        color: #fff;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
</style>
