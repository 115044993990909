<template>
  <div class="component-summary--wrap">
    <h3 class="mb-2">
      Komponenten
    </h3>

    <v-list class="mb-10 component-list" height="270" dense>
      <template v-for="(slot, i) in slots">
        <v-list-item v-if="slot.pickedComponent" :key="`item_${i}`">
          <v-list-item-avatar tile :width="60">
            <component-img
              v-if="slot.pickedComponent"
              :id="slot.pickedComponent.componentId"
              :has-image="slot.pickedComponent.hasImage"
              :alt="slot.pickedComponent.label"
              :height="40"
              :width="60"
              contain
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ slot.componentType.label }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ slot.pickedComponent.label }}

              <div
                v-if="slot.pickedComponent.paintable && slot.pickedComponent.pickedColor"
                class="d-flex align-center mt-1"
              >
                Lackierung: {{ slot.pickedComponent.pickedColor.label }}
                <v-chip x-small tile :color="slot.pickedComponent.pickedColor.colorValue" class="ml-1 px-2" />
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            {{ getPriceDifference(slot) | centsToEuro }}
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-else :key="`item_${i}`">
          <v-list-item-avatar :width="60" />
          <v-list-item-content>
            <v-list-item-title>Ohne {{ slot.componentType.label }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="getDefaultComponent(slot)">
            {{ -getDefaultComponent(slot).price | centsToEuro }}
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="i < slots.length - 1" :key="i" />
      </template>
    </v-list>
  </div>
</template>

<script>
import ComponentImg from '@/components/ComponentImg'

export default {
  name: 'component-summary',

  components: {
    ComponentImg,
  },

  computed: {
    // slots of the current bike-configuration
    slots () {
      return this.$store.state.bike.slots
    },
  },

  methods: {
    /**
     * Returns the default-component of the given slot.
     *
     * @param {object} slot
     * @returns {object|null}
     */
    getDefaultComponent (slot) {
      const slotComponent = slot.slotComponents.find(({ component }) =>
        component.componentId === slot.defaultComponentId
      )

      return slotComponent ? slotComponent.component : null
    },

    /**
     * Difference between the prices of the picked and default-component of the
     * given slot.
     *
     * @param {object} slot
     * @returns {number}
     */
    getPriceDifference (slot) {
      const defaultComponent = this.getDefaultComponent(slot)
      return slot.pickedComponent.price - (defaultComponent ? defaultComponent.price : 0)
    },
  }
}
</script>

<style lang="scss">
  .component-summary--wrap {
    .component-list {
      overflow-y: auto;
    }
  }
</style>
