<template>
  <v-dialog
    :value="isVisible"
    max-width="600"
    @input="visible => !visible && $emit('close')"
  >
    <v-card v-if="previewToShow">
      <component-img
        v-if="previewToShow"
        :id="previewToShow.componentId"
        :has-image="previewToShow.hasImage"
        :alt="previewToShow.label"
      />
      <v-card-title class="mb-2">
        {{ previewToShow.label }}
      </v-card-title>

      <v-card-subtitle>
        {{ previewToShow.price | centsToEuro }}
      </v-card-subtitle>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="$emit('close')">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ComponentImg from '@/components/ComponentImg'

export default {
  name: 'component-dialog',

  components: {
    ComponentImg,
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    previewToShow: {
      type: Object,
      default: null,
    },
  },
}
</script>
